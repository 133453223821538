import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SearchEntityModalService {

    private savedSelectedRadioButton: Map<string, string> = new Map<string, string>();
    private savedParams: Map<string, HttpParams> = new Map<string, HttpParams>();
    private lastSearchMaxRowsError: Map<string, boolean> = new Map<string, boolean>();
    
    constructor() {}

    saveParam(classKey: string, key:string, value:any): void {
        let savedParams: HttpParams = this.getSavedParams(classKey);
        savedParams = savedParams.set(key, value);
        this.saveParams(classKey, savedParams)
    }

    saveParams(classKey: string, params: HttpParams, hasMaxRowsError: boolean = null): void {
        this.savedParams.set(classKey, params);
        if(hasMaxRowsError != null) {
            this.lastSearchMaxRowsError.set(classKey, hasMaxRowsError);
        }
    }

    getSavedParams(classKey: string): HttpParams {
        return this.savedParams.get(classKey) ?? new HttpParams();
    }

    hasLastSearchMaxRowsError(classKey: string): boolean {
        return this.lastSearchMaxRowsError.get(classKey) ?? false;
    }

    saveSelectedRadioButton(classKey: string, selectedRadioButton: string): void {
        this.savedSelectedRadioButton.set(classKey, selectedRadioButton);
    }

    getSelectedRadioButton(classKey: string): string {
        return this.savedSelectedRadioButton.get(classKey);
    }
    
    clearSavedParams(classKey: string): void {
        this.savedParams.delete(classKey);
    }
    
    clearSelectedRadioButton(classKey: string): void {
        this.savedSelectedRadioButton.delete(classKey);
    }

}
