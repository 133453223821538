import { DecimalPipe } from "@angular/common";

export class StringUtils {

    private static readonly DOT: string = '.';
    private static readonly ZERO: string = '0';
    private static readonly REGEX_NO_SYMBOLS = new RegExp('[A-Za-z0-9]');


    public static toStringNeverNull(value: any):string{
        return value && value !== null ? value.toString() : '';
    }

    public static isEmpty(value: any): boolean {
        return value === null || value === undefined || value.toString().trim() === '';
    }

    public static isEmptyInPrimitiveTypes(value: any): boolean {
        return value === null || value === undefined || value.toString().trim() === '' || value.toString().trim() === '0' || value.toString().trim() === 'false';
    }

    public static trim(value:any):string {
        return (value && value !== null) ? value.toString().trim() : null;
    }

    public static hasInvalidStringValue(value: string): boolean {
        return value === null || value === undefined
            || value === 'null' || value.trim() === '';
    }

    public static toUpperCase(value: any): string {
        if(!StringUtils.isEmpty(value)) {
            return value.toString().toUpperCase();
        }
        return value;
    }

    public static hashCode(str: string): number {
        let hash = 0;

        if (str.length === 0) return hash;

        for (let i = 0; i < str.length; i++) {
            let char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }

        return hash;
    }

    public static applyNumberThousandsFormat(decimalPipe: DecimalPipe, value: string, 
                        completeDecimals: boolean = false, maxDecimal: number = 2, allowDecimalZeros: boolean = true): string {
        
        if (value !== null && value !== '') {
            value = String(value);
            value = value.replace(/,/g, '');
            const dotIndex = value.indexOf(StringUtils.DOT);
            if (dotIndex > -1 || completeDecimals) {
                const dParts = value.split(StringUtils.DOT);
                value = decimalPipe.transform(dParts[0]);

                if(completeDecimals){
                    if(dotIndex > -1 || allowDecimalZeros) {
                        const toIndex = dParts[1] ? dParts[1].length : 0;
                        if (dParts.length > 1) {
                            value += StringUtils.DOT + dParts[1];
                        } else {
                            value += StringUtils.DOT;
                        }
                        for(let i = toIndex; i<maxDecimal; i++){
                            value += StringUtils.ZERO;
                        }
                    }
                } else {
                    value += StringUtils.DOT + dParts[1];
                }
            } else {
                value = decimalPipe.transform(value);
            }
        }
        return value;
    }

    public static equalsIgnoreCase(value1: string, value2: string): boolean {
        value1 = StringUtils.toStringNeverNull(value1);
        value2 = StringUtils.toStringNeverNull(value2);

        return value1.toUpperCase() === value2.toUpperCase();
    }

    public static getCompositeIDName(idValue: string, nameValue: string, separator: string = '-') {
        let result: string = StringUtils.toStringNeverNull(idValue);
        if(!StringUtils.isEmpty(idValue) && !StringUtils.isEmpty(nameValue)) {
            result += separator;
        }
        result += StringUtils.toStringNeverNull(nameValue);
        return result;
    }

    public static convertToBoolean(value: string): boolean {
        let result: boolean = false;
        if(value != null && (StringUtils.equalsIgnoreCase(value, '1') || StringUtils.equalsIgnoreCase(value, 'true'))) {
            result = true;
        }
        return result;
    }

    public static isNumber(value: string): boolean {
        return !Number.isNaN(value);
    }

    public static convertToCamelCase(inputString: string): string {
        const words = inputString.trim().split(' ');
        const camelCaseWords = words.map((word, index) => index === 0 ? word.toLowerCase() : StringUtils.capitalizeFirstLetter(word));
        const camelCaseString = camelCaseWords.join('');
    
        return camelCaseString;
    }
    
    public static capitalizeFirstLetter(word: string): string {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    public static removeWhiteSpace(word: string): string {
        return word.replace(/\s/g, '');
    }
    
    public static safeWindowName(name: string): string {
       return name.replace(/[^0-9a-zA-Z]/g,'_');
    }

    public static checkNoSymbols(value: string): boolean {
        return this.REGEX_NO_SYMBOLS.test(value);
    }

    public static convertToYesNo(value: any): string {
        if (value === 1 || value === '1') {
            return 'Yes';
        } else {
            return 'No';
        }
    }
}
