import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, Observable } from 'rxjs';
import { HexaColor, ProjectStatusValue } from 'src/app/core/models/constants';
import { ProjectRecordStatus, ProjectStatus } from 'src/app/core/models/project';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { SearchEntityModalUtils } from 'src/app/core/utils/search-entity-modal-utils';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { ProjectSectionService } from 'src/app/project-section/project-section.service';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { AdditionalInfoContainer, InputModalField, InputModalFieldContainer, InputModalFieldEditableType, SearchEntityModalComponent } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.component';
import { SearchEntityModalService } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.service';
import { AddressService } from '../../address-form/address.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';
import { PecZoneModalComponent } from '../pec-zone-modal/pec-zone-modal.component';
import { SearchPlantComponent } from '../search-plant/search-plant.component';

@Component({
    selector: 'app-search-project',
    templateUrl: '../../../shared/components/base/search-entity-modal/search-entity-modal.component.html'
})

export class SearchProjectComponent extends SearchEntityModalComponent {
    isUnconfirmedScreen: boolean = false;

    constructor(
        private projectService: ProjectSectionService,
        private addressService: AddressService,
        private offshoreAddressService: OffshoreAddressService,
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        protected sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected datePipe: DatePipe,
        protected searchEntityModalService: SearchEntityModalService,
        protected changeDetectorRef: ChangeDetectorRef) {
        super(messageHandler, activeModal, config, preferencesService, translate, loadWheelService, entityCommonService, entityUtilsService, searchEntityModalService, dialogService, changeDetectorRef);
    }

    override initConfigAtributes() {
        this.isUnconfirmedScreen = this.config.data?.isUnconfirmedScreen;     
        this.entityTitle = 'common.confirmedProject';
        this.entityTitlePlural = 'common.confirmedProject';
        this.showNewRecordButton = false;
    }

    protected override getRadioButtons(): InputModalField[] {
        return [
            InputModalField.CreateInstance({ entityPropName: 'onshore', displayName: 'project.searchProject.onshore' }),
            InputModalField.CreateInstance({ entityPropName: 'offshore', displayName: 'project.searchProject.offshore' })
        ];
    }

    override onRowDblclick(rowData: any){
        if (this.selectableProject(rowData)) {
            this.activeModal.close(rowData);
        }
    }

    override getInputModalFieldContainer(): InputModalFieldContainer[] {
        let inputsModalFieldContainer: InputModalFieldContainer[] = [];
        let inputsModalField: InputModalField[] = [];
    
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'projectId', displayName: 'project.searchProject.projectID', editableType: InputModalFieldEditableType.numberField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'projectStatus', displayName: 'project.searchProject.status', editableType: InputModalFieldEditableType.multiSelectField, dropdownValue: 'statusID', dropdownLabel: 'description' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantId', displayName: 'project.searchProject.plantID', editableType: InputModalFieldEditableType.numberField, searchType: SearchPlantComponent, searchData: { baseIndex: this.baseIndex + 1000 }, searchTitle: 'Plant Search', searchWidth: '90rem', searchEntityPropName: 'plantId', maxLength: 7 }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'ownerId', displayName: 'project.searchProject.ownerID', editableType: InputModalFieldEditableType.numberField, maxLength: 7 }));
            
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
            const isOffshore = this.selectedRadioButton === 'offshore';
            
            inputsModalField = [];
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'ownerName', displayName: 'project.searchProject.ownerName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'projectName', displayName: 'project.searchProject.projectName', editableType: InputModalFieldEditableType.textField }));
            
            if (!isOffshore) {
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'pecZone', displayName: 'project.searchProject.pecZone', editableType: InputModalFieldEditableType.textField, searchType: PecZoneModalComponent, searchTitle: 'PEC Zone Search', searchEntityPropName:'pecZoneId' }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'country', displayName: 'project.searchProject.country', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'countryId', dropdownLabel: 'countryNameConcat', onChange: () => this.onChangePhysCountry(), dropdownOptionsArray: [] , widthPercentage: 60 }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'state', displayName: 'project.searchProject.state', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'sId', dropdownLabel: 'stateConcat', isDropdownValueNumber: () => { return true },  disabledCondition: this.isDisabledState, dropdownOptionsArray: [] , widthPercentage: 60}));
                
            }else{
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'country', displayName: 'project.searchProject.country', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'countryId', dropdownLabel: 'countryNameConcat', onChange: () => this.onChangePhysCountry(), dropdownOptionsArray: [] , widthPercentage: 60 }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'waterBody', displayName: 'project.searchProject.waterBody', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'waterBodyId', dropdownLabel: 'waterBodyName', isDropdownValueNumber: () => { return true }, disabledCondition: this.isDisabledWaterBody, dropdownOptionsArray: [], widthPercentage: 60})); 
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'fieldName', displayName: 'project.searchProject.fieldName', editableType: InputModalFieldEditableType.textField, widthPercentage: 60 }));
                
            }
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'recordStatus', displayName: 'project.searchProject.recordStatus', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'statusID', dropdownLabel: 'description', dropdownOptionsArray: []  }));
            
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
    
        return inputsModalFieldContainer;
    }

    protected override getColumnsInfo(): ColumnEntityInfo[] {
        const isOffshore = this.selectedRadioButton === 'offshore';
        let columnsInfo: ColumnEntityInfo[] = [];

        columnsInfo.push({ entityPropName: 'projectID', columnHeader: this.translate.instant('project.searchProject.table.projectID'), widthPercentage: 10, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'projectName', columnHeader: this.translate.instant('project.searchProject.table.projectName'), widthPercentage: 28, getColumnTextColor: this.getColumnTextColor,  getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'ownerName', columnHeader: this.translate.instant('project.searchProject.table.owner'), widthPercentage: 25, getColumnTextColor: this.getColumnTextColor,  getColumnTooltip: this.getColumnTooltip });
        
        if(!isOffshore){
            columnsInfo.push({ entityPropName: 'physCity', columnHeader: this.translate.instant('project.searchProject.table.city'), widthPercentage: 15, getColumnTextColor: this.getColumnTextColor,  getColumnTooltip: this.getColumnTooltip });
            columnsInfo.push({ entityPropName: 'physCountry', columnHeader: this.translate.instant('project.searchProject.table.country'), widthPercentage: 15, getColumnTextColor: this.getColumnTextColor,  getColumnTooltip: this.getColumnTooltip });
            
        }else{
            columnsInfo.push({ entityPropName: 'waterbodyName', columnHeader: this.translate.instant('project.searchProject.table.waterBody'), widthPercentage: 15, getColumnTextColor: this.getColumnTextColor,  getColumnTooltip: this.getColumnTooltip });
            columnsInfo.push({ entityPropName: 'fieldName', columnHeader: this.translate.instant('project.searchProject.table.fieldName'), widthPercentage: 15, getColumnTextColor: this.getColumnTextColor,  getColumnTooltip: this.getColumnTooltip});
        }

        columnsInfo.push({ entityPropName: 'projectStatus', columnHeader: this.translate.instant('project.searchProject.table.status'), widthPercentage: 10, getColumnTextColor: this.getColumnTextColor,  getColumnTooltip: this.getColumnTooltip });
        
        return columnsInfo;
    }

    getColumnTooltip(implementedComponent: SearchProjectComponent, rowData: any, rowIndex: number): string {
        return implementedComponent.selectableProject(rowData) ? '' :implementedComponent.translate.instant('project.confirmedMessage')
    }

    getColumnTextColor(implementedComponent: SearchProjectComponent, rowData: any, rowIndex: number) {
        const res = implementedComponent.selectableProject(rowData);
        return  res ? '' : HexaColor.lightGray;
    }

    protected override getAdditionalInfoContainers(): AdditionalInfoContainer[] {
        let aditionalInfoContainer: AdditionalInfoContainer[] = [];
        let columnsInfo: ColumnEntityInfo[] = [];

        columnsInfo.push({ entityPropName: 'projectID', columnHeader: this.translate.instant('project.additionalInfo.projectID'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'reviewDate', columnHeader: this.translate.instant('project.additionalInfo.reviewDate'), editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'completionDate', columnHeader: this.translate.instant('project.additionalInfo.completionDate'), editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'afeDate', columnHeader: this.translate.instant('project.additionalInfo.afeDate'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'rfqDate', columnHeader: this.translate.instant('project.additionalInfo.rfqDate'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'projectStatus', columnHeader: this.translate.instant('project.additionalInfo.status'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'pecTiming', columnHeader: this.translate.instant('project.additionalInfo.timing'), widthPercentage: 10, displayValueFunc: this.displyValueFuncPecTiming });
        columnsInfo.push({ entityPropName: 'pecActivity', columnHeader: this.translate.instant('project.additionalInfo.activity'), widthPercentage: 10, displayValueFunc: this.displayValueFunc });
        columnsInfo.push({ entityPropName: 'tiv', columnHeader: this.translate.instant('project.additionalInfo.tiv'), editableType: ColumnEntityInfoEditableType.textField,  widthPercentage: 50, displayValueFunc: this.currency });

        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
        
        columnsInfo = [];
        columnsInfo.push({ entityPropName: 'projectName', columnHeader:  this.translate.instant('project.searchProject.table.projectName'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'ownerName', columnHeader: this.translate.instant('project.additionalInfo.ownerName'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'pecZoneSimplex', columnHeader: this.translate.instant('project.additionalInfo.pecZone'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'bidDocDate', columnHeader:this.translate.instant('project.additionalInfo.bidDocDate'), widthPercentage: 50 });

        columnsInfo.push({ entityPropName: 'kickoffDate', columnHeader:  this.translate.instant('project.additionalInfo.kickoffDate'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'recordStatusDesc', columnHeader: this.translate.instant('project.additionalInfo.recordStatus'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: this.translate.instant('project.additionalInfo.qcDate'), editableType: ColumnEntityInfoEditableType.nonEditableDateField,  widthPercentage: 50 });

        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
        
        return aditionalInfoContainer;
    }

    protected displayValueFunc(implementedComponent: SearchProjectComponent, rowData: any) {
        return rowData.pecActivity ? `(${rowData.pecActivity})`: '';
    }

    protected displyValueFuncPecTiming(implementedComponent: SearchProjectComponent, rowData: any) {
        return rowData.pecTiming ? `(${rowData.pecTiming})`: '';
    }

    protected currency(implementedComponent: SearchProjectComponent, rowData: any): string {
        return rowData.tiv ? `$${rowData.tiv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '';
    }

    isDisabledState = (): boolean => {
        const countryField = this.searchInputModalField('country');
        return !countryField?.inputValue;
    };

    isDisabledWaterBody = (): boolean => {
        const countryField = this.searchInputModalField('country');
        return !countryField?.inputValue;
    };

    protected override searchEntitiesDelegate(params: HttpParams, wheel: SpinnerProcess): Observable<any> {
        return this.projectService.searchProject(params)
            .pipe(
                map(
                    projects => projects?.filter((project, index, self) =>
                        index === self.findIndex((p) => (
                            p.projectID === project.projectID && p.projectID === project.projectID
                        ))
                    )
                )
            );
    }

    protected override getHttpParamsFromInputsModalField(): HttpParams {
        let params: HttpParams = super.getHttpParamsFromInputsModalField();
        params = params.set('offshore', this.selectedRadioButton == 'offshore'? '1':'0');
        return params;
    }

    onChangePhysCountry() {
        const isOffshore = this.selectedRadioButton === 'offshore';
        if(!isOffshore){
            const countryField = this.searchInputModalField('country');
            const stateField = this.searchInputModalField('state');          
            SearchEntityModalUtils.loadStates(countryField, stateField, this.addressService);
        } else {
            const countryField = this.searchInputModalField('country');
            const waterBodyField = this.searchInputModalField('waterBody');        
            SearchEntityModalUtils.loadWaterBodies(countryField, waterBodyField, this.offshoreAddressService);         
        }
    }

    override loadList() {
        this.loadRecordStatus();
        this.loadProjectStatus();
        const isOffshore = this.selectedRadioButton === 'offshore';
        isOffshore ? this.loadCountryOffshore() : this.loadCountryOnshore();
    }

    private loadProjectStatus() {
        this.sharedService.getProjectStatus()
            .subscribe((statuses: ProjectStatus[]) => {
                let confirmedProjectStatuses = [];
                let unconfirmedProjectStatuses = [];

                statuses.forEach(status => {
                    if (this.isUnconfirmedProjectByStatus(status.statusID)) {
                        unconfirmedProjectStatuses.push(status);
                    } else {
                        confirmedProjectStatuses.push(status);
                    }
                });

                const projectStatusField = this.searchInputModalField('projectStatus');
                if (projectStatusField) {
                    projectStatusField.dropdownOptionsArray = this.isUnconfirmedScreen ? unconfirmedProjectStatuses : confirmedProjectStatuses;
                }
            });
    }

    public isUnconfirmedProjectByStatus(status: string): boolean{
        return status === ProjectStatusValue.Unconfirmed ||
        status === ProjectStatusValue.UnableToConfirm
    }

    private loadRecordStatus() {
        this.projectService.getProjectRecordStatus()
            .subscribe((record: ProjectRecordStatus[]) => 
            {
                this.searchInputModalField('recordStatus').dropdownOptionsArray =  record;
            })
    }

    private loadCountryOnshore() {
        SearchEntityModalUtils.loadOnshoreCountries(this.searchInputModalField('country'), this.addressService);
    }

    private loadCountryOffshore() {
        SearchEntityModalUtils.loadOffshoreCountries(this.searchInputModalField('country'), this.offshoreAddressService);
    }

    selectableProject(project: any): boolean{
        return !this.isUnconfirmedScreen || 
                (this.isUnconfirmedScreen &&
                    this.isUnconfirmedProjectByStatus(project.projectStatusId)) ||
                (this.isUnconfirmedScreen && 
                    !this.isUnconfirmedProjectByStatus(project.projectStatusId) &&
                    project.qcDate === null)
    }

}