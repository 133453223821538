import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, Type, ViewChild, ViewChildren } from '@angular/core';
// Modal Dialog
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

// Models
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LoadWheelService } from 'src/app/components/load-wheel/load-wheel.service';
import { MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { BaseModalComponent } from 'src/app/components/modals/base-modals.component';
import { Constants, EntityPreferenceSufix, PreferencePrefix } from 'src/app/core/models/constants';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { ColumnEntityInfo, MVTEntityAssociatorComponent } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { SearchEntityModalService } from './search-entity-modal.service';

@Component({
    selector: 'app-search-entity-modal',
    templateUrl: './search-entity-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchEntityModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
    @ViewChild('mvtTableSelector') mvtTableSelector: MVTEntityAssociatorComponent;
    @ViewChildren('entityGenericInput') entityGenericInput: QueryList<ElementRef>;

    entityTitle: string = '';
    entityTitlePlural: string = '';
    entitiesArray: Array<any> = [];
    isMainAccess: boolean = false;
    isMultiSelect: boolean = false;
    showNewRecordButton: boolean = true;
    enabledNewRecordButton: boolean = false;
    avoidHasParamsValidation: boolean = false;
    returnInputElementRef: ElementRef = null;
    returnInputEntityPropName: string = null;

    radioButtons: InputModalField[] = [];
    inputsModalFieldContainer: InputModalFieldContainer[];
    columnsInfo: ColumnEntityInfo[] = [];
    additionalInfoContainers: AdditionalInfoContainer[] = [];
    mvtTableKey = PreferencePrefix.Search + EntityPreferenceSufix.Entity;

    selectedRadioButton: string = null;    
    showRecordCounter: boolean = false;
    showClearButton: boolean = true;
    componentDescription: string = null;
    customTextNewRecordButton: string = null;

    constructor(
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        public translate: TranslateService,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected searchEntityModalService: SearchEntityModalService,
        public dialogService: DialogService,
        protected changeDetectorRef: ChangeDetectorRef) {
        super(activeModal, config, preferencesService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.isMainAccess = this.config.data?.isMainAccess !== undefined ? this.config.data?.isMainAccess : false;
        this.isMultiSelect = this.config.data?.isMultiSelect !== undefined ? this.config.data?.isMultiSelect : false;

        this.initConfigAtributes();
        this.initPaginatorPreferences();
        this.loadRadioButtons();
        this.loadInputsModalFieldContainer();
        this.loadColumnsInfo();

        this.loadList();
    }

    ngAfterViewInit() {
        this.mvtTableSelector.setBaseModalIndex(this.baseIndex);
        this.loadAdditionalInfo();
        this.clear();
        this.searchByParamsRecording();
        if (this.returnInputEntityPropName) {
            this.returnInputElementRef = this.entityGenericInput.find(el => el.nativeElement.id === this.returnInputEntityPropName);
        }
    }

    protected loadInputsModalFieldContainer() {
        this.inputsModalFieldContainer = this.getInputModalFieldContainer();
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                inputModalField.searchEntityModalComponent = this;
                inputModalField.inputValue = inputModalField.defaultValue;
            });
        });
    }

    protected loadRadioButtons() {
        this.radioButtons = this.getRadioButtons();
        if(this.radioButtons?.length > 0) {
            this.selectedRadioButton = this.radioButtons[0].entityPropName;
        }
        const savedSelectedRadioButton = this.searchEntityModalService.getSelectedRadioButton(this.getClassKey());
        if(savedSelectedRadioButton) {
            this.selectedRadioButton = savedSelectedRadioButton;
        }
    }

    protected loadColumnsInfo() {
        this.columnsInfo = this.getColumnsInfo();
    }

    protected loadAdditionalInfo() {
        this.convertAdditionalInfoContainers(this.getAdditionalInfoContainers());
    }

    radioButtonChange() {
        this.searchEntityModalService.saveSelectedRadioButton(this.getClassKey(), this.selectedRadioButton);
        this.loadInputsModalFieldContainer();
        this.loadColumnsInfo();
        this.mvtTableSelector.setColumnsInfo(this.columnsInfo);
        this.loadAdditionalInfo();
        this.loadList();
        this.clear();
    }

    convertAdditionalInfoContainers(additionalInfoContainers: AdditionalInfoContainer[]) {
        let containers: AdditionalInfoContainer[] = null;
        if (additionalInfoContainers != null && additionalInfoContainers.length > 0) {
            containers = [];
            for (let additionalInfoContainer of additionalInfoContainers) {
                additionalInfoContainer.mvtEntityAssociatorComponent = this.mvtTableSelector;
                containers.push(AdditionalInfoContainer.CreateInstance(additionalInfoContainer));
            }
        }
        setTimeout(() => this.additionalInfoContainers = containers, 0);
    }

    protected searchInputModalField(entityPropName: string): InputModalField {
        let result: InputModalField = null;
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                if (inputModalField.entityPropName === entityPropName) {
                    result = inputModalField;
                }
            });
        });
        return result;
    }

    protected initConfigAtributes(): void {}
    protected getInputModalFieldContainer(): InputModalFieldContainer[] { return null; };
    protected getRadioButtons(): InputModalField[] { return null; };
    protected getColumnsInfo(): ColumnEntityInfo[] { return null; };
    protected getAdditionalInfoContainers(): AdditionalInfoContainer[] { return null; };
    protected searchEntitiesDelegate(params: HttpParams, wheel: SpinnerProcess): Observable<any> { return null; };
    protected loadList(): void { };


    search(showNotFoundAlert:boolean = true) {
        this.searchEntities(this.getHttpParamsFromInputsModalField(), showNotFoundAlert);
    }

    protected getHttpParamsFromInputsModalField(): HttpParams {
        let httpParams: HttpParams = new HttpParams();
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                httpParams = httpParams.set(inputModalField.entityPropName, inputModalField.inputValue != null ? inputModalField.inputValue : '');
            });
        });
        return httpParams;
    }

    protected setInputValue(fieldName: string, value: any, detectChanges: boolean = true) {
        this.searchInputModalField(fieldName).setInputValue(value, detectChanges);
    }

    protected searchByParamsRecording() {
        let savedParams: HttpParams = this.searchEntityModalService.getSavedParams(this.getClassKey());
        this.setHttpParamsInInputValues(savedParams);

        if (this.entityUtilsService.httpParamsHasValues(savedParams) 
            && this.searchEntityModalService.hasLastSearchMaxRowsError(this.getClassKey()) === false) {
            this.search(false);
        }
    }

    protected hasRecordedParams(): boolean {
        let savedParams: HttpParams = this.searchEntityModalService.getSavedParams(this.getClassKey());
        return this.entityUtilsService.httpParamsHasValues(savedParams);
    }

    getClassKey(): string {
        return this.constructor.name + this.isMainAccess;
    }

    private setHttpParamsInInputValues(httpParams: HttpParams): void {
        if (!this.entityUtilsService.httpParamsHasValues(httpParams)) {
            return;
        }

        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                if (httpParams.has(inputModalField.entityPropName) && !StringUtils.isEmptyInPrimitiveTypes(httpParams.get(inputModalField.entityPropName))) {
                    inputModalField.inputValue = httpParams.get(inputModalField.entityPropName);
                    inputModalField.onInputChange();
                    if (inputModalField.editableType === InputModalFieldEditableType.dropdownField && !isNaN(inputModalField.inputValue)
                        && inputModalField.isDropdownValueNumber()) {
                        inputModalField.inputValue = Number(inputModalField.inputValue);
                    }
                }
            });
        });
        this.changeDetectorRef.detectChanges();
    }

    protected searchEntities(params: HttpParams, showNotFoundAlert:boolean) {
        this.entitiesArray = [];
        this.enabledNewRecordButton = false;
        this.searchEntityModalService.clearSavedParams(this.getClassKey());
        
        if(this.validateInfo()) {
            return;
        }

        if (!this.avoidHasParamsValidation && !this.entityUtilsService.checkForValueRequired(params, null)) {
            return;
        }

        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant('searchEntityModalComponent.searching') + ' ' + this.getEntityTitle());
        this.searchEntitiesDelegate(params, wheel)
            .subscribe({
                next: (searchedEntities: Array<any>) => {
                    if (searchedEntities === null) {
                        searchedEntities = [];
                    } else if (searchedEntities.length === 0) {
                        if(showNotFoundAlert){
                            if (this.returnInputElementRef) {
                                this.messageHandler.showAlertAndReturnFocus(this.translate.instant("common.searchNotFound"), null,
                                    this.returnInputElementRef, false, false);
                            } else {
                                this.messageHandler.show(this.translate.instant("common.searchNotFound"), MessageType.INFO);
                            }
                        }

                        this.enabledNewRecordButton = true;
                    } else {
                        this.enabledNewRecordButton = true;
                    }

                    this.entitiesArray = searchedEntities;
                    if (this.entitiesArray.length > 0) {
                        this.focusFirstElement();
                    }
                    
                    this.searchEntityModalService.saveParams(this.getClassKey(), params, false);
                    this.loadWheelService.hideWheel(wheel);
                },
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                    if (error.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
                        this.searchEntityModalService.saveParams(this.getClassKey(), params, true);
                        this.messageHandler.showAlertAndReturnFocus(error.message);
                    } else {
                        console.warn(error);
                    }
                }
            });
    }

    validateInfo(): boolean {
        let hasAlert = false;
        const alertMsg = this.getValidationInfo();
        if(!StringUtils.isEmptyInPrimitiveTypes(alertMsg)) {
            this.messageHandler.show(alertMsg, MessageType.ERROR)
            hasAlert = true;
        }
        return hasAlert;
    }

    getValidationInfo(): string {
        let alertMsg = '';

        this.inputsModalFieldContainer.forEach(inputModalFieldContainer => {
            inputModalFieldContainer.inputFieldsArray.forEach(inputModalField => {
                if (inputModalField.required && StringUtils.isEmptyInPrimitiveTypes(inputModalField.inputValue)) {
                    alertMsg += this.getMissingRequiredFieldMessage(inputModalField) + '<br>';
                }
            });
        });

        return alertMsg;
    }

    getMissingRequiredFieldMessage(inputModalField: InputModalField): string {
        const fieldDisplayName = this.translate.instant(inputModalField.displayName).trim();
        const isList = inputModalField.editableType === InputModalFieldEditableType.multiSelectField || inputModalField.editableType === InputModalFieldEditableType.listboxField;
        const startsWithVowel = /^[aeiou]/i.test(fieldDisplayName);

        const baseKey = isList 
            ? 'searchEntityModalComponent.missingRequiredFieldAtLeastOne' 
            : 'searchEntityModalComponent.missingRequiredField';
        return this.translate.instant(baseKey)+ (isList ? ` ${fieldDisplayName}` : (startsWithVowel ? ' an ' : ' a ') + fieldDisplayName);
    }

    override focusFirstElement() {
        setTimeout(() => {
            this.mvtTableSelector.setFocusToFirstRow();
        }, 0);
    }

    saveParam(key:string, value:any) {
        this.searchEntityModalService.saveParam(this.getClassKey(), key, value);
    }

    clearAll(): void {
        this.searchEntityModalService.clearSavedParams(this.getClassKey());
        this.clear();
    }

    private clear(): void {
        this.clearInputsModalField();
        this.setFocusOnFirstInput();
        this.entitiesArray = [];
        this.mvtTableSelector.resetSort();
        this.enabledNewRecordButton = false;
    }

    clearInputsModalField() {
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                if(inputModalField.defaultValue) {
                    inputModalField.inputValue = inputModalField.defaultValue;
                } else {
                    inputModalField.inputValue = null;
                }
            });
        });
    }

    setFocusOnFirstInput() {
        this.entityGenericInput?.find(el => el.nativeElement.autofocus)?.nativeElement?.focus()
    }

    onInternalRowDblclick(implementedComponent: SearchEntityModalComponent, rowData: any) {
        implementedComponent.onRowDblclick(rowData);
    }

    onKeyDownEnter(rowData: any): void {
        this.onRowDblclick(rowData);
    }

    onRowDblclick(rowData: any) {
        if (!this.isMultiSelect) {
            this.activeModal.close(rowData);
        }
    }

    public getSelectedEntities(): Array<any> {
        return (this.mvtTableSelector != null) ? this.mvtTableSelector.getSelectedEntities() : [];
    }

    hasSelectedEntities(): boolean {
        return this.getSelectedEntities().length > 0;
    }

    addSelectedEntities() {
        this.activeModal.close(this.getSelectedEntities());
    }

    newRecord() {
        this.entityCommonService.sendNewRecordEvent(this.getNewRecordData());
        this.activeModal.close();
    }

    getNewRecordData(): any {
        return null;
    }

    protected getEntityTitle(): string {
        return this.translate.instant(this.entityTitle);
    }

    protected getEntityTitlePlural(): string {
        return this.translate.instant(this.entityTitlePlural);
    }

    protected showInputsFilters(): boolean {
        return this.inputsModalFieldContainer != null && this.inputsModalFieldContainer.length > 0;
    }

    public get inputModalFieldEditableType(): typeof InputModalFieldEditableType {
        return InputModalFieldEditableType;
    }

    public getAdditionalInfoContainerColCssClass(): string {
        const colSize = Math.floor(12 / (this.additionalInfoContainers?.length ?? 0));
        return `col-12 lg:col-${colSize} xl:col-${colSize}`;
    }

    public getSelectedRow(): any {
        return this.mvtTableSelector ? this.mvtTableSelector.getSelectedRow() : null;
    }

    getChangeDetectorRef(): ChangeDetectorRef {
        return this.changeDetectorRef;
    }

}

export class AdditionalInfoContainer {

    mvtEntityAssociatorComponent?: MVTEntityAssociatorComponent;
    additionalInfoFields: ColumnEntityInfo[] = [];

    constructor(entity: AdditionalInfoContainer) {
        this.mvtEntityAssociatorComponent = entity?.mvtEntityAssociatorComponent ?? null;
        this.additionalInfoFields = entity?.additionalInfoFields ?? null;

        this.additionalInfoFields = MVTEntityAssociatorComponent.convertAdditionalInfoFields(this.mvtEntityAssociatorComponent, this.additionalInfoFields);
    }

    static CreateInstance(entity: AdditionalInfoContainer): AdditionalInfoContainer {
        return new AdditionalInfoContainer(entity);
    }
}

export class InputModalFieldContainer {

    cssClasses?: string;
    inputFieldsArray: Array<InputModalField> = [];

    constructor(entity: InputModalFieldContainer) {
        this.cssClasses = entity?.cssClasses ?? null;
        this.inputFieldsArray = entity?.inputFieldsArray ?? null;

        if (this.cssClasses == null) {
            this.cssClasses = 'col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2';
        }
    }

    static CreateInstance(entity: InputModalFieldContainer): InputModalFieldContainer {
        return new InputModalFieldContainer(entity);
    }
}

export class InputModalField {

    searchEntityModalComponent?: SearchEntityModalComponent;
    entityPropName?: string;
    displayName?: string;
    editableType?: InputModalFieldEditableType;
    widthPercentage?: number;
    maxLength?: number;
    maxDecimals?: number;
    maxValue?: object;
    dropdownValue?: string;
    dropdownLabel?: string;
    dropdownOptionsArray?: Array<any>;
    disabledCondition?: () => boolean;
    required?: boolean;
    placeholder?: string;

    inputValue?: any;
    inputModalFieldValueFormatter?: InputModalFieldValueFormatter;
    defaultValue?: any;

    onChange?: () => void;

    searchType?: Type<any>;
    searchTitle?: string;
    searchData?: any;
    searchDataFunc?: () => any;
    searchWidth?: string;
    searchEntityPropName?: string;
    infoTooltip?: string;

    protected constructor(entity: InputModalField) {
        this.searchEntityModalComponent = entity?.searchEntityModalComponent ?? null;
        this.entityPropName = entity?.entityPropName ?? null;
        this.displayName = entity?.displayName ?? null;
        this.editableType = entity?.editableType ?? null;
        this.widthPercentage = entity?.widthPercentage ?? 100;
        this.maxLength = entity?.maxLength ?? (entity?.editableType === InputModalFieldEditableType.numberField ? 15 : 80);
        this.maxDecimals = entity?.maxDecimals ?? null;
        this.maxValue = entity?.maxValue ?? null;
        this.dropdownValue = entity?.dropdownValue ?? null;
        this.dropdownLabel = entity?.dropdownLabel ?? null;
        this.dropdownOptionsArray = entity?.dropdownOptionsArray ?? null;
        this.required = entity?.required ?? false;
        this.placeholder = entity?.placeholder ?? '';

        this.searchType = entity?.searchType ?? null;
        this.searchTitle = entity?.searchTitle ?? null;
        this.infoTooltip = entity?.infoTooltip ?? null;
        this.searchData = entity?.searchData ?? {};
        this.searchDataFunc = entity?.searchDataFunc ?? null;
        this.searchWidth = entity?.searchWidth ?? '40rem';
        this.searchEntityPropName = entity?.searchEntityPropName ?? null;

        this.disabledCondition = entity?.disabledCondition;
        this.onChange = entity?.onChange ?? null;
        this.isDropdownValueNumber = entity?.isDropdownValueNumber ?? this.isDropdownValueNumber;

        this.defaultValue = entity?.defaultValue ?? null;

        this.inputModalFieldValueFormatter = new InputModalFieldValueFormatter(this);
    }

    width?(): string {
        return this.widthPercentage + '%';
    }

    onInputChange?() {
        if (this.onChange != null) {
            this.onChange();
        }
    }

    getMaxDate?(): Date {
        if (this.maxValue == null || !(this.maxValue instanceof Date)) {
            return null;
        }
        return this.maxValue as Date;
    }

    openSearchModal?() {
        const modalRef = this.searchEntityModalComponent.dialogService.open(this.searchType, {
            header: this.searchEntityModalComponent.translate.instant(this.searchTitle),
            width: this.searchWidth,
            data: this.searchDataFunc != null ? this.searchDataFunc() : this.searchData,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((dataResult: any) => {
            if (dataResult !== undefined) {
                this.openSearchModalResult(dataResult);
            }
        });
    }

    openSearchModalResult?(dataResult: any) {
        if (dataResult instanceof Array) {
            this.inputValue = dataResult.map(entity => entity[this.searchEntityPropName]).join(', ');
        } else {
            this.inputValue = dataResult[this.searchEntityPropName];
        }
    }

    isDropdownValueNumber?(): boolean {
        return this.dropdownOptionsArray?.some(entity => 
            typeof entity[this.getDropdownValuePropName()] === 'number'
        );
    }

    getDropdownValuePropName?(): string {
        return this.dropdownValue ?? 'value';
    }

    setInputValue?(value: any, detectChanges: boolean = true) {
        this.inputValue = value;
        if(detectChanges) {
            this.searchEntityModalComponent?.getChangeDetectorRef().detectChanges();
        }
    }

    static CreateInstance(entity: InputModalField): InputModalField {
        return new InputModalField(entity);
    }

}

export class InputModalFieldValueFormatter {
    private _actualInputValue?: string;
    private _formatedInputValue: string[] = [];

    constructor(
        public inputModalField: InputModalField
    ) { }

    get formatedInputValue(): string[] {
        if (StringUtils.toStringNeverNull(this.inputModalField.inputValue) !== this._actualInputValue) {
            this._actualInputValue = StringUtils.toStringNeverNull(this.inputModalField.inputValue);
            this._formatedInputValue = this._actualInputValue.split(',').filter(item => item.trim() !== '');
        }
        return this._formatedInputValue;
    }

    set formatedInputValue(value: string[]) {
        this._formatedInputValue = value;
        this.inputModalField.inputValue = this._formatedInputValue?.join(',');
    }
}

export enum InputModalFieldEditableType {
    nonEditableField,
    textField,
    numberField,
    calendarField,
    dropdownField,
    listboxField,
    multiSelectField
}