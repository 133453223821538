import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { AreaSectionService } from 'src/app/area-section/area-section.service';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { AdditionalInfoContainer, InputModalField, InputModalFieldContainer, InputModalFieldEditableType, SearchEntityModalComponent } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.component';
import { SearchEntityModalService } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.service';
import { AddressService } from '../../address-form/address.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';
import { SearchEntityModalUtils } from 'src/app/core/utils/search-entity-modal-utils';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { PlantSectionService } from 'src/app/plant-section/plant-section.service';
import { PlantSearch } from 'src/app/core/models';

@Component({
    selector: 'app-search-area-modal',
    templateUrl: '../../../shared/components/base/search-entity-modal/search-entity-modal.component.html',
})
export class SearchAreaComponent extends SearchEntityModalComponent {
  
    plantId: string;

    constructor(
        private areaService: AreaSectionService,
        private offshoreAddressService: OffshoreAddressService,
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        protected addressService:AddressService,
        protected sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected plantSectionService: PlantSectionService,
        protected datePipe: DatePipe,
        protected searchEntityModalService: SearchEntityModalService,
        protected changeDetectorRef: ChangeDetectorRef) {
        super(messageHandler, activeModal, config, preferencesService, translate, loadWheelService, entityCommonService, entityUtilsService, searchEntityModalService, dialogService, changeDetectorRef);
    }

    override initConfigAtributes() {
        this.entityTitle = 'common.area';
        this.entityTitlePlural = 'common.area';

        this.plantId = this.config.data.plantId;
    }

    override ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if(!this.isMainAccess) {
            this.searchAreaByPlantId();
        }
    }

    searchAreaByPlantId() {
        if(!StringUtils.isEmpty(this.plantId)) {
            this.plantSectionService.getPlantById(Number(this.plantId)).subscribe((plantSearch: PlantSearch) => {
                if(plantSearch) {
                    this.selectedRadioButton = plantSearch.plantOffshore === '1' ? 'offshore' : 'onshore';
                    this.radioButtonChange();
                    this.setInputValue('plantId', plantSearch.plantId);
                    this.search(false);
                }
            });
        } else {
            this.setInputValue('plantId', null);
        }
    }

    protected override getRadioButtons(): InputModalField[] {
        return [
            InputModalField.CreateInstance({ entityPropName: 'onshore', displayName: 'area.searchArea.onshore' }),
            InputModalField.CreateInstance({ entityPropName: 'offshore', displayName: 'area.searchArea.offshore' })
        ];
    }


    protected override getInputModalFieldContainer(): InputModalFieldContainer[] {
            let inputsModalFieldContainer: InputModalFieldContainer[] = [];
            let inputsModalField: InputModalField[] = [];
            
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'areaName', displayName: 'area.searchArea.areaName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantId', displayName: 'area.searchArea.plantId', editableType: InputModalFieldEditableType.numberField, maxLength: 7 }));
            
            const isOffshore = this.selectedRadioButton === 'offshore';
            if (!isOffshore) {
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'physCountry', displayName: 'area.searchArea.physCountry', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'countryId', dropdownLabel: 'countryNameConcat', onChange: () => this.onChangePhysCountry() }));
            }
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));

            inputsModalField = [];
            if (!isOffshore) {
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'physState', displayName: 'area.searchArea.physState', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'sId', dropdownLabel: 'stateConcat', isDropdownValueNumber: () => { return true },  disabledCondition: this.isDisabledState, dropdownOptionsArray: [] }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'physCity', displayName: 'area.searchArea.physCity', editableType: InputModalFieldEditableType.textField }));
            }else{
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'waterBody', displayName: 'area.searchArea.waterBody', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'waterBodyId', dropdownLabel: 'waterBodyNameConcat', isDropdownValueNumber: () => { return true } }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'fieldName', displayName: 'area.searchArea.areaTableFieldName', editableType: InputModalFieldEditableType.textField }));
            }
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'recordStatus', displayName: 'common.recordStatus', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'RecordStatusID', dropdownLabel: 'RecordStatusDescription' }));

            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
           
            return inputsModalFieldContainer;
    }

    isDisabledState = (): boolean => {
        const countryField = this.searchInputModalField('physCountry');
        return !countryField?.inputValue;
    };

    protected override getColumnsInfo(): ColumnEntityInfo[] {
        const isOffshore = this.selectedRadioButton === 'offshore';
        let columnsInfo: ColumnEntityInfo[] = [];

        columnsInfo.push({ entityPropName: 'areaId', columnHeader: 'area.searchArea.areaTableAreaId', widthPercentage: 9 });
        columnsInfo.push({ entityPropName: 'areaName', columnHeader: 'area.searchArea.areaTableAreaName', widthPercentage: 18 });
        if(!isOffshore){
            columnsInfo.push({ entityPropName: 'physStateName', columnHeader: 'area.searchArea.areaTableState', widthPercentage: 8 });
            columnsInfo.push({ entityPropName: 'physCountryName', columnHeader: 'area.searchArea.areaTableCountry', widthPercentage: 8 });
        }else{
            columnsInfo.push({ entityPropName: 'waterbodyName', columnHeader: 'area.searchArea.areaTableWaterBody', widthPercentage: 8 });
            columnsInfo.push({ entityPropName: 'fieldName', columnHeader: 'area.searchArea.areaTableFieldName', widthPercentage: 8 });
        }
        columnsInfo.push({ entityPropName: 'plantName', columnHeader: 'area.searchArea.areaTablePlantName', widthPercentage: 15 });
        columnsInfo.push({ entityPropName: 'companyName', columnHeader: 'area.searchArea.areaTableCompanyName', widthPercentage: 15 });
        
        return columnsInfo;
    }

    protected override getAdditionalInfoContainers(): AdditionalInfoContainer[]{
        const isOffshore = this.selectedRadioButton === 'offshore';
        let aditionalInfoContainer: AdditionalInfoContainer[] = [];

        let columnsInfo: ColumnEntityInfo[] = [];
        if(!isOffshore){
            columnsInfo.push({ entityPropName: 'physAddressV1', columnHeader: 'area.additionalInfo.physAddress', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'mailAddressV1', columnHeader: 'area.additionalInfo.mailAddress', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'physCountry', columnHeader: 'area.additionalInfo.country', widthPercentage: 50 }); 
            columnsInfo.push({ entityPropName: 'telephone', columnHeader: 'area.additionalInfo.telephone', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'faxNumber', columnHeader: 'area.additionalInfo.faxNumber', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 50 });
            
            aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
    
            columnsInfo = [];
            columnsInfo.push({ entityPropName: 'companyName', columnHeader: 'area.additionalInfo.ultimateOwner', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'operatorName', columnHeader: 'area.additionalInfo.operator', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'plantStatus', columnHeader: 'area.additionalInfo.plantStatus', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'recordStatusDesc', columnHeader: 'common.recordStatus', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'noEmployees', columnHeader: 'area.additionalInfo.noEmployees', widthPercentage: 50 });
    
            aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
        }else{
            columnsInfo.push({ entityPropName: 'offshoreBlockV1', columnHeader: 'area.additionalInfo.quadrantBlock', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'mailAddressV1', columnHeader: 'area.additionalInfo.mailAddress', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'waterbodyName', columnHeader: 'area.additionalInfo.waterbody', widthPercentage: 50 }); 
            columnsInfo.push({ entityPropName: 'telephone', columnHeader: 'area.additionalInfo.telephone', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'faxNumber', columnHeader: 'area.additionalInfo.faxNumber', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 50 });
            
            aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
    
            columnsInfo = [];
            columnsInfo.push({ entityPropName: 'companyName', columnHeader: 'area.additionalInfo.ultimateOwner', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'operatorName', columnHeader: 'area.additionalInfo.operator', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'plantStatus', columnHeader: 'area.additionalInfo.plantStatus', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'recordStatusDesc', columnHeader: 'common.recordStatus', widthPercentage: 50 });
            columnsInfo.push({ entityPropName: 'noEmployees', columnHeader: 'area.additionalInfo.noEmployees', widthPercentage: 50 });
    
            aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
        }
        
        return aditionalInfoContainer;
    }
    
    protected override searchEntitiesDelegate(params: HttpParams, wheel: SpinnerProcess): Observable<any> {
        return this.areaService.searchArea(params); 
    }

    protected override getHttpParamsFromInputsModalField(): HttpParams {
        let params: HttpParams = super.getHttpParamsFromInputsModalField();
        params = params.set('offshore', this.selectedRadioButton == 'offshore'? '1':'0');
        return params;
    }

    onChangePhysCountry() {
        const countryField = this.searchInputModalField('physCountry');
        const stateField = this.searchInputModalField('physState');          
        SearchEntityModalUtils.loadStates(countryField, stateField, this.addressService);
    }

    override loadList() {
        this.loadRecordStatus();
        this.loadWaterBodies();
        this.loadOnshoreCountries();
    }

    private loadRecordStatus() {
        SearchEntityModalUtils.loadRecordStatus(this.searchInputModalField('recordStatus'), this.sharedService);
    }

    private loadOnshoreCountries() {
        SearchEntityModalUtils.loadOnshoreCountries(this.searchInputModalField('physCountry'), this.addressService);
    }

    private loadWaterBodies() {
        SearchEntityModalUtils.loadAllWaterBodies(this.searchInputModalField('waterBody'), this.offshoreAddressService);
    }

}

