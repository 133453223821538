import { ChangeDetectorRef, Component } from '@angular/core';
// Modal Dialog
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// Service
// Models
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CompanySectionService } from 'src/app/company-section/company-section.service';
import { CompanySearch } from 'src/app/core/models';
import { CompanyClassificationValue, CompanyStatusValue, HexaColor, RecordStatusValue } from 'src/app/core/models/constants';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { SearchEntityModalUtils } from 'src/app/core/utils/search-entity-modal-utils';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { AdditionalInfoContainer, InputModalField, InputModalFieldContainer, InputModalFieldEditableType, SearchEntityModalComponent } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.component';
import { SearchEntityModalService } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.service';
import { CompanyClasses, CompanyStatus, Country, Industry, RecordStatus } from '../../../core/models/common';
import { AddressService } from '../../address-form/address.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';

@Component({
    selector: 'app-search-modal',
    templateUrl: '../../../shared/components/base/search-entity-modal/search-entity-modal.component.html'
})

export class SearchCompanyComponent extends SearchEntityModalComponent {
    
    fromCompanyScreen: boolean;
    parentCompanyForm: FormGroup<any>;
    filterParent: boolean = false;
    ownerList: Array<number> = [];

    constructor(
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        protected companySectionService: CompanySectionService,
        protected addressService:AddressService,
        protected sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected datePipe: DatePipe,
        protected searchEntityModalService: SearchEntityModalService,
        protected changeDetectorRef: ChangeDetectorRef) {
        super(messageHandler, activeModal, config, preferencesService, translate, loadWheelService, entityCommonService, entityUtilsService, searchEntityModalService, dialogService, changeDetectorRef);
    }

    override initConfigAtributes() {
        this.entityTitle = 'common.company';
        this.entityTitlePlural = 'common.companies';
        this.fromCompanyScreen = this.config.data?.fromCompanyScreen;
        this.parentCompanyForm = this.config.data?.parentCompanyForm;
        this.filterParent =  this.config.data?.filterParent?? false;
        if (this.filterParent) {
            this.ownerList = this.config.data?.ownerList;
        }
    }


    protected override getInputModalFieldContainer(): InputModalFieldContainer[] {
        let inputsModalFieldContainer: InputModalFieldContainer[] = [];

            let inputsModalField: InputModalField[] = [];
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'companyName', displayName: 'common.companyName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'previousName', displayName: 'previous_name', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'industryCode', displayName: 'industry_code', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'industryCode', dropdownLabel: 'industryDescription' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'companyStatus[]', displayName: 'company_status', editableType: InputModalFieldEditableType.multiSelectField, dropdownValue: 'StatusID', dropdownLabel: 'StatusDescription', defaultValue: [CompanyStatusValue.Active], required: true  }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'companyClassification', displayName: 'classification', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'ClassificationID', dropdownLabel: 'ClassificationDescription', defaultValue: this.getDefaultClassificationValue(), disabledCondition: this.isDisabledSCompanyClassification}));
            
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
            
            inputsModalField = [];
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'physCountry', displayName: 'phys_country', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'countryId', dropdownLabel: 'countryNameConcat', onChange: () => this.onChangePhysCountry()  })); 
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'physState', displayName: 'phys_state', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'sId', dropdownLabel: 'stateConcat', isDropdownValueNumber: () => { return true },  disabledCondition: this.isDisabledState, dropdownOptionsArray: []  }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'physCity', displayName: 'phys_city', editableType: InputModalFieldEditableType.textField, maxLength: 50 }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'physCounty', displayName: 'phys_county', editableType: InputModalFieldEditableType.textField, maxLength: 40 }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'recordStatus', displayName: 'common.recordStatus', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'RecordStatusID', dropdownLabel: 'RecordStatusDescription'  }));

            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
       
        return inputsModalFieldContainer;
    }

    private isOwnerSearch(): boolean {
        return (this.fromCompanyScreen && this.parentCompanyForm?.get('classification')?.value === CompanyClassificationValue.Parent);
    }

    private getDefaultClassificationValue() {
        if (this.isOwnerSearch()) {
            return CompanyClassificationValue.Holding;
        } 
        return null;
    }

    isDisabledSCompanyClassification = (): boolean => {
        return this.isOwnerSearch();
    }

    isDisabledState = (): boolean => {
        const countryField = this.searchInputModalField('physCountry');
        return !countryField?.inputValue;
    };

    protected override getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];

        columnsInfo.push({ entityPropName: 'companyId', columnHeader: 'company.searchModal.companyId', widthPercentage: 12, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'companyCompositeName', columnHeader: 'company.searchModal.companyNameSec', widthPercentage: 15, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'classification', columnHeader: 'company.searchModal.cl/s', widthPercentage: 15, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'recordStatusDesc', columnHeader: 'company.searchModal.recordStatus', widthPercentage: 15, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'companyStatusDesc', columnHeader: 'company.searchModal.companyStatus', widthPercentage: 15, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'mailAddressV1', columnHeader: 'company.searchModal.physAddress', widthPercentage: 15, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'physCity', columnHeader: 'company.searchModal.physCity', widthPercentage: 15, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        columnsInfo.push({ entityPropName: 'mailStateDesc', columnHeader: 'company.searchModal.physState', widthPercentage: 15, getColumnTextColor: this.getColumnTextColor, getColumnTooltip: this.getColumnTooltip });
        
        return columnsInfo;
    }

    getColumnTooltip(implementedComponent: SearchCompanyComponent, rowData: any, rowIndex: number): string {
        return  implementedComponent.getRowTooltip(rowData);
    }

    getColumnTextColor(implementedComponent: SearchCompanyComponent, rowData: any, rowIndex: number): any {
        return implementedComponent.canAssociate(rowData) && implementedComponent.canAssociateParent(rowData) ? '' : HexaColor.lightGray;
    }

    override onRowDblclick(rowData: any){
        if (this.canAssociate(rowData)) {
            if (this.canAssociateParent(rowData)) {
                if (this.filterParent) {
                    this.companySectionService.companyInTree(this.ownerList, rowData, this);
                } else {
                    this.activeModal.close(rowData);
                }
            }
        } else {
            this.messageHandler.show(this.translate.instant('company.searchModal.cannotAssociate'), MessageType.INFO);
        }
    }

    protected override getAdditionalInfoContainers(): AdditionalInfoContainer[] {
        let aditionalInfoContainer: AdditionalInfoContainer[] = [];

        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'mailAddressV1', columnHeader: 'mailing address', widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'mailCountryName', columnHeader: 'mail_country', widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'previousName', columnHeader: 'previous_name', widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'phoneNo', columnHeader: 'telephone', widthPercentage: 50, displayValueFunc: this.formatPhoneNumber });
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 50 });
        
        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));

        columnsInfo = [];
        columnsInfo.push({ entityPropName: 'companyCompositeMailState', columnHeader: 'mail_city_state', widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'webSite', columnHeader: 'web_site', widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'stockSymbol', columnHeader: 'stock_symbol', widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'fax', columnHeader: 'fax_number', widthPercentage: 50, displayValueFunc: this.formatFaxNumber });
    
        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));

        return aditionalInfoContainer;
    }

    private formatFaxNumber(implementedComponent: SearchCompanyComponent, rowData: CompanySearch): string {
        return implementedComponent.convertToPhoneNumberFormat(rowData.fax);
    }

    private formatPhoneNumber(implementedComponent: SearchCompanyComponent, rowData: CompanySearch): string {
        return implementedComponent.convertToPhoneNumberFormat(rowData.phoneNo);
    }

    convertToPhoneNumberFormat(value:any) {
        const phoneNumber = StringUtils.toStringNeverNull(value);
        if(StringUtils.isEmpty(phoneNumber)) {
            return '';
        }

        if(phoneNumber.length < 3) {
            return phoneNumber; 
        } 
        return phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3);
    }

    protected override searchEntitiesDelegate(params: HttpParams): Observable<any> {
        return this.companySectionService.searchCompany(params);
    }
    
    override loadList() {
        this.loadRecordStatus();
        this.loadcompanyClassification();
        this.loadIndustry();
        this.loadCompanyStatus();
        this.loadCountries();
    }

    private loadRecordStatus() {
        SearchEntityModalUtils.loadRecordStatus(this.searchInputModalField('recordStatus'), this.sharedService);
    }

    private loadIndustry() {
        SearchEntityModalUtils.loadIndustryCode(this.searchInputModalField('industryCode'), this.sharedService);
    }

    private loadcompanyClassification() {
        this.sharedService.getCompanyClasses()
            .subscribe((classification: CompanyClasses[]) =>
            {
                this.searchInputModalField('companyClassification').dropdownOptionsArray = classification;
            })
    }

    private loadCompanyStatus() {
        this.sharedService.getStatus()
            .subscribe({
                next: (status: CompanyStatus[]) => {
                    this.searchInputModalField('companyStatus[]').dropdownOptionsArray = status;
                }
            });
    }

    private loadCountries() {
        SearchEntityModalUtils.loadOnshoreCountries(this.searchInputModalField('physCountry'), this.addressService);
    }

    onChangePhysCountry() {
        const countryField = this.searchInputModalField('physCountry');
        const stateField = this.searchInputModalField('physState');          
        SearchEntityModalUtils.loadStates(countryField, stateField, this.addressService);
    }

    getRowTooltip(company: CompanySearch): string {
        let tooltip: string = '';
        if (!this.canAssociate(company)) {
            tooltip =
                (company.recordStatus === RecordStatusValue.Trash
                    || company.companyStatus == CompanyStatusValue.Closed
                    || company.companyStatus == CompanyStatusValue.SuspendedUnresolved) ?
                    this.translate.instant('company.searchModal.invalidAStatus', { status: this.getInvalidStatusMessage(company) }) :
                    this.translate.instant('company.searchModal.invalidAnStatus', { status: this.getInvalidStatusMessage(company) });
        }
        return tooltip;
    }
    
    getInvalidStatusMessage(company: CompanySearch): string {
        return (company.recordStatus &&
            company.recordStatus !== RecordStatusValue.Enhanced &&
            company.recordStatus !== RecordStatusValue.Archived &&
            company.recordStatus !== RecordStatusValue.Unconfirmed) ?
            company.recordStatusDesc : company.companyStatusDesc;
    }

    canAssociate(company: CompanySearch): boolean {
        return this.isMainAccess ||
            (this.companySectionService.canAssociateCompany(company));
    }

    canAssociateParent(company: CompanySearch): boolean {
        return  !this.filterParent || this.companySectionService.canAssociateParentCompany(company);
    }

    parentDataOk(companyData: CompanySearch) {
        this.activeModal.close(companyData);
    }

}