import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Constants } from "../models/constants";
import { TelemetryService } from "./telemetry.service";

const DP = new DatePipe('en-US');

export class CsvHeaderItem {
    field: string;
    description: string;

    constructor(field: string, description: string) {
        this.field = field;
        this.description = description;
    }
}

export class CsvData {
    title: string;
    subtitle: string;
    headers: Array<CsvHeaderItem>;
    rows: Array<any>;

    constructor(title: string, subtitle: string, headers: Array<CsvHeaderItem>, rows: Array<any>) {
        this.title = title;
        this.subtitle = subtitle;
        this.headers = headers;
        this.rows = rows;
    }
}

@Injectable({
    providedIn: 'root'
})
export class CsvService {
    constructor(private telemetry: TelemetryService) { }

    exportToCsv(filename: string, csvData: CsvData) {
        const rows = csvData.rows;
        const headers = csvData.headers;

        if (!csvData || !rows.length || !headers.length) {
            return;
        }

        const separator = ',';
        let csvContent = '';

        if (csvData.title) {
            csvContent += csvData.title + '\n';
        }

        if (csvData.subtitle) {
            csvContent += csvData.subtitle + '\n\n';
        }

        let csvHeaderContent = '';
        for (let i = 0; i < headers.length; i++) {
            const header: CsvHeaderItem = headers[i];
            if (csvHeaderContent.length > 0) {
                csvHeaderContent += separator;
            }
            csvHeaderContent += `"${header.description}"`;
        }

        csvContent = csvContent + csvHeaderContent + '\n' +
            rows.map(row => {
                return headers.map(k => {
                    let cell = row[k.field] === null || row[k.field] === undefined ? '' : row[k.field];
                    
                    if(cell instanceof Date)
                    {
                       cell = DP.transform(cell, Constants.STANDARD_DATE_FORMAT);
                    }

                    return `"${cell}"`;
                }).join(separator);
            }).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const navigator = (window.navigator as any);
        if (navigator.msSaveOrOpenBlob) { // IE 10+
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        
        this.telemetry.featureUsage("exportToCsv");
    }

}
