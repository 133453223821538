import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadWheelService } from '../components/load-wheel/load-wheel.service';
import { MessageType } from '../components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../components/messages/message-handler/message-handler.service';
import { Plants, Projects } from '../core/models';
import { Boilers, Drives, News, OfflineEvent, OfflineEventStatus, Pipeline, TransmissionLine } from '../core/models/common';
import { OfflineEventStatusValue } from '../core/models/constants';
import { AssetsType } from '../core/models/enumerations/assets-type';
import { EntityName } from '../core/models/enumerations/entity-name';
import { SaveType } from '../core/models/enumerations/save-type';
import { OfflineEventsDisplay } from '../core/models/offline-event/display/offline-event-display';
import { OfflineEventEquipment } from '../core/models/offline-event/display/offline-event-equipments-display';
import { OfflineEventsBoilersDisplay } from '../core/models/offline-event/display/offline-events-boilers-display';
import { OfflineEventsDrivesDisplay } from '../core/models/offline-event/display/offline-events-drives-display';
import { OfflineEventSearch, OfflineEventSearchAdapter } from '../core/models/search/offline-event-search';
import { SpinnerProcess } from '../core/models/spinner-process';
import { MVTOperations } from '../core/mvt-operations';
import { RecordLockingFlow } from '../core/record-locking/record-locking-flow';
import { EntityCommonService } from '../core/services/entity-common.service';
import { LockSameUserMessageService, LockSameUserResult } from '../core/services/lock-same-user-message.service';
import { TelemetryService } from '../core/services/telemetry.service';
import { EntityUtilsService } from '../core/utils/entity-utils.service';

@Injectable({
    providedIn: 'root'
})
export class OfflineEventSectionService {

    constructor(
        private recordLockingFlow: RecordLockingFlow,
        private http: HttpClient,
        private messageHandlerService: MessageHandlerService,
        private loadWheelService: LoadWheelService,
        private entityCommonService: EntityCommonService,
        private translate: TranslateService,
        private lockSameUserMessageService: LockSameUserMessageService,
        private offlineEventSearchAdapter: OfflineEventSearchAdapter,
        private entityUtilsService: EntityUtilsService,
        private telemetry: TelemetryService,
    ) { }

    offlineEventByID(offlineEventId: number): Observable<OfflineEvent[]> {
        return this.http.get<OfflineEvent>(`${environment.apiUrl}offlineEvent/offlineEventByID/${offlineEventId}`)
            .pipe(
                map((data: any) =>  OfflineEvent.BuildOfflineEvents(data.response))
            );
    }

    searchOfflineEvents(httpParams: HttpParams): Observable<OfflineEventSearch[]> {
        httpParams = EntityUtilsService.initializeMissingParams(httpParams, 
            [ 
            'unitName','ownerName','operatorName',
            'plantName','unitType','sicCode',
            'industryCode','unitId','plantId',
            'sicCindustryCodeode','unitId','plantId',
            'searchOfflineEventsIds','unitId','offlineEventsIds',
            'recordStatus','assetId','assetName',
            ]);
        const searchStart: number = performance.now();

        return this.http.get<OfflineEventSearch[]>(`${environment.apiUrl}offlineEvent/search`, { params: httpParams })
            .pipe(
                map((data: any) => {
                        this.entityUtilsService.verifyMaxResult(data);
                        let entities: Array<OfflineEventSearch> = data.response;

                        this.telemetry.entitySearchTime("offlineEvent", performance.now() - searchStart);

                        return entities.map(item => OfflineEventSearch.CreateInstance(item, this.translate));
                })
            );
    }

    getOfflineEventDetailsById(offlineEventId: string, isReload: boolean = false, quickAccess: boolean = false): Promise<OfflineEventsDisplay> {
        let mainLockID: number = 0;

        if (isReload) {
            mainLockID = this.recordLockingFlow.getLockID(EntityName.OFFLINE_EVENT, offlineEventId);
        }

        return new Promise<OfflineEventsDisplay>((resolve) => {
            this.lockSameUserMessageService.validateEntityIsLockedByUser(this.recordLockingFlow, EntityName.OFFLINE_EVENT, offlineEventId, mainLockID, quickAccess).then((lockSameUserResult: LockSameUserResult) => {
                if(lockSameUserResult.openEntity) {
                    this.getEntityDetailsById(offlineEventId, mainLockID, false).subscribe((entityDisplay: OfflineEventsDisplay) => {
                        resolve(entityDisplay);
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    private getEntityDetailsById(offlineEventId: string, mainLockID: number, onlyForRead: boolean): Observable<OfflineEventsDisplay> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.offlineEvent"));
        return this.http.get<OfflineEventsDisplay>(`${environment.apiUrl}offlineEvent/${offlineEventId}/${mainLockID}/${onlyForRead}`)
            .pipe(
                map((data: any) => {
                    let offlineEventDisplay = null;
                    if (data.response) {
                        offlineEventDisplay = OfflineEventsDisplay.BuildOfflineEventDisplay(data.response);

                        if (offlineEventDisplay.offlineEventId) {
                            if (offlineEventDisplay.hasDiffUserLockError()) {
                                this.messageHandlerService.show(offlineEventDisplay.lockError, MessageType.INFO);
                            }

                            // Set the locking info into lock global list.
                            this.recordLockingFlow.setLockItem(EntityName.OFFLINE_EVENT, offlineEventDisplay.offlineEventId, offlineEventDisplay.lockId, offlineEventDisplay.lockMode);
                            this.recordLockingFlow.initLockRefreshTimer(EntityName.OFFLINE_EVENT, offlineEventDisplay.offlineEventId, offlineEventDisplay.lockId);
                        } else {
                            this.messageHandlerService.show(offlineEventDisplay.lockError, MessageType.ERROR);
                        }
                    } else {
                        const errorType = MessageHandlerService.errorType(data);
                        if (errorType === MessageType.INFO) {
                            this.entityCommonService.sendEntityNotFoundEvent();
                        } else if (errorType !== MessageType.NONE && errorType !== MessageType.SESSION_INVALID) {
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), errorType);
                        }
                    }
                    this.loadWheelService.hideWheel(wheel);
                    return offlineEventDisplay;
                }),

                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })

            );
    }

    offlineEventNextId(): Observable<OfflineEventsDisplay> {
        return this.http.get<any>(`${environment.apiUrl}offlineEvent/nextId`)
            .pipe(
                map((data: any) => {
                    const offlineEventDisplay = OfflineEventsDisplay.BuildNewOfflineEvent(data.response);
                    if (offlineEventDisplay && offlineEventDisplay.offlineEventId) {
                        // Set the locking info into lock global list.
                        this.recordLockingFlow.setLockItem(EntityName.OFFLINE_EVENT, offlineEventDisplay.offlineEventId, -1);
                    }
                    return offlineEventDisplay;
                }
                ));
    }

    getOfflineEventById(offlineEventId: number): Observable<OfflineEventSearch> {
        return this.http.get<OfflineEventSearch>(`${environment.apiUrl}offlineEvent/offlineEventByID/${offlineEventId}`)
            .pipe(
                map((data: any) => {
                    return this.offlineEventSearchAdapter.adapt(data.response[0]);
                })
            );
    }

    saveOfflineEventDetails(offlineEventDisplay: OfflineEventsDisplay): Observable<OfflineEventsDisplay> {
        offlineEventDisplay = this.entityUtilsService.prepareEntityForSave(offlineEventDisplay) as OfflineEventsDisplay;
        return this.http.put<OfflineEventsDisplay>(`${environment.apiUrl}offlineEvent/`, offlineEventDisplay)
            .pipe(
                map((data: any) => {
                    if (data.response === null) {
                        if (data.exception !== null) {
                            this.messageHandlerService.setExceptionData(data);
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), MessageType.EXCEPTION);
                        } else if (data.message !== null) {
                            if (offlineEventDisplay.saveType === SaveType.TYPE_QC || offlineEventDisplay.saveType === SaveType.UPDATE) {
                                //When the user attempts to QC a record while the lock was already cleared,
                                //this//the above warning message should still be thrown; however, the record should not reload.
                                if (data.messageType !== MessageType.WARNING.valueOf()) {
                                    this.entityCommonService.sendReloadEvent();
                                }
                            } else {
                                offlineEventDisplay.intDataDepMigError = data.message;
                            }

                        }
                    } else {
                        const isQcUpdateOrAmend = offlineEventDisplay.saveType === SaveType.TYPE_QC ||
                        offlineEventDisplay.saveType === SaveType.AMENDMENT ||
                        offlineEventDisplay.saveType === SaveType.UPDATE;

                        const afterClearFunc = () => this.entityCommonService.sendPreReleaseEvent();
                        this.recordLockingFlow.clearLockFlow(EntityName.OFFLINE_EVENT, String(offlineEventDisplay.offlineEventId), isQcUpdateOrAmend, afterClearFunc);
                    }
                    return data.response;
                })
            );
    }

    getProjectsInfo(offlineEventId: number, isUnconfirmed: boolean = false) {
        return this.getProjectRequest(offlineEventId, isUnconfirmed)
            .pipe(
                map((data: any) => Projects.BuildProjects(data.response))
            );
    }

    private getProjectRequest(offlineEventId: number, isUnconfirmed: boolean) {
        return this.http.get<Projects>(`${environment.apiUrl}offlineEvent/projects/${offlineEventId}/${isUnconfirmed}`);
    }

    getOfflineEventPlants(offlineEventId: number) {
        return this.http.get<Plants>(`${environment.apiUrl}offlineEvent/plants/${offlineEventId}`)
            .pipe(
                map((data: any) => Plants.BuildPlants(data.response))
            );
    }

    getOfflineEventBoilers(offlineEventId: number) {
        return this.http.get<Boilers>(`${environment.apiUrl}offlineEvent/boilers/${offlineEventId}`)
            .pipe(
                map((data: any) => Boilers.BuildBoilers(data.response))
            );
    }

    getOfflineEventDrives(offlineEventId: number) {
        return this.http.get<Drives>(`${environment.apiUrl}offlineEvent/drives/${offlineEventId}`)
            .pipe(
                map((data: any) => Drives.BuildDrives(data.response))
            );
    }

    getOfflineEventNews(offlineEventId: number,productNumberList: string) {
        return this.http.get<News>(`${environment.apiUrl}offlineEvent/news/${productNumberList}/${offlineEventId}`)
            .pipe(
                map((data: any) => data && data.response ? News.BuildNew(data.response) : [])
            );
    }

    getOfflineEventPipelines(offlineEventId: number) {
        return this.http.get<Pipeline>(`${environment.apiUrl}offlineEvent/assets/${offlineEventId}/${AssetsType.PIPELINE}`)
            .pipe(
                map((data: any) => Pipeline.BuildPipeline(data.response))
            );
    }

    getOfflineEventTLines(offlineEventId: number) {
        return this.http.get<TransmissionLine>(`${environment.apiUrl}offlineEvent/assets/${offlineEventId}/${AssetsType.TRANSMISSION_LINE}`)
            .pipe(
                map((data: any) => TransmissionLine.BuildTransmissionLine(data.response))
            );
    }

    getDrivesByUnitID(unitId: number) {
        return this.http.get<OfflineEventEquipment>(`${environment.apiUrl}offlineEvent/drivesByUnitID/${unitId}`)
            .pipe(
                map((data: any) => { 
                return OfflineEventEquipment.BuildOfflineEventEquipment(data.response, EntityName.TURBINE)})
            );
    }

    getBoilerByUnitID(unitId: number) {
        return this.http.get<OfflineEventEquipment>(`${environment.apiUrl}offlineEvent/boilersByUnitID/${unitId}`)
            .pipe(
                map((data: any) => OfflineEventEquipment.BuildOfflineEventEquipment(data.response,EntityName.BOILER))
            );
    }

    getDrivesByUnitIDs(unitIds: Array<number>) {
        const httpParams = new HttpParams().append('unitIds', unitIds.join(","));
        return this.http.get<OfflineEventEquipment>(`${environment.apiUrl}offlineEvent/drivesByUnitIDs`, { params: httpParams })
            .pipe(
                map((data: any) => { 
                return OfflineEventEquipment.BuildOfflineEventEquipment(data.response, EntityName.TURBINE)})
            );
    }

    getBoilerByUnitIDs(unitIds: Array<number>) {
        const httpParams = new HttpParams().append('unitIds', unitIds.join(","));
        return this.http.get<OfflineEventEquipment>(`${environment.apiUrl}offlineEvent/boilersByUnitIDs`, { params: httpParams })
            .pipe(
                map((data: any) => OfflineEventEquipment.BuildOfflineEventEquipment(data.response,EntityName.BOILER))
            );
    }

    unitsIdFutureCapacityByOfflineEvents(unitIds: Array<string>): Observable<Array<number>> {
        const httpParams = new HttpParams().append('unitIds', unitIds.join(","));
        return this.http.get<Array<any>>(`${environment.apiUrl}offlineEvent/unitsIdFutureCapacityByOfflineEvents`, 
                { params: httpParams })
                .pipe(
                    map((data: any) => {
                        let result: Array<number> = [];
                        if(data.response) {
                            data.response.forEach(unitCap => {
                                if(result.indexOf(unitCap.UNIT_ID) < 0) {
                                    result.push(unitCap.UNIT_ID);
                                }
                            });
                        }
                        return result;
                    })
                );
    }

    public getOfflineEventStatusTooltip(offlineEventStatus: string, 
        startDateParam: Date | string, endDateParam: Date | string, now: Date,
        offlineEventStatuses: Array<OfflineEventStatus>, isUnitTooltip: boolean): string {
        let oeStatusTooltip = '';
        let derivedEventStatus: string = this.calculateDerivedEventStatus(offlineEventStatus, startDateParam, endDateParam, now);
        if(offlineEventStatus &&  derivedEventStatus &&
            offlineEventStatus !== derivedEventStatus){
                oeStatusTooltip = this.translate.instant(isUnitTooltip ? 'offlineEvent.offlineEventUnitStatusTooltip' : 'offlineEvent.offlineEventStatusTooltip', 
                    {status: offlineEventStatuses.find(
                        status => status.offlineEventStatusId === derivedEventStatus).offlineEventStatusConcat});
        }
        return oeStatusTooltip;
    }

    private calculateDerivedEventStatus(offlineEventStatus: string, startDateParam: Date | string, endDateParam: Date | string, now: Date): string{
        if(offlineEventStatus){
            if(offlineEventStatus === OfflineEventStatusValue.Cancelled){
                return OfflineEventStatusValue.Cancelled;
            }
            if(startDateParam && endDateParam) {
                let endDate = new Date(endDateParam);
                let startDate = new Date(startDateParam);
                const endDateFormatted = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                const startDateFormatted  = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                const nowFormatted  = new Date(now.getFullYear(), now.getMonth(), now.getDate());

                if (endDateFormatted < nowFormatted) {
                    return OfflineEventStatusValue.Past;
                } else {
                    if (startDateFormatted  > nowFormatted) {
                        return OfflineEventStatusValue.Future;
                    }else{
                        return OfflineEventStatusValue.Ongoing;
                    }
                }
            
            }            
        }
        return null;
    }

    removeAssociatedEquipments(offlineEventEquipments: Array<OfflineEventEquipment>, offlineEventDisplay: OfflineEventsDisplay, unitId: string) {
        let drives: Array<OfflineEventsDrivesDisplay> = offlineEventDisplay.offlineEventsDrivesDisplay;
        let boilers: Array<OfflineEventsBoilersDisplay> = offlineEventDisplay.offlineEventsBoilersDisplay;
        let equipments = [...offlineEventEquipments];
        equipments.forEach(equipment => {
            //if unitId params is empty delete all rows, or delete associated equipments
            if (!unitId || String(equipment.unitId) === String(unitId)) {
                MVTOperations.removeItem(boilers, 'equipmentId', String(equipment.equipmentId));
                MVTOperations.removeItem(drives, 'equipmentId', String(equipment.equipmentId));
                offlineEventEquipments.splice(offlineEventEquipments.indexOf(equipment), 1);
            }
        });
    }



}


