import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// Modules
import { CoreModule } from './core/core.module';
import { PrimeNgModule } from './primeng.module';
import { SharedModule } from './shared/shared.module';
// Route
import { AppRoutingModule } from './app.routing';
// Interceptors
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { PendingRequestsInterceptor } from './core/interceptor/pending-requests.interceptor';

// Service
import { MessageService } from 'primeng/api';
import { RegistrationService } from './core/services/registration.service';
import { VersionService } from './core/services/version.service';
// Components
import { AppComponent } from './app.component';
import { LoadWheelComponent } from './components/load-wheel/load-wheel.component';
import { UserModalComponent } from './components/modals/user-modal/user-modal.component';
import { ContactDetailsModule } from './contact-details-section/contactDetails.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
//Translation
import { DatePipe, DecimalPipe, LOCATION_INITIALIZED } from '@angular/common';
import { APP_INITIALIZER, Injector } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BehaviorSubject } from 'rxjs';
import { CountryStateModalComponent } from './components/modals/country-state-modal/country-state-modal.component';
import { NotificationModalComponent } from './components/modals/notification-modal/notification-modal.component';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';
import { ServerDateService } from './core/services/serverdate.service';
import { MessageHandlerService } from './components/messages/message-handler/message-handler.service';
import { GeographyRequestModalComponent } from './components/modals/geography-request-modal/geography-request-modal.component';
import { CommonDirectivesModule } from './common-directives.modules';
import './core/patchs/primeng-dropdown-patch';
import './core/patchs/primeng-listbox-patch';
import './core/patchs/primeng-dialog-patch';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const pendingRequestsSubject = new BehaviorSubject<number>(0);

const DEFAULT_LANG = 'en';
export function languageInit(translate: TranslateService, injector: Injector) {
    return () => new Promise<any>((resolve, reject) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            translate.setDefaultLang(DEFAULT_LANG);
            translate.use(DEFAULT_LANG).subscribe({
                next: () => console.info('Default language successfully initialized.'),
                error: (e) => {
                   const msg = 'Problem with language initialization.';
                   console.error(msg, e);
                   reject(msg);
                },
                complete: () => resolve(null)
            });
        });
    });
}

export function settingsInit(registrationService: RegistrationService) {
    const settingsError = (e: any, reject: { (reason?: any): void }) => {
        const msg = 'Problem with settings initialization.';
        console.error(msg, e);
        reject(msg);
    };

    return () => new Promise<any>((resolve, reject) => {
        registrationService.getOperatingMode().subscribe({
            next: (mode: string) => {
                if(!mode)
                {
                   settingsError(null, reject);
                   return;
                }

                // Change some behavior based on the operating mode, such as color schemes
                let customOperatingModeColor = getComputedStyle(document.documentElement).getPropertyValue(`--operating-mode-color-${mode}`);
                if (customOperatingModeColor !== '') {
                    document.documentElement.style.setProperty('--operating-mode-color', customOperatingModeColor);
                }
            },
            error: (e) => settingsError(e, reject),
            complete: () => resolve(null)
        })
    });
}

export function versionInit(versionService: VersionService) {
    return () => new Promise<any>((resolve) => {
       versionService.loadServerVersion(true).subscribe({complete: () => resolve(null)});
    });
}

export function serverDateInit(serverDate: ServerDateService) {
    return () => new Promise<any>((resolve) => {
       serverDate.init().subscribe({complete: () => resolve(null)});
    });
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient],
            }
        }),
        AppRoutingModule,
        SharedModule,
        CoreModule,
        PrimeNgModule,
        FormsModule,
        ReactiveFormsModule,
        ContactDetailsModule,
        CommonDirectivesModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true,
          registrationStrategy: 'registerImmediately'
        })
    ],

    declarations: [
        AppComponent,
        AuthLayoutComponent,
        AdminLayoutComponent,
        UserModalComponent,
        LoadWheelComponent,
        GeographyRequestModalComponent,
        CountryStateModalComponent,
        NotificationModalComponent
    ],
    exports: [],
    providers: [
        { provide: APP_INITIALIZER, useFactory: languageInit, deps: [TranslateService, Injector], multi: true },
        { provide: APP_INITIALIZER, useFactory: settingsInit, deps: [RegistrationService], multi: true },
        { provide: APP_INITIALIZER, useFactory: versionInit, deps: [VersionService], multi: true },
        { provide: APP_INITIALIZER, useFactory: serverDateInit, deps: [ServerDateService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: PendingRequestsInterceptor, multi: true },
        { provide: 'PENDING_REQUESTS_SUBJECT', useValue: pendingRequestsSubject },
        MessageService,
        MessageHandlerService,
        VersionService,
        DatePipe,
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
