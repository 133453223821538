import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import { MessageType } from '../components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../components/messages/message-handler/message-handler.service';
import { SaveType } from '../core/models/enumerations/save-type';
import { CompanyOwners, Pipeline, Plants, PlantsDisplay, PlantSearch, PlantSearchAdapter, Projects, TransmissionLine } from '../core/models/index';
// Interface
import { TranslateService } from '@ngx-translate/core';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { LoadWheelService } from '../components/load-wheel/load-wheel.service';
import { AlertInfo } from '../core/models/alerts';
import { Areas, Boilers, Drives, Equipment, GreenhouseGasEmission, MiningEquipments, News, OfflineEvent, PipelineMeterBlocked, PlantsLTSA, Product, ShalePlay, SicCode, Site, Tank, Units } from '../core/models/common';
import { CompanyStatusValue, RecordStatusValue } from '../core/models/constants';
import { EntityName } from '../core/models/enumerations/entity-name';
import { ProbAssociation } from '../core/models/prob-association';
import { SpinnerProcess } from '../core/models/spinner-process';
import { RecordLockingFlow } from '../core/record-locking/record-locking-flow';
import { EntityCommonService } from '../core/services/entity-common.service';
import { LockSameUserMessageService, LockSameUserResult } from '../core/services/lock-same-user-message.service';
import { SharedService } from '../core/services/shared.service';
import { TelemetryService } from '../core/services/telemetry.service';
import { WebsiteService } from '../core/services/website.service';
import { EntityUtilsService } from '../core/utils/entity-utils.service';

@Injectable({
    providedIn: 'root'
})
export class PlantSectionService {

    constructor(
        private adapter: PlantSearchAdapter,
        private recordLockingFlow: RecordLockingFlow,
        private http: HttpClient,
        private messageHandlerService: MessageHandlerService,
        private loadWheelService: LoadWheelService,
        private entityCommonService: EntityCommonService,
        private translate: TranslateService,
        private plantSearchAdapter: PlantSearchAdapter,
        private website: WebsiteService,
        private entityUtilsService: EntityUtilsService,
        private sharedService: SharedService,
        private lockSameUserMessageService: LockSameUserMessageService,
        private telemetry: TelemetryService,
    ) {
    }

    getPlantDetailsById(plantId: string, isReload: boolean = false, quickAccess: boolean = false): Promise<PlantsDisplay> {
        let mainLockID: number = 0;

        if (isReload) {
            mainLockID = this.recordLockingFlow.getLockID(EntityName.PLANT, plantId);
        }

        return new Promise<PlantsDisplay>((resolve) => {
            this.lockSameUserMessageService.validateEntityIsLockedByUser(this.recordLockingFlow, EntityName.PLANT, plantId, mainLockID, quickAccess).then((lockSameUserResult: LockSameUserResult) => {
                if(lockSameUserResult.openEntity) {
                    this.getEntityDetailsById(plantId, mainLockID, false).subscribe((entityDisplay: PlantsDisplay) => {
                        this.lockSameUserMessageService.clearContactsLockedsBySameUser(lockSameUserResult, entityDisplay?.plantsContactInfoDisplay);
                        resolve(entityDisplay);
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    private getEntityDetailsById(plantId: string, mainLockID: number, onlyForRead: boolean): Observable<PlantsDisplay> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.plant"));

        return this.http.get<PlantsDisplay>(`${environment.apiUrl}plant/${plantId}/${mainLockID}/${onlyForRead}`)
            .pipe(
                map((data: any) => {
                    let plantDisplay = null;
                    if (data.response) {
                        plantDisplay = PlantsDisplay.BuildPlantDisplay(data.response);

                        if (plantDisplay.plantId) {
                            if (plantDisplay.hasDiffUserLockError()) {
                                this.messageHandlerService.show(plantDisplay.lockError, MessageType.INFO);
                            }

                            // Set the locking info into lock global list.
                            this.recordLockingFlow.setLockItem(EntityName.PLANT, plantDisplay.plantId, plantDisplay.lockId, plantDisplay.lockMode);
                            this.recordLockingFlow.initLockRefreshTimer(EntityName.PLANT, plantDisplay.plantId, plantDisplay.lockId);
                        } else {
                            this.messageHandlerService.show(plantDisplay.lockError, MessageType.ERROR);
                        }
                    } else {
                        const errorType = MessageHandlerService.errorType(data);
                        if (errorType === MessageType.INFO) {
                            this.entityCommonService.sendEntityNotFoundEvent();
                        } else if (errorType !== MessageType.NONE && errorType !== MessageType.SESSION_INVALID) {
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), errorType);
                        }
                    }
                    this.loadWheelService.hideWheel(wheel);
                    return plantDisplay;
                }),

                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })

            );
    }

    getPlantById(plantId: number): Observable<PlantSearch> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.plant"));
        return this.http.get<PlantSearch>(`${environment.apiUrl}plant/${plantId}`)
            .pipe(
                map((data: any) => {
                        this.loadWheelService.hideWheel(wheel);
                        return this.adapter.adapt(data.response[0]);
                })
            );
    }

    getAssociatedCompanyById(plantId: number): Observable<PlantSearch> {
        return this.getPlantById(plantId).pipe(
            map((plant: PlantSearch) => {
                if (!this.canAssociateCompany(plant)) {
                    this.messageHandlerService.show(this.translate.instant('plant.searchModal.cannotAssociate'), MessageType.INFO);
                    return null;
                }
                return plant;
            })
        );
    }

    canAssociateCompany(plant: PlantSearch): boolean {
        return plant.plantStatusDesc === CompanyStatusValue.Active &&
            (plant.recordStatus === RecordStatusValue.Enhanced ||
                plant.recordStatus === RecordStatusValue.Archived ||
                plant.recordStatus === RecordStatusValue.Unconfirmed
            );
    }

    savePlantDetails(plantDisplay: PlantsDisplay): Observable<PlantsDisplay> {
        return this.http.put<PlantsDisplay>(`${environment.apiUrl}plant/`, plantDisplay)
            .pipe(
                map((data: any) => {
                    if (data.response === null) {
                        if (data.exception !== null) {
                            this.messageHandlerService.setExceptionData(data);
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), MessageType.EXCEPTION);
                        } else if (data.message !== null) {
                            if (plantDisplay.saveType === SaveType.TYPE_QC || plantDisplay.saveType === SaveType.UPDATE) {
                                //When the user attempts to QC a record while the lock was already cleared,
                                //this//the above warning message should still be thrown; however, the record should not reload.
                                if (data.messageType !== MessageType.WARNING.valueOf()) {
                                    this.entityCommonService.sendReloadEvent();
                                }
                            } else {
                                plantDisplay.intDataDepMigError = data.message;
                            }

                        }
                    } else {
                        const isQcUpdateOrAmend = plantDisplay.saveType === SaveType.TYPE_QC ||
                            plantDisplay.saveType === SaveType.AMENDMENT ||
                            plantDisplay.saveType === SaveType.UPDATE;

                        const afterClearFunc = () => this.entityCommonService.sendPreReleaseEvent();
                        this.recordLockingFlow.clearLockFlow(EntityName.PLANT, String(plantDisplay.plantId), isQcUpdateOrAmend, afterClearFunc);
                    }
                    return data.response;
                })
            );
    }

    plantNextId(): Observable<PlantsDisplay> {
        return this.http.get<any>(`${environment.apiUrl}plant/nextId`)
            .pipe(
                map((data: any) => {
                    const plantDisplay = PlantsDisplay.BuildNewPlant(data.response);
                    if (plantDisplay && plantDisplay.plantId) {
                        // Set the locking info into lock global list.
                        this.recordLockingFlow.setLockItem(EntityName.PLANT, plantDisplay.plantId, -1);
                    }
                    return plantDisplay;
                }
                ));
    }

    getAlertInfo(plantId: number): Observable<AlertInfo> {
        return this.http.get<AlertInfo>(`${environment.apiUrl}plant/loadPlantRegionInformationByPlantId/${plantId}`)
            .pipe(
                map((data: any) => AlertInfo.CreateInstance(data.response[0]))
            );
    }

    getTLineInfo(plantId: number): Observable<TransmissionLine[]> {
        return this.http.get<TransmissionLine>(`${environment.apiUrl}plant/tlines/${plantId}`)
            .pipe(
                map((data: any) => TransmissionLine.BuildTransmissionLine(data.response))
            );
    }

    getPlantsInfo(plantId: number) {
        return this.http.get<Plants>(`${environment.apiUrl}plant/plants/${plantId}`)
            .pipe(
                map((data: any) => Plants.BuildPlants(data.response))
            );
    }

    getOwnersInfo(plantId: number) {
        return this.http.get<CompanyOwners>(`${environment.apiUrl}plant/owners/${plantId}`)
            .pipe(
                map((data: any) => CompanyOwners.BuildCompOwners(data.response))
            );
    }

    getSitesInfo(plantId: number) {
        return this.http.get<Site>(`${environment.apiUrl}plant/sites/${plantId}`)
            .pipe(
                map((data: any) => Site.BuildSite(data.response))
            );
    }

    private getProjectRequest(plantId: number, isUnconfirmed: boolean, shouldFilter: boolean) {
        return this.http.get<Projects>(`${environment.apiUrl}plant/projects/${plantId}/${isUnconfirmed}/${shouldFilter}`);
    }


    getProjectsInfo(plantId: number, isUnconfirmed: boolean = false, shouldFilter: boolean = false) {
        return this.getProjectRequest(plantId, isUnconfirmed, shouldFilter)
            .pipe(
                map((data: any) => Projects.BuildProjects(data.response))
            );
    }

    getAssociatedProjects(plantId: number, isUnconfirmed: boolean = false, shouldFilter: boolean = false) {
        return this.getProjectRequest(plantId, isUnconfirmed, shouldFilter)
            .pipe(
                map((data: any) => ProbAssociation.BuildFromProjectResult(data.response))
            );
    }

    getAreasInfo(plantId: number) {
        return this.http.get<Areas>(`${environment.apiUrl}plant/areas/${plantId}`)
            .pipe(
                map((data: any) => Areas.BuildAreas(data.response))
            );
    }

    getUnitsInfo(plantId: number) {
        return this.http.get<Units>(`${environment.apiUrl}plant/units/${plantId}`)
            .pipe(
                map((data: any) => Units.BuildUnits(data.response))
            );
    }

    getBoilersInfo(plantId: number) {
        return this.http.get<Boilers>(`${environment.apiUrl}plant/boilers/${plantId}`)
            .pipe(
                map((data: any) => Boilers.BuildBoilers(data.response))
            );
    }

    getDrivesInfo(plantId: number) {
        return this.http.get<Drives>(`${environment.apiUrl}plant/drives/${plantId}`)
            .pipe(
                map((data: any) => Drives.BuildDrives(data.response))
            );
    }

    getMiningEquipmentsInfo(plantId: number) {
        return this.http.get<MiningEquipments>(`${environment.apiUrl}plant/miningEquipments/${plantId}`)
            .pipe(
                map((data: any) => MiningEquipments.BuildMiningEquipment(data.response))
            );
    }

    getOfflineEventsInfo(plantId: number) {
        return this.http.get<OfflineEvent>(`${environment.apiUrl}plant/offlineEvents/${plantId}`)
            .pipe(
                map((data: any) => OfflineEvent.BuildOfflineEvents(data.response))
            );
    }

    getPlantsLTSAInfo(plantId: number) {
        return this.http.get<PlantsLTSA>(`${environment.apiUrl}plant/plantLTSAs/${plantId}`)
            .pipe(
                map((data: any) => PlantsLTSA.BuildPlantsLTSA(data.response))
            );
    }

    getPipelineInfo(plantId: number): Observable<Pipeline[]> {
        return this.http.get<Pipeline>(`${environment.apiUrl}plant/pipelines/${plantId}`)
            .pipe(
                map((data: any) => Pipeline.BuildPipeline(data.response))
            );
    }

    getTransmissionLinesInfo(plantId: number): Observable<TransmissionLine[]> {
        return this.http.get<TransmissionLine>(`${environment.apiUrl}plant/transmissionLines/${plantId}`)
            .pipe(
                map((data: any) => TransmissionLine.BuildTransmissionLine(data.response))
            );
    }

    getNewsInfo(plantId: number): Observable<News[]> {
        return this.http.get<News>(`${environment.apiUrl}plant/news/${plantId}`)
            .pipe(
                map((data: any) => News.BuildNew(data.response))
            );
    }

    getTanksInfo(plantId: number): Observable<Tank[]> {
        return this.http.get<Tank>(`${environment.apiUrl}plant/tanks/${plantId}`)
            .pipe(
                map((data: any) => Tank.BuildTank(data.response))
            );
    }

    getShalesPlaysByBasinsIds(basinsIds: number[]): Observable<ShalePlay[]> {
        return this.http.post<ShalePlay>(`${environment.apiUrl}plant/shalePlaysByBasinIds`, basinsIds)
            .pipe(
                map((data: any) => ShalePlay.BuildShalePlays(data.response))
            );
    }

    getEquipmentsInfo(plantId: number): Observable<Equipment[]> {
        return this.http.get<Equipment>(`${environment.apiUrl}plant/equipments/${plantId}`)
            .pipe(
                map((data: any) => Equipment.BuildEquipment(data.response))
            );
    }

    plantProductInfoById(plantId: number): Observable<Product[]> {
        return this.http.get<Equipment>(`${environment.apiUrl}plant/plantProductInfoById/${plantId}`)
            .pipe(
                map((data: any) => Product.BuildProduct(data.response))
            );
    }

    getSicCodesFilteredBySicCode(sicCodeID?, sicCodeName?, industryCode?) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.sicCode"));
        return this.http.get(`${environment.apiUrl}plant/search/sicCode?sicCodeID=${sicCodeID}&sicCodeName=${sicCodeName}&industryCode=${industryCode}`)
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return data.response
                }),
                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })
            );
    }

    searchSicCodes(sicCodeID?, sicCodeName?, industryCode?): Observable<SicCode[]> {
        return this.getFilteredSicCodes(sicCodeID, sicCodeName, industryCode)
            .pipe(
                map((data: any) => {
                    if (data && data !== null) {
                        return SicCode.BuildSicCode(data);
                    }
                    return data;
                })
            );
    }

    private getFilteredSicCodes(sicCodeID?, sicCodeName?, industryCode?) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.sicCode"));
        const httpParams = new HttpParams()
            .append('sicCodeID', sicCodeID)
            .append('sicCodeName', sicCodeName)
            .append('industryCode', industryCode);

        if(!this.entityUtilsService.checkForValueRequired(httpParams, wheel)){
            return of([]);
        }

        return this.http.get(`${environment.apiUrl}plant/search/sicCode`, { params: httpParams })
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return data.response
                }),
                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })
            );
    }

    private filterPlantsByMvOrderAndOwnerName(plants: Array<any>): Array<any> {
        return plants.filter(plant => {
            return plant.MV_ORDER == '1' || ((plant.OWNER_NAME == null || plant.OWNER_NAME == '') && (plant.MV_ORDER == null || plant.MV_ORDER == ''));
        });
    }

    private removeDuplicatedPlants(plants: Array<any>): Array<any> {
        let uniquePlants: Array<any> = [];
        plants.forEach(plant => {
            if (!uniquePlants.some(uniquePlant => uniquePlant.PLANT_ID == plant.PLANT_ID)) {
                uniquePlants.push(plant);
            }
        });
        return uniquePlants;
    }

    private sortPlantsByPlantName(plants: Array<any>): Array<any> {
        return plants.sort((a, b) => {
            if (a.PLANT_NAME < b.PLANT_NAME) return -1;
            if (a.PLANT_NAME > b.PLANT_NAME) return 1;
            return 0;
        });
    }

    searchPlant(httpParams: HttpParams): Observable<PlantSearch[]> {
        
        httpParams = EntityUtilsService.initializeMissingParams(httpParams, 
            [ 
            'plantOwner','plantName','plantStatus','industryCode','sicCode','plantCountry',
            'plantState','plantCity','plantCounty','pecZone','plantOffshore','plantFieldName',
            'plantAreaName','plantWaterBody','plantRecordStatus','plantProbability' 
            ]);

        const searchStart: number = performance.now();

        return this.http.get<PlantSearch[]>(`${environment.apiUrl}plant/search`, { params: httpParams })
            .pipe(
                map((data: any) => {
                    this.entityUtilsService.verifyMaxResult(data);
                    let plants: Array<any> = data.response;

                    if (plants.length > 1) {
                        const plantOnwerValue = httpParams.get('plantOwner')
                        if (plantOnwerValue == null || plantOnwerValue == '') {
                            plants = this.filterPlantsByMvOrderAndOwnerName(plants);
                        } else if (plantOnwerValue != null && plantOnwerValue != '') {
                            plants = this.removeDuplicatedPlants(plants);
                        }

                        plants = this.sortPlantsByPlantName(plants);
                    }

                    this.telemetry.entitySearchTime("plant", performance.now() - searchStart);

                    return plants.map(item => this.plantSearchAdapter.adapt(item));
                })
            )
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getSicCodes(): Observable<SicCode[]> {
        return this.http.get<SicCode>(`${environment.apiUrl}plant/sicCodes`)
            .pipe(
                map((data: any) => SicCode.BuildSicCode(data.response))
            );
    }

    getPipelinesByPlantId(plantId: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}plant/pipelines/${plantId}`)
            .pipe(
                map((data: any) => Pipeline.BuildPipeline(data.response))
            );

    }

    validateDunsFormat(value: string): boolean {
        const pattern = /^\(?([0-9]{2})[-]([0-9]{3})[-]([0-9]{4})$/g;
        return pattern.test(value);
    }

    openFlow(row: any) {
        this.website.goToWebsite(environment.flowUrl + `?edMode=true&code=plant_pipe_meter_flow&meterNo=${row.meterNo}`);
    }

    openTotalFlow(row: any[], plantId: number) {
        const meterNos: string = row.map(e => e.meterNo).join(',');
        this.website.goToWebsite(environment.flowUrl + `?edMode=true&code=plant_pipe_meter_flow_ed_all&entityId=${plantId}&meterNo=${meterNos}`);
    }

    showCapacityConversions(): void {
        this.sharedService.showCapacityConversions();
    }

    plantIdByFacilityId(facilityId:string ,plantId: string): Observable<any> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.greenhouseGas"));
        const httpParams = new HttpParams()
            .append('facilityId', facilityId)
            .append('plantId', plantId);

        if(!this.entityUtilsService.checkForValueRequired(httpParams, wheel)){
            return of([]);
        }
        return this.http.get(`${environment.apiUrl}plant/plantsByFacility?facilityId=${facilityId}&plantId=${plantId}`)
        .pipe(
            map((data: any) => {
                this.loadWheelService.hideWheel(wheel);
                return data.response
            }),
            catchError((error) => {
                this.loadWheelService.hideWheel(wheel);
                return null;
            })
        );

    }

    plantGasEmissionByYear(facilityIds:string[]): Observable<GreenhouseGasEmission[]> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.greenhouseGas"));
        return this.http.post<GreenhouseGasEmission>(`${environment.apiUrl}plant/plantsGasEmissionByYear`, facilityIds)
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return GreenhouseGasEmission.BuildEntitiesList(data.response);
                })
            );
    }

    getPlantsByMeterNos(pipelineMeterNos: string[] ,plantId: string): Observable<PipelineMeterBlocked[]> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.pipelineMeters"));
        return this.http.post<PipelineMeterBlocked[]>(`${environment.apiUrl}plant/plantsByMeterNos/${plantId}`, pipelineMeterNos)
        .pipe(
            map((data: any) => {
                this.loadWheelService.hideWheel(wheel);
                return PipelineMeterBlocked.BuildPipelineMeterList(data.response);
            }),
            catchError((error) => {
                this.loadWheelService.hideWheel(wheel);
                return [];
            })
        );
    }

}
