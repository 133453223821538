import { Listbox } from 'primeng/listbox';

const originalOnFocusout = Listbox.prototype.onFocusout;

Listbox.prototype.onFocusout = function (event: FocusEvent) {
    if(this.tabindex > 0) {
        this.firstHiddenFocusableElement.nativeElement.tabIndex = this.tabindex;
        this.lastHiddenFocusableElement.nativeElement.tabIndex = this.tabindex;
    } else {
        originalOnFocusout.apply(this, [event]);
    }
};
