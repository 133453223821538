import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Third Party
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
// Rxjs
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Models
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { LoadWheelService } from 'src/app/components/load-wheel/load-wheel.service';
import { PecZoneSearchParams } from '../interfaces/ipec-zone';
import { PipelineMeterSearchParams } from '../interfaces/ipipelineMeter';
import { ProductSearchParams } from '../interfaces/iproduct';
import { CompanyClasses, CompanyStatus, Industry, RecordStatus, StockChanges } from '../models';
import { AreaStatus, AreaType, BoilerStatus, Category, ControlArea, CountyType, Currency, DateResolution, EmailStatus, EquipmentCategory, EquipmentStatus, EquipmentType, FirmType, FlowIndicator, FunctionalTitle, GasGenRegion, GasGenRegions, InstallationType, JobFunction, KvRating, LocationQti, LTSAStatus, LTSATypes, MarketRegion, MarketTrend, NercRegion, NercSubRegion, OfficeLocation, OfflineEventCause, OfflineEventStatus, OfflineEventType, OilGasBasin, PADDRegion, PaddRegion, PecZone, PipelineMeter, PlantStatus, Product, ProductStatus, ProductType, SicCode, SicProduct, StateType, Status, UnitTypes, WorldRegion, WorldSaleRegion } from '../models/common';
import { ElectricalMetricConversionValue, ElectricalMetricValue } from '../models/constants';
import { DrivenEquipmentType } from '../models/drive';
import { Firm, FirmSearchParams } from '../models/firm';
import { ManufacturerEquipmentType } from '../models/manufacturer';
import { MiningEquipmentStatus } from '../models/mining-equipment';
import { PlantProbability, PlantSource, PlantType, PlatformType } from '../models/plant';
import { Author, PecActivity, ProjectProbability, ProjectSource, ProjectStatus } from '../models/project';
import { SpinnerProcess } from '../models/spinner-process';
import { TankStatus } from '../models/tank';
import { CrudeSlateGroup, CrudeSlateType, FutureStatus, UnitStatus, UnitType } from '../models/unit';
import { EntityUtilsService } from '../utils/entity-utils.service';
import { StringUtils } from '../utils/string-utils';
import { WebsiteService } from './website.service';
import { IWorldRegionSearchParams } from '../interfaces/search/iworld-region-search-params';


@Injectable({
    providedIn: 'root'
})
export class SharedService {

    searchParams: PipelineMeterSearchParams = {
        meterNo: '',
        meterName: '',
        pipelineName: '',
        operatorName: '',
        category: null,
        locProp: '',
        locQti: null,
        flow: null,
        admin1: '',
        admin2: '',
        recordedSearch: false,
    }

    worldRegionsearchParams: IWorldRegionSearchParams = {
        worldRegionName: '',
        recordedSearch: false,
    }

    private disableForm$ = new BehaviorSubject<boolean>(false);
    disableFormChange$ = this.disableForm$.asObservable();
    private appRightSideNativeElement: any = null;

    constructor(
        private http: HttpClient,
        private loadWheelService: LoadWheelService,
        private website: WebsiteService,
        private translate: TranslateService,
        private entityUtilsService: EntityUtilsService) { }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getStatus(): Observable<CompanyStatus[]> {
        return this.http.get<CompanyStatus>(`${environment.apiUrl}shared/companyStatuses`)
            .pipe(
                map((data: any) => CompanyStatus.BuildCompanyStatus(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getIndustryCode(): Observable<Industry[]> {
        return this.http.get<Industry>(`${environment.apiUrl}shared/industryCodes`)
            .pipe(
                map((data: any) => Industry.BuildIndustry(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getAllIndustryCodes(): Observable<Industry[]> {
        return this.http.get<Industry>(`${environment.apiUrl}shared/allIndustryCodes`)
            .pipe(
                map((data: any) => Industry.BuildIndustry(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getRecordStatus(): Observable<RecordStatus[]> {
        return this.http.get<RecordStatus>(`${environment.apiUrl}shared/recordStatus`)
            .pipe(
                map((data: any) => RecordStatus.BuildRecordStatus(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getAreaTypes(): Observable<AreaType[]> {
        return this.http.get<AreaType>(`${environment.apiUrl}shared/areaTypes`)
            .pipe(
                map((data: any) => AreaType.BuildAreaType(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getAreaStatuses(): Observable<AreaStatus[]> {
        return this.http.get<AreaStatus>(`${environment.apiUrl}shared/areaStatuses`)
            .pipe(
                map((data: any) => AreaStatus.BuildAreaStatuses(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getTankStatuses(): Observable<TankStatus[]> {
        return this.http.get<TankStatus>(`${environment.apiUrl}shared/tankStatuses`)
            .pipe(
                map((data: any) => TankStatus.BuildTankStatus(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getBoilerStatuses(): Observable<BoilerStatus[]> {
        return this.http.get<BoilerStatus>(`${environment.apiUrl}shared/boilerStatuses`)
            .pipe(
                map((data: any) => BoilerStatus.BuildBoilerStatuses(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getEquipmentStatuses(): Observable<EquipmentStatus[]> {
        return this.http.get<EquipmentStatus>(`${environment.apiUrl}shared/equipmentStatuses`)
            .pipe(
                map((data: any) => EquipmentStatus.BuildEquipmentStatuses(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getMiningEquipmentStatuses(): Observable<MiningEquipmentStatus[]> {
        return this.http.get<EquipmentStatus>(`${environment.apiUrl}shared/miningEquipmentStatuses`)
            .pipe(
                map((data: any) => MiningEquipmentStatus.BuildMiningEquipmentStatus(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getFilteredPlantStatus(): Observable<PlantStatus[]> {
        return this.http.get<PlantStatus>(`${environment.apiUrl}shared/plantStatus`)
            .pipe(
                map((data: any) => {
                    let plantStatus = PlantStatus.BuildPlantStatus(data.response);
                    if (plantStatus.length > 0) {
                        plantStatus = plantStatus.filter(item =>
                            item.StatusID !== 'C'
                            && item.StatusID !== 'M'
                            && item.StatusID !== 'S'
                            && item.StatusID !== 'L'
                            && item.StatusID !== 'U');
                    }
                    return plantStatus;
                }
        ));
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getPlantStatus(): Observable<PlantStatus[]> {
        return this.http.get<PlantStatus>(`${environment.apiUrl}shared/plantStatus`)
            .pipe(
                map((data: any) => {
                    let plantStatus = PlantStatus.BuildPlantStatus(data.response);
                    return plantStatus;
                }
                ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getLTSARecordStatuses(): Observable<RecordStatus[]> {
        return this.http.get<RecordStatus>(`${environment.apiUrl}shared/ltsaRecordStatus`)
            .pipe(
                map((data: any) => {
                    let ltsaRecordStatus = RecordStatus.BuildRecordStatus(data.response);
                    return ltsaRecordStatus;
                }
        ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getUnitStatus(): Observable<UnitStatus[]> {
        return this.http.get<UnitStatus>(`${environment.apiUrl}shared/getUnitStatus`)
            .pipe(
                map((data: any) => {
                    return UnitStatus.BuildUnitStatus(data.response);
                }
        ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getPlantProbabilities(): Observable<PlantProbability[]> {
        return this.http.get<PlantProbability>(`${environment.apiUrl}shared/plantProbability`)
            .pipe(
                map((data: any) => {
                    return PlantProbability.BuildPlantProbability(data.response);
                }
        ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getProjectProbabilities(): Observable<ProjectProbability[]> {
        return this.http.get<ProjectProbability>(`${environment.apiUrl}shared/plantProbability`)
            .pipe(
                map((data: any) => {
                    return ProjectProbability.BuildProjectProbability(data.response);
                }
        ));
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getPlantTypes(): Observable<PlantType[]> {
        return this.http.get<PlantType>(`${environment.apiUrl}shared/plantType`)
            .pipe(
                map((data: any) => {
                    return PlantType.BuildPlantType(data.response);
                }
                ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    searchUnitTypes(unitTypesId: string, typeName: string, industryCode): Observable<UnitType[]> {
        let params = new HttpParams().append('unitTypesId', unitTypesId)
                                    .append('typeName', typeName)
                                    .append('industryCode', industryCode !== null ? industryCode : '');
        return this.http.get<UnitType>(`${environment.apiUrl}shared/searchUnitTypes`, {params})
            .pipe(
                map((data: any) => {
                    return UnitType.BuildUnitType(data.response);
                }
        ));
    }

    

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getPlatformTypes(): Observable<PlatformType[]> {
        return this.http.get<PlatformType>(`${environment.apiUrl}shared/platformTypes`)
            .pipe(
                map((data: any) => {
                    return PlatformType.BuildPlatformType(data.response);
                }
                ));
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getPlantSources(): Observable<PlantSource[]> {
        return this.http.get<PlantSource>(`${environment.apiUrl}shared/plantSource`)
            .pipe(
                map((data: any) => {
                    return PlantSource.BuildPlantSource(data.response);
                }
                ));
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getProductStatus(): Observable<ProductStatus[]> {
        return this.http.get<ProductStatus>(`${environment.apiUrl}shared/productStatus`)
            .pipe(
                map((data: any) => ProductStatus.BuildProductStatus(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getProductTypes(): Observable<ProductType[]> {
        return this.http.get<ProductType>(`${environment.apiUrl}shared/productTypes`)
            .pipe(
                map((data: any) => ProductType.BuildProductType(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getDatesResolutions(): Observable<DateResolution[]> {
        return this.http.get<DateResolution>(`${environment.apiUrl}shared/datesResolutions`)
            .pipe(
                map((data: any) => DateResolution.BuildDateResolution(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getCrudeSlateTypes(): Observable<CrudeSlateType[]> {
        return this.http.get<CrudeSlateType>(`${environment.apiUrl}shared/getCrudeSlateTypes`)
            .pipe(
                map((data: any) => {
                    return CrudeSlateType.BuildCrudeSlateType(data.response);
                }
            ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    crudeSlateGroups(): Observable<CrudeSlateGroup[]> {
        return this.http.get<CrudeSlateGroup>(`${environment.apiUrl}shared/crudeSlateGroups`)
            .pipe(
                map((data: any) => {
                    return CrudeSlateGroup.BuildCrudeSlateGroup(data.response);
                }
            ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getFutureCapacityStatuses(): Observable<FutureStatus[]> {
        return this.http.get<FutureStatus>(`${environment.apiUrl}shared/getFutureCapacityStatuses`)
            .pipe(
                map((data: any) => {
                    return FutureStatus.BuildFutureStatus(data.response);
                }
            ));
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy,
      maxCacheCount: 2
    })
    getCurrencies(restricted: boolean): Observable<Currency[]> {
        return this.http.get<Currency>(`${environment.apiUrl}shared/currencies?restricted=` + restricted)
            .pipe(
                map((data: any) => Currency.BuildCurrency(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getStateTypes(): Observable<StateType[]> {
        return this.http.get<StateType>(`${environment.apiUrl}shared/stateTypes`)
            .pipe(
                map((data: any) => StateType.Build(data.response))
            );
    }

    getWorldSalesRegion(worldSalesRegionId: number, worldSalesRegionName: string): Observable<WorldSaleRegion[]> {
        let params = new HttpParams();
        if(worldSalesRegionId) {
            params = params.append('worldSalesRegionId', worldSalesRegionId)
        }
        if(worldSalesRegionName) {
            params = params.append('worldSalesRegionName', worldSalesRegionName)
        }
        return this.http.get<WorldSaleRegion>(`${environment.apiUrl}shared/getWorldSalesRegion`, { params } )
            .pipe(
                map((data: any) => WorldSaleRegion.Build(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getAllWorldRegions(): Observable<WorldRegion[]> {
        return this.getWorldRegion(null);
    }

    getWorldRegion(params: HttpParams): Observable<WorldRegion[]> {
        return this.http.get<{response: any}>(`${environment.apiUrl}shared/getWorldRegion`, { params })
            .pipe(
                map((data: any) => WorldRegion.Build(data.response))
            );
    }

    getWorldRegionById(worldRegionId: string | number): Observable<WorldRegion[]> {
        const params = new HttpParams()
            .append('worldRegionId', worldRegionId.toString())
            .append('worldRegionName', '');
        return this.getWorldRegion(params)
            .pipe(
                map(regions => { return regions.filter(region => { return Number(region.id) === Number(worldRegionId); });})
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getPADDRegion(): Observable<PADDRegion[]> {
        return this.http.get<PADDRegion>(`${environment.apiUrl}shared/paddRegion`)
            .pipe(
                map((data: any) => PADDRegion.Build(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getMarketRegions(): Observable<MarketRegion[]> {
        return this.http.get<MarketRegion>(`${environment.apiUrl}shared/marketRegions`)
            .pipe(
                map((data: any) => MarketRegion.Build(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getGasGenRegions(): Observable<GasGenRegion[]> {
        return this.http.get<GasGenRegion>(`${environment.apiUrl}shared/gasGenRegions`)
            .pipe(
                map((data: any) => GasGenRegion.Build(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getCountyTypes(): Observable<CountyType[]> {
        return this.http.get<CountyType>(`${environment.apiUrl}shared/countyTypes`)
            .pipe(
                map((data: any) => CountyType.Build(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getCompanyClasses(): Observable<CompanyClasses[]> {
        return this.http.get<CompanyClasses>(`${environment.apiUrl}shared/companyClasses`)
            .pipe(
                map((data: any) => CompanyClasses.BuildCompanyClasses(data.response))
            );
    }


    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getUnitType(): Observable<UnitTypes[]> {
        return this.http.get<UnitTypes>(`${environment.apiUrl}shared/unitTypes`)
            .pipe(
                map((data: any) => UnitTypes.BuildUnitTypes(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getUnitsSicCodes(): Observable<SicCode[]> {
        return this.http.get<UnitTypes>(`${environment.apiUrl}unit/unitsSICCodes`)
            .pipe(
                map((data: any) => SicCode.BuildSicCode(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getStockChanges(): Observable<StockChanges[]> {
        return this.http.get<StockChanges>(`${environment.apiUrl}shared/companyStockExchanges`)
            .pipe(
                map((data: any) => StockChanges.BuildStockChange(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getJobFunctions(): Observable<JobFunction[]> {
        return this.http.get<JobFunction>(`${environment.apiUrl}shared/jobFunctions`)
            .pipe(
                map((data: any) => JobFunction.BuildJobFunctions(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getSicProductsContactNotRequired(): Observable<SicProduct[]> {
        return this.http.get<SicProduct>(`${environment.apiUrl}shared/sicProductsContactNotRequired`)
            .pipe(
                map((data: any) => SicProduct.BuildSicProduct(data.response))
            );
    }

    getTitles(titleId: string, titleDesc: string): Observable<any[]> {
        let params = '';
        if (titleId !== null) {
            params += `id=${titleId}`;
        }
        if (titleDesc !== null) {
            if (params !== '') {
                params += '&'
            }
            params += `description=${titleDesc.toUpperCase()}`;
        }
        return this.http.get<any>(`${environment.apiUrl}shared/titles?${params}`)
            .pipe(
                map((data: any) => {
                    let titles = [];
                    if (data.response && data.response.length > 0) {
                        titles = data.response;
                    }
                    return titles;
                })
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    functionalTitles(): Observable<FunctionalTitle[]> {
        return this.http.get<FunctionalTitle>(`${environment.apiUrl}shared/functionalTitles`)
            .pipe(
                map((data: any) => FunctionalTitle.BuildFunctionalTitles(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getCountryExceptions(): Observable<any[]> {
        return this.http.get<any>(`${environment.apiUrl}shared/countryExceptions`)
            .pipe(
                map((data: any) => {
                    let cExceptions = [];
                    if (data.response && data.response.length > 0) {
                        cExceptions = data.response;
                    }
                    return cExceptions;
                })
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getSiteStatus(): Observable<Status[]> {
        return this.http.get<Status>(`${environment.apiUrl}shared/siteStatus`)
            .pipe(
                map((data: any) => Status.BuildStatus(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getProjectStatus(): Observable<ProjectStatus[]> {
        return this.http.get<ProjectStatus>(`${environment.apiUrl}shared/projectStatus`)
            .pipe(
                map((data: any) => ProjectStatus.BuildProjectStatus(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getPaddRegion(): Observable<PaddRegion[]> {
          return this.http.get<PaddRegion>(`${environment.apiUrl}shared/paddRegion`)
              .pipe(
                  map((data: any) => PaddRegion.BuildPaddRegion(data.response))
              );
    }

    getNercRegion(): Observable<NercRegion[]> {
        return this.http.get<NercRegion>(`${environment.apiUrl}shared/nercRegion`)
            .pipe(
                map((data: any) => NercRegion.BuildNercRegion(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getNercSubRegion(): Observable<NercSubRegion[]> {
        return this.http.get<NercSubRegion>(`${environment.apiUrl}shared/nercSubRegion`)
            .pipe(
                map((data: any) => NercSubRegion.BuildNercSubRegion(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getOilGasBasins(): Observable<OilGasBasin[]> {
        return this.http.get<OilGasBasin>(`${environment.apiUrl}shared/basins`)
            .pipe(
                map((data: any) => OilGasBasin.BuildOilGasBasins(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getControlAreas(): Observable<ControlArea[]> {
        return this.http.get<ControlArea>(`${environment.apiUrl}shared/controlAreas`)
            .pipe(
                map((data: any) => ControlArea.BuildControlArea(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getKvRatings(): Observable<KvRating[]> {
        return this.http.get<KvRating>(`${environment.apiUrl}shared/kvRatings`)
            .pipe(
                map((data: any) => KvRating.BuildKvRating(data.response))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getMeterCategories(): Observable<Category[]> {
        return this.http.get<PlantSource>(`${environment.apiUrl}shared/meterCategories`)
            .pipe(
                map((data: any) => {
                    return Category.BuildCategoryList(data.response);
                }
                ));
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getMeterFlowIndicators(): Observable<FlowIndicator[]> {
        return this.http.get<PlantSource>(`${environment.apiUrl}shared/meterFlowIndicators`)
            .pipe(
                map((data: any) => {
                    return FlowIndicator.BuildFlowIndicatorList(data.response);
                }
                ));
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getMeterAllLocQti(): Observable<LocationQti[]> {
        return this.http.get<LocationQti>(`${environment.apiUrl}shared/meterAllLocQti`)
            .pipe(
                map((data: any) => {
                    return LocationQti.BuildLocationQtiList(data.response);
                }
                ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getFirmTypes(): Observable<FirmType[]> {
        return this.http.get<FirmType[]>(`${environment.apiUrl}shared/firmTypes`)
            .pipe(
                map((data: any) => FirmType.BuildFirmTypes(data.response))
            );
    }

    searchPipelineMeter(httpParams: HttpParams): Observable<PipelineMeter[]> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.meter"));
        
        httpParams = EntityUtilsService.initializeMissingParams(httpParams, ['meterNo', 'meterName', 'pipeline', 'operator', 'catId', 'flowInd',
                                                                'locPropDrn', 'locQti', 'admin1', 'admin2', 'umbrellaId', 'umbrellaName' ]);

        return this.http.get<PipelineMeter[]>(`${environment.apiUrl}shared/searchPipelineMeter`, { params: httpParams })
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    this.entityUtilsService.verifyMaxResult(data);
                    return PipelineMeter.BuildPipelineMeterList(data.response);
                })
            )
    }

    searchPipelineMeterByMeterNo(meterNo: string): Observable<PipelineMeter[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('meterNo', meterNo ?? '');

        return this.searchPipelineMeter(httpParams);
    }
    
    
    
    searchHSProducts(product: ProductSearchParams): Observable<Product[]> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.product"));
        const params = new HttpParams()
            .append('productID', product.productID)
            .append('productDesc', product.productDesc)
            .append('unitId', product.unitId);

        if(!this.entityUtilsService.checkForValueRequired(params, wheel)){
            return of([]);
        }    
        return this.http.get<Product>(`${environment.apiUrl}shared/searchHSProducts`, { params })
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return Product.BuildProduct(data.response)
                  })
            );
    }

    searchHSProductsByPlantId(plantId: string, unitId: string): Observable<Product[]> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("searching.products"));
        return this.http.get<Product>(`${environment.apiUrl}shared/searchHSProductsByPlantId/${plantId}/${unitId}`)
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return Product.BuildProduct(data.response)
                  })
            );
    }

    

    getPecZoneData(pecZone: PecZoneSearchParams): Observable<PecZone[]> {
        const params = new HttpParams()
            .append('pecZoneId', pecZone.pecZoneId)
            .append('pecZoneName', pecZone.pecZoneName);
        return this.http.post<Product>(`${environment.apiUrl}shared/getPecZoneData`, params )
            .pipe(
                map((data: any) => PecZone.BuildPecZone(data.response))
            );
    }

    getEntityInLive(entityIdName: string, entityIdValue: number, entityViewName: string) {
        return this.http.get(`${environment.apiUrl}shared/entityInLive?entityIdName=${entityIdName}&entityIdValue=${entityIdValue}&entityViewName=${entityViewName}`)
            .pipe(
                map((data: any) => { return data.response })
            );
    }


    searchSicProducts(sicProductID?, sicProductName?, industryCode?): Observable<SicProduct[]> {
        return this.http.get(`${environment.apiUrl}shared/searchSicProducts?sicProductID=${sicProductID}&sicProductName=${sicProductName}&industryCode=${industryCode}`)
            .pipe(
                map((data: any) => {
                    if (data && data !== null && data.response && data.response !== null) {
                        return SicProduct.BuildSicProduct(data.response);
                    }
                    return data;
                })
            );
    }

    getExactSICProductId(sicProductID, industryCode?): Observable<SicProduct[]> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.sicProduct"));
        return this.http.get(`${environment.apiUrl}shared/getExactSICProductId?sicProductID=${sicProductID}&industryCode=${industryCode}`)
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    if (data && data !== null && data.response && data.response !== null) {
                        return SicProduct.BuildSicProduct(data.response);
                    }
                    return data;
                })
            );
    }

    getContactEmailStatus(email: string): Observable<EmailStatus> {
        return this.http.get<EmailStatus>(`${environment.apiUrl}shared/contactEmailStatus?email=${email}`)
            .pipe(
                map((data: any) => {
                    if (data && data !== null && data.response && data.response !== null) {
                        return EmailStatus.BuildEmailStatus(data.response);
                    }
                    return data;
                }
                ));
    }

    getContactEmailOverrideStatus(email: string): Observable<EmailStatus> {
        return this.http.get<EmailStatus>(`${environment.apiUrl}shared/contactEmailOverrideStatus?email=${email}`)
            .pipe(
                map((data: any) => {
                    if (data && data !== null && data.response && data.response !== null) {
                        return EmailStatus.BuildEmailStatus(data.response);
                    }
                    return data;
                }
                ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getInstallationType(): Observable<InstallationType[]> {
        return this.http.get<InstallationType>(`${environment.apiUrl}shared/installationType`)
            .pipe(
                map((data: any) => InstallationType.BuildInstallationType(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getFirmType(): Observable<FirmType[]> {
        return this.http.get<FirmType>(`${environment.apiUrl}shared/firmTypes`)
            .pipe(
                map((data: any) => FirmType.BuildFirmTypes(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getProjectSources(): Observable<ProjectSource[]> {
        return this.http.get<ProjectSource>(`${environment.apiUrl}shared/projectSource`)
            .pipe(
                map((data: any) => {
                    return ProjectSource.BuildProjectSource(data.response);
                })
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getPecActivities(): Observable<PecActivity[]> {
        return this.http.get<PecActivity>(`${environment.apiUrl}shared/pecActivities`)
            .pipe(
                map((data: any) => {
                    return PecActivity.BuildPecActivity(data.response);
                })
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    allManufactEquipmentType(): Observable<ManufacturerEquipmentType[]> {
        return this.http.get<ManufacturerEquipmentType>(`${environment.apiUrl}shared/allManufactEquipmentType`)
            .pipe(
                map((data: any) => {
                    return ManufacturerEquipmentType.BuildManufacturerEquipmentType(data.response);
                })
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    allDrivenEquipmentTypes(): Observable<DrivenEquipmentType[]> {
        return this.http.get<DrivenEquipmentType>(`${environment.apiUrl}shared/allDrivenEquipmentTypes`)
            .pipe(
                map((data: any) => DrivenEquipmentType.BuildDrivenEquipmentType(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getOfficeLocations(): Observable<OfficeLocation[]> {
        return this.http.get<OfficeLocation>(`${environment.apiUrl}shared/officeLocations`)
            .pipe(
                map((data: any) => {
                    return OfficeLocation.BuildEntity(data.response);
                })
            );
    }

    getAuthorData(lastName: string, authorId: string): Observable<Author[]> {
        let params = new HttpParams();
        if(lastName !== null){
            params = new HttpParams().append('lastName', lastName.toUpperCase());
        }
        if(authorId !== null){
            params =new HttpParams().append('authorId', authorId);
        }
        return this.http.get<Author>(`${environment.apiUrl}shared/getAuthorData`, { params })
            .pipe(
                map((data: any) => {
                    return Author.BuildAuthor(data.response);
                })
        );
    }

    getPecActivityById(pecActivity: string): Observable<PecActivity[]> {
        return this.http.get<PecActivity>(`${environment.apiUrl}shared/getPecActivityById?pecActivityId=${pecActivity}`)
            .pipe(
                map((data: any) => {
                    return PecActivity.BuildPecActivity(data.response);
                })
        );
    }
	
    customSort(event: SortEvent, isAlphaNumeric: boolean = false) {
        event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;

            if ((value1 === null || value1 === '') && value2 !== null)
                result = 1;
            else if (value1 !== null && (value2 == null || value2 === ''))
                result = -1;
            else if ((value1 === null && value2 === null)
                || value1 === '' && value2 === '')
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else if (isAlphaNumeric && typeof value1 === 'string' && typeof value2 !== 'string')
                result = 1;
            else if (isAlphaNumeric && typeof value1 !== 'string' && typeof value2 === 'string')
                result = -1;
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });
    }

    disableForm(disable: boolean): void {
        this.disableForm$.next(disable);
    }

    searchMarketTrendByDesc(marketTrendDescription: string): Observable<MarketTrend[]> {
        let params = new HttpParams();
        
        if(marketTrendDescription !== null){
            params = new HttpParams().append('desc', marketTrendDescription);
        }
    
        return this.http.get<MarketTrend>(`${environment.apiUrl}project/searchMarketTrends`, { params })
        .pipe(
            map((data: any) => {
                return MarketTrend.BuildMarketTrend(data.response);
            })
        );
    }

    searchAECFirm(httpParams: HttpParams, wheel: SpinnerProcess): Observable<Firm[]> {
        
        if(!this.entityUtilsService.checkForValueRequired(httpParams, wheel)){
            return of(null);
        }
    
        return this.http.get<Firm>(`${environment.apiUrl}shared/searchAECFirm`, { params: httpParams })
        .pipe(
            map((data: any) => {
                return Firm.BuildFirm(data.response);
            })
        );
    }
    
    searchMarketTrendByById(marketTrendId:number):Observable<MarketTrend[]>{
        return this.http.get<MarketTrend>(`${environment.apiUrl}shared/searchMarketTrendById/${marketTrendId}`)
        .pipe(
            map((data: any) => MarketTrend.BuildMarketTrend(data.response))
        )
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getLTSAStatus(): Observable<LTSAStatus[]> {
        return this.http.get<LTSAStatus>(`${environment.apiUrl}shared/ltsaStatus`)
            .pipe(
                map((data: any) => {
                    return LTSAStatus.BuildLTSAStatus(data.response);
                }
        ));
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getUnitLTSATypes(): Observable<LTSATypes[]> {
        return this.http.get<LTSATypes>(`${environment.apiUrl}shared/unitLTSATypes`)
            .pipe(
                map((data: any) => {
                    return LTSATypes.BuildLTSATypes(data.response);
                }
        ));
    }

    set appRightSideElement(value) {
        this.appRightSideNativeElement = value;
    }

    get appRightSideElement() {
        return this.appRightSideNativeElement;
    }

    showCapacityConversions(): void {
        this.website.goToWebsite(environment.wikiUrl + '?id=research:capacity_conversion', 'WikiCapacityConversions');
    }

    public getMetricConversion(metric: string, valueConvert: string): string
    {
        let result: string = '';
        if(valueConvert === ''){
            return result;
        }
        if(metric === ElectricalMetricValue.MegaWatts){
            result = (Number(valueConvert)/ElectricalMetricConversionValue.HorsePower).toFixed(3);
        }else if(metric === ElectricalMetricValue.HorsePower){
            result = (Number(valueConvert)*ElectricalMetricConversionValue.HorsePower).toFixed(0);
        }
        return result;
    }

    equipmentTypes(equipmentTypeName: string | null, equipmentTypeId: string | null): Observable<EquipmentType[]> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("equipment.equipmentTypeSearch.loading"));
        
        let params = new HttpParams();
        params = params.append('equipmentTypeId', StringUtils.toStringNeverNull(equipmentTypeId));
        params = params.append('equipmentTypeName', StringUtils.toStringNeverNull(equipmentTypeName));
     
        return this.http.get<EquipmentType[]>(`${environment.apiUrl}shared/equipmentTypes`, { params })
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    this.sortEquipmentTypesByName(data.response);
                    return EquipmentType.BuildEquipmentType(data.response);
                })
            );
    }

    private sortEquipmentTypesByName(types: Array<any>): Array<any> {
        return types.sort((a, b) => {
            if (a.TYPE_DESC < b.TYPE_DESC) return -1;
            if (a.TYPE_DESC > b.TYPE_DESC) return 1;
            return 0;
        });
    }

    getCountReleases(entityName: string, entityIdViewName: string, entityIdName: string, entityIdsValue: string[], entityViewName: string, entityHisName: string, lookingForInLive: boolean): Observable<any> {
        const params = new HttpParams()
          .set('entityName', entityName)
          .set('entityIdViewName', entityIdViewName)
          .set('entityIdName', entityIdName)
          .set('entityIdsValue', entityIdsValue.join(','))
          .set('entityViewName', entityViewName)
          .set('entityHisName', entityHisName)
          .set('lookingForInLive', lookingForInLive);
    
        return this.http.post<any>(`${environment.apiUrl}shared/getCountReleases`, params);
    }

    sendGeographyEmail(addressType: string, emailContent: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}shared/sendGeographyEmail`, 
                {'emailContent': emailContent, 'addressType': addressType});
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getOfflineEventStatuses(): Observable<OfflineEventStatus[]> {
        return this.http.get<OfflineEventStatus>(`${environment.apiUrl}shared/offlineEventStatuses`)
            .pipe(
                map((data: any) => OfflineEventStatus.BuildOfflineEventStatus(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getOfflineEventTypes(): Observable<OfflineEventType[]> {
        return this.http.get<OfflineEventType>(`${environment.apiUrl}shared/offlineEventTypes`)
            .pipe(
                map((data: any) => OfflineEventType.BuildOfflineEventType(data.response))
            );
    }
    
    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getEquipmetCategories(): Observable<EquipmentCategory[]> {
        return this.http.get<EquipmentCategory>(`${environment.apiUrl}shared/outageCategories`)
            .pipe(
                map((data: any) => EquipmentCategory.BuildEquipmentCategory(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getOfflineEventCauses(): Observable<OfflineEventCause[]> {
        return this.http.get<OfflineEventCause>(`${environment.apiUrl}shared/offlineEventCauses`)
            .pipe(
                map((data: any) => OfflineEventCause.BuildOfflineEventCause(data.response))
            );
    }

    getSicCodesByIdsUnits(unitIds: number[]): Observable<SicCode[]> {
        let params = new HttpParams();
        if (unitIds.length > 0) {
            unitIds.forEach(id => {
                params = params.append('unitIds', id.toString());
            });

            return this.http.get<SicCode[]>(`${environment.apiUrl}unit/unitSICCodeByUnitId`, { params }).pipe(
                map((data: any) => SicCode.BuildSicCode(data.response))
            );
        }
        return of([]);
    }
    
}
