import { StringUtils } from "src/app/core/utils/string-utils";
import { AddressService } from "src/app/components/address-form/address.service";
import { Country, Industry, RecordStatus, State } from "src/app/core/models";
import { OffshoreAddressService } from "src/app/components/offshore-address-form/offshore-address.service";
import { CountryWaterBody, WaterBody } from "src/app/core/models/offshoreAddress";
import { InputModalField } from "src/app/shared/components/base/search-entity-modal/search-entity-modal.component";
import { SharedService } from "../services/shared.service";
import { RecordStatusValue } from "../models/constants";
import { SicCode, UnitTypes } from "../models/common";

export class SearchEntityModalUtils {

    static loadStates(countryField: InputModalField, stateField: InputModalField, addressService: AddressService) {
        if (!stateField) {
            return;
        }

        const countryId = countryField.inputValue;
        stateField.setInputValue(null);
        stateField.dropdownOptionsArray = [];

        if (StringUtils.isEmptyInPrimitiveTypes(countryId)) {
            return;
        }

        addressService.getStateByCountry(countryId, '', true)
            .subscribe({
                next: (states: State[]) => {
                    stateField.dropdownOptionsArray = states;
                },
                error: () => {
                    stateField.dropdownOptionsArray = [];
                }
            });
    }

    static loadWaterBodies(countryField: InputModalField, waterBodyField: InputModalField, offshoreAddressService: OffshoreAddressService) {
        if (!waterBodyField) {
            return;
        }

        const countryName = countryField.inputValue;
        waterBodyField.setInputValue(null);
        waterBodyField.dropdownOptionsArray = [];

        if (StringUtils.isEmptyInPrimitiveTypes(countryName)) {
            return;
        }

        offshoreAddressService.getWaterBodies(countryName)
            .subscribe({
                next: (waterBodies: WaterBody[]) => {
                    waterBodyField.dropdownOptionsArray = waterBodies;
                },
                error: () => {
                    waterBodyField.dropdownOptionsArray = [];
                }
            });
    }

    static loadOffshoreFields(waterBody: InputModalField, fieldName: InputModalField, offshoreAddressService: OffshoreAddressService) {
        if (!fieldName) {
            return;
        }

        const waterBodyId = waterBody.inputValue;
        fieldName.setInputValue(null);
        fieldName.dropdownOptionsArray = [];

        if (StringUtils.isEmptyInPrimitiveTypes(waterBodyId)) {
            return;
        }

        offshoreAddressService.getOffshoreFields(Number(waterBodyId), '', false)
            .subscribe({
                next: (fields) => {
                    fieldName.dropdownOptionsArray = fields;
                },
                error: () => {
                    fieldName.dropdownOptionsArray = [];
                }
            });
    }

    static loadOnshoreCountries(countryField: InputModalField, addressService: AddressService) {
        if(!countryField) {
            return;
        }
        addressService.getCountries()
            .subscribe((countries: Country[]) => { 
                countryField.dropdownOptionsArray = countries;
            });
    }

    static loadOffshoreCountries(countryField: InputModalField, offshoreAddressService: OffshoreAddressService) {
        if(!countryField) {
            return;
        }
        offshoreAddressService.getCountriesWaterBodyById(true, '')
            .subscribe((countries: CountryWaterBody[]) => {
                countryField.dropdownOptionsArray =  countries;
            });
    }

    static loadAllWaterBodies(waterBodyField: InputModalField, offshoreAddressService: OffshoreAddressService) {
        if(!waterBodyField) {
            return;
        }
        offshoreAddressService.getAllWaterBodies()
            .subscribe((waterBodies: WaterBody[]) => 
            {
                waterBodyField.dropdownOptionsArray = waterBodies;
            })
    }

    static loadRecordStatus(recordStatusField: InputModalField, sharedService: SharedService) {
        if(!recordStatusField) {
            return;
        }
        sharedService.getRecordStatus()
            .subscribe((record: RecordStatus[]) => 
            {
                const recordStatuses = record.filter(item => item.RecordStatusID !== RecordStatusValue.Pending);
                recordStatusField.dropdownOptionsArray = recordStatuses;
            })
    }

    static loadLTSARecordStatus(recordStatusField: InputModalField, sharedService: SharedService) {
        if(!recordStatusField) {
            return;
        }
        sharedService.getLTSARecordStatuses()
            .subscribe({
                next: (records) => {
                    recordStatusField.dropdownOptionsArray = [...records];
                }
            });
    }

    static loadIndustryCode(industryCodeField: InputModalField, sharedService: SharedService) {
        if(!industryCodeField) {
            return;
        }
        sharedService.getAllIndustryCodes()
            .subscribe((industry: Industry[]) => {
                industryCodeField.dropdownOptionsArray = industry;
            });
    }

    static loadSicCode(sicCodeField: InputModalField, sharedService: SharedService) {
        if(!sicCodeField) {
            return;
        }
        sharedService.getUnitsSicCodes()
            .subscribe((sicCodes: SicCode[]) => {
                sicCodeField.dropdownOptionsArray = sicCodes;
            });
    }

    static loadUnitType(unitTypeField: InputModalField, sharedService: SharedService) {
        if(!unitTypeField) {
            return;
        }
        sharedService.getUnitType()
            .subscribe((unitTypes: UnitTypes[]) => {
                unitTypeField.dropdownOptionsArray = unitTypes;
            });
    }
}
