<div class="container-modal">
    <div *ngIf="this.showInputsFilters()" class="Ex-content-modal">
        <div class="grid pt-1">
            <div *ngIf="componentDescription"  class="col-1 min-w-max">
                <span class="text-xs">{{componentDescription | translate}} </span>
            </div>

            <div class="col-1 min-w-max">
                <button *ngIf="showClearButton" 
                    [tabindex]="baseIndex + 0" 
                    (keydown)="focusCloseButton($event, true)" 
                    (click)="clearAll()" 
                    class="btn-custom">{{'clear'| translate}}
                </button>
            </div>

            <div *ngIf="radioButtons?.length > 0" class="grid col-11">
                <div class="col-1 mt-1 flex justify-content-end min-w-max">
                    <span class="px-0 font-bold text-xs">{{'common.search'| translate}}: </span>
                </div>

                <div *ngFor="let radioButton of radioButtons; index as inputIndex" class="col-1 min-w-max">
                    <p-radioButton [tabindex]="baseIndex + 1" name="filter" [value]="radioButton.entityPropName" [(ngModel)]="selectedRadioButton" (ngModelChange)="radioButtonChange()" label="{{radioButton.displayName | translate}}">
                    </p-radioButton>
                </div>
            </div>
        </div>
        <div class="">
            <form>
                <div class="grid">
                    <div *ngFor="let inputModalFieldContainer of inputsModalFieldContainer;  index as inputContainerIndex" [class]="inputModalFieldContainer.cssClasses">
                        <div *ngFor="let inputModalField of inputModalFieldContainer.inputFieldsArray; index as inputIndex" class="flex -mt-3 pt-1 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{inputModalField.displayName | translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #entityGenericInput
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.numberField"
                                    type="text"
                                    class="form__input-field-style"
                                    [style.flex-basis]="inputModalField.width()"
                                    pInputText
                                    [name]="inputModalField.entityPropName"
                                    [placeholder]="inputModalField.placeholder"
                                    [(ngModel)]="inputModalField.inputValue"
                                    (ngModelChange)="inputModalField.onInputChange()"
                                    [disabled]="inputModalField.disabledCondition && inputModalField.disabledCondition()"
                                    (keydown.enter)="search()"
                                    [maxlength]="inputModalField.maxLength"
                                    [maxwhole]="inputModalField.maxLength"
                                    appOnlyNumbers [maxdecimal]="inputModalField.maxDecimals?.toString()"
                                    [autofocus]="inputContainerIndex === 0 && inputIndex === 0 ? true : false"
                                    [attr.id]="inputModalField.entityPropName"
                                    [tabindex]="baseIndex + inputContainerIndex * 10 + inputIndex + 1">
                                <input
                                    #entityGenericInput
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.textField || inputModalField.editableType == inputModalFieldEditableType.nonEditableField"
                                    type="text"
                                    class="form__input-field-style"
                                    [style.flex-basis]="inputModalField.width()"
                                    pInputText
                                    [name]="inputModalField.entityPropName"
                                    [placeholder]="inputModalField.placeholder"
                                    [(ngModel)]="inputModalField.inputValue"
                                    (ngModelChange)="inputModalField.onInputChange()"
                                    [disabled]="inputModalField.disabledCondition && inputModalField.disabledCondition()"
                                    (keydown.enter)="search()"
                                    [maxlength]="inputModalField.maxLength"
                                    [autofocus]="inputContainerIndex === 0 && inputIndex === 0 ? true : false"
                                    [attr.id]="inputModalField.entityPropName"
                                    [readonly]="inputModalField.editableType == inputModalFieldEditableType.nonEditableField"
                                    [tabindex]="baseIndex + inputContainerIndex * 10 + inputIndex + 1">
                                <p-calendar
                                    #entityGenericInput
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.calendarField"
                                    class="form__input-field-style"
                                    [style.flex-basis]="inputModalField.width()"
                                    [name]="inputModalField.entityPropName"
                                    [(ngModel)]="inputModalField.inputValue"
                                    (ngModelChange)="inputModalField.onInputChange()"
                                    [disabled]="inputModalField.disabledCondition && inputModalField.disabledCondition()"
                                    (keydown.enter)="search()"
                                    dateFormat="dd-M-yy"
                                    showButtonBar="true"
                                    inputStyleClass="calendar-inputtext"
                                    [maxDate]="inputModalField.getMaxDate()"
                                    [showIcon]="true"
                                    [autofocus]="inputContainerIndex === 0 && inputIndex === 0 ? true : false"
                                    [attr.id]="inputModalField.entityPropName"
                                    [tabindex]="baseIndex + inputContainerIndex * 10 + inputIndex + 1">
                                </p-calendar>
                                <p-dropdown
                                    #entityGenericInput
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.dropdownField"
                                    class="form__container-fields-layout--selects"
                                    [style.width]="inputModalField.width()"
                                    [name]="inputModalField.entityPropName"
                                    [(ngModel)]="inputModalField.inputValue"
                                    (ngModelChange)="inputModalField.onInputChange()"
                                    [disabled]="inputModalField.disabledCondition && inputModalField.disabledCondition()"
                                    (keydown.enter)="search()"
                                    [options]="inputModalField.dropdownOptionsArray"
                                    [optionValue]="inputModalField.dropdownValue"
                                    [optionLabel]="inputModalField.dropdownLabel"
                                    showClear="true"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    [autofocus]="inputContainerIndex === 0 && inputIndex === 0 ? true : false"
                                    [attr.id]="inputModalField.entityPropName"
                                    [tabindex]="baseIndex + inputContainerIndex * 10 + inputIndex + 1">
                                </p-dropdown>
                                <p-listbox 
                                    #entityGenericInput
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.listboxField"
                                    [listStyle]="{'height':'90px', 'width':'max','font-size': '12px', 'margin-left':'8px'}"
                                    [style.width]="inputModalField.width()"
                                    emptyMessage=""
                                    [name]="inputModalField.entityPropName"
                                    [(ngModel)]="inputModalField.inputModalFieldValueFormatter.formatedInputValue"
                                    (ngModelChange)="inputModalField.onInputChange()"
                                    [disabled]="inputModalField.disabledCondition && inputModalField.disabledCondition()"
                                    [options]="inputModalField.dropdownOptionsArray"
                                    [optionValue]="inputModalField.dropdownValue"
                                    [optionLabel]="inputModalField.dropdownLabel"
                                    (keydown.enter)="search()"
                                    [attr.id]="inputContainerIndex === 0 && inputIndex === 0 ? 'firstInput' : null"
                                    [multiple]="true"
                                    [tabindex]="baseIndex + inputContainerIndex * 10 + inputIndex + 1"
                                    >
                                </p-listbox>
                                <p-multiSelect 
                                    #entityGenericInput
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.multiSelectField"
                                    class="form__container-fields-layout--selects"
                                    emptyMessage=""
                                    [name]="inputModalField.entityPropName"
                                    [(ngModel)]="inputModalField.inputModalFieldValueFormatter.formatedInputValue"
                                    [disabled]="inputModalField.disabledCondition && inputModalField.disabledCondition()"
                                    [options]="inputModalField.dropdownOptionsArray"
                                    [optionValue]="inputModalField.dropdownValue"
                                    [optionLabel]="inputModalField.dropdownLabel"
                                    [style.width]="inputModalField.width()"
                                    showClear="true"
                                    [filter]="true"
                                    [showHeader]="true"
                                    [showToggleAll]="false"
                                    [placeholder]="'common.selectOne' | translate"
                                    [maxSelectedLabels]="3"
                                    [tabindex]="baseIndex + inputContainerIndex * 10 + inputIndex + 1">
                                </p-multiSelect>
                            </div>
                            <div *ngIf="inputModalField.searchType" class="col-2 px-0 ml-1">
                                <button 
                                    pButton 
                                    pRipple 
                                    type="button" 
                                    icon="pi pi-search"
                                    class="icon-field-style pd p-button-info p-button-text pe-auto"
                                    (click)="inputModalField.openSearchModal()"
                                    [tabindex]="baseIndex + inputContainerIndex * 10 + inputIndex + 1">
                                </button>
                            </div>
                            <div *ngIf="inputModalField.infoTooltip" class="col-2 px-0 ml-1">
                                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info"
                                     [pTooltip]="inputModalField.infoTooltip | translate">
                            </div>
                            <div class="col-1 px-0"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <hr *ngIf="this.showInputsFilters()" class="hr-modal">
    
    <div class="container-info-modal pt-1">
        <div class="grid main-content">
            <div class="col-6 text-xs" *ngIf="!isMultiSelect">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <strong>{{'common.mouseUsers'| translate}}: </strong> 
                <label class="custom-label">
                    {{'common.searchMouseUsers'| translate}}
                </label>
            </div>
            <div class="col-6 text-xs" *ngIf="!isMultiSelect">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <strong>{{'common.keyboardUsers' | translate}}: </strong>
                <label class="custom-label">
                    {{'common.searchKeyboardUsers'| translate }}
                </label>
            </div>
            <div class="col-12" *ngIf="isMultiSelect">
                <span class="text-xs">{{ 'searchEntityModalComponent.multiSelectSubtitle'| translate:{entityName: getEntityTitle(), entityNamePlural: getEntityTitlePlural()} }}</span>
            </div>
        </div>
    </div>
    <div class="container-table-modal -mt-3 pt-1">

        <app-base-mvt-table-selector
            #mvtTableSelector
            [editable]="true"
            [disabledButton]="true"
            
            [columnsInfo]="this.columnsInfo"
            [entitiesArray]="entitiesArray"
            [tKeyComponentTitle]="''"
            [componentDescription]="''"
            [emptyMessage]="'common.noDataTable' | translate"
            [showAddRowButton]="false"

            [showPaginator]="true"
            [tableKey]="mvtTableKey"
            [useCommonPreference] = "true"
            [defaultRowsPerPage]="rowsPerPage"

            [sortingMode]="'multiple'"
            [selectionMode]="isMultiSelect ? 'multiple' : 'none'"
            [highlightSeleccion]="true"
            [onRowDoubleClick]="onInternalRowDblclick"
            [clearSelectedRowOnPageChange]="false"

            [implementedComponent]="this"
            [baseIndex]="baseIndex + 100">
        </app-base-mvt-table-selector>

        
    </div>

    <div class="container-info-modal main-content" *ngIf="additionalInfoContainers && additionalInfoContainers.length > 0">
        <div class="grid card-modal mt-2 ml-0">
            <div class="col-12 header-info">
                <span>{{'additional_info'| translate}}:</span>
            </div>
            <div *ngFor="let additionalInfoContainer of additionalInfoContainers" [class]="getAdditionalInfoContainerColCssClass()">
                <div *ngFor="let additionalInfoField of additionalInfoContainer.additionalInfoFields" class="content-card">
                    <label class="custom-label-card-right-short" [class]="additionalInfoField.cssClasses">{{ additionalInfoField.columnHeader | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfoField.getCellDisplayValue(getSelectedRow()) }}</label>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showRecordCounter || (isMainAccess && showNewRecordButton) || (!isMainAccess && isMultiSelect)" class="grid pt-3">
        <div *ngIf="showRecordCounter" class="col-12 lg:col-3 xl:col-3 flex justify-content-start">
            <label class="custom-label mt-2 ml-2"> {{'common.records'| translate}} {{entitiesArray.length}}</label>
        </div>

        <div *ngIf="!isMainAccess && isMultiSelect" [ngClass]="showRecordCounter ? 'col-12 lg:col-9 text-right' : 'col-12 lg:col-12 text-right'">
            <button [tabindex]="baseIndex + 110" [disabled]="!this.hasSelectedEntities()" class="btn-row" style="width: auto;"
                (keydown)="focusCloseButton($event)"
                (click)="addSelectedEntities()">
                {{'common.addItems'| translate}}
              </button>
        </div>

        <div *ngIf="isMainAccess && showNewRecordButton" [ngClass]="showRecordCounter ? 'grid pr-0 col-12 lg:col-9 xl:col-9' : 'grid pr-0 col-12 lg:col-12 xl:col-12'">
            <div class="col-12 flex justify-content-end">
                <label class="custom-label mt-2 lg:mr-2 md:mr-2 sm:mr-2">{{ 'searchEntityModalComponent.infoNewRecord'| translate:{entityName: getEntityTitle()} }}
                    <strong> {{'common.notDuplicate'| translate}}</strong>
                </label>
                <button [disabled]="!enabledNewRecordButton" [ngStyle]="{'color':!enabledNewRecordButton ? 'grey' : 'black'}"
                    [tabindex]="baseIndex + 120"
                    class="btn-custom h-2rem" 
                    (keydown.Tab)="focusCloseButton($event)"
                    (click)="newRecord()">{{ (customTextNewRecordButton || 'common.newRecordButtonText') | translate }}
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="(!additionalInfoContainers || additionalInfoContainers.length === 0) && (!isMainAccess || !showNewRecordButton)" class="h-4rem"></div>

</div>
