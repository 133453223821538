import { Table } from "primeng/table";

export class TableUtils {

    public static getCellValue(event: any): any {
        return event.data[event.field];
    }

    public static changeSelectionOnArrowKey(implementedComponent: any, propNameSelectedRowData: string, event: any, rows: any[], hasHeader: boolean = true, setFocusToRow: boolean = false, adjustRowIndexToPage: boolean = true): void {
        const { key, shiftKey, target } = event;
        if (key === "Tab" || key === "ArrowDown" || key === "ArrowUp") {
            let rowIndex = target.rowIndex + (key === "ArrowDown" || (key === "Tab" && !shiftKey) ? 1 : -1);

            this.changeSelectionToRowIndex(implementedComponent, this.getPTableFromEvent(event), propNameSelectedRowData, rowIndex, rows, hasHeader, adjustRowIndexToPage);

            if(setFocusToRow) {
                this.setFocusToTableRow(event, rowIndex);
            }
        }
    }

    public static changeSelectionToRowIndex(implementedComponent: any, tableElement: HTMLElement, propNameSelectedRowData: string, rowIndex: number, rows: any[], hasHeader: boolean = true, adjustRowIndexToPage: boolean = true): void {
        if (hasHeader) {
            rowIndex -= 1;
        }    
        const itemsPerPage = this.getItemsPerPage(tableElement, rows.length);
        const currentPageIndex = this.getCurrentPage(tableElement);
        //overloading max value per page
        if(rowIndex % itemsPerPage ===  0) {
            rowIndex -= 1;
        }
        //overloading min value per page
        rowIndex = Math.max(0, Math.min(rowIndex, rows.length - 1));
        if(adjustRowIndexToPage) {
            //set array index (based on all records)
            rowIndex = (currentPageIndex * itemsPerPage) + rowIndex;
        }
        if(rowIndex < rows.length) {
            implementedComponent[propNameSelectedRowData] = rows[rowIndex];
        }
    }

    protected static getPTableFromEvent(event: any): HTMLElement {
        const { target } = event;
        return target.closest('p-table');
    }

    public static getItemsPerPage(tableElement: HTMLElement, rowLength: number) {
        const paginator = tableElement?.querySelector('p-paginator');
        return paginator ? Number((paginator.querySelector('p-dropdown') as HTMLElement)?.outerText) : rowLength;
    }

    public static getCurrentPage(tableElement: HTMLElement) {
        const paginator = tableElement?.querySelector('p-paginator');
        if(paginator) {
            let pageNumber: string = (paginator.querySelector('button.p-highlight') as HTMLElement)?.outerText;
            if(pageNumber) {
                return Number(pageNumber)-1;
            }
        }
        return 0;
    }

    public static getFirstPaginatorButton(event: Event): HTMLElement {
        const tableElement = this.getPTableFromEvent(event);
        const paginator = tableElement?.querySelector('p-paginator');
        if (!paginator) {
            return null;
        }

        const firstPaginatorButton = Array.from(paginator.querySelectorAll<HTMLButtonElement>('button')).find(button => !button.disabled);
        return firstPaginatorButton;
    }

    public static getRowsByPage(table: Table): Array<any> {
        if(!table){
            return [];
        }
        let rows = table.value;
        let minIndex = table.first;
        let maxIndex = minIndex + table.rows;
        maxIndex = rows.length <= maxIndex ? rows.length : maxIndex; 
        return rows.slice(minIndex, maxIndex);
    }

    public static restrictArrowKeyNavigation(event: KeyboardEvent) {
        const arrowKeys = ['ArrowDown', 'Down', 'ArrowUp', 'Up', 'ArrowLeft', 'Left', 'ArrowRight', 'Right'];
        
        if (arrowKeys.includes(event.key)) {
            event.stopPropagation();
        }
    }

    protected static setFocusToTableRow(event: any, rowIndex: number): void {
        const tableRows = this.getTableRowsFromEvent(event);
        if (tableRows) {
            let targetElement;
            if (tableRows.length > (rowIndex - 1)) {
                targetElement = tableRows[rowIndex - 1];
            } else {
                targetElement = this.getFirstPaginatorButton(event);
            }

            if (targetElement) {
                targetElement.focus();
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    protected static getTableRowsFromEvent(event: any): any[] {
        const { target } = event;
        const table = target.closest('table');
        if (table) {
            return table.querySelectorAll('tbody tr');
        }
        return null;
    }

}
