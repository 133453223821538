import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { SearchEntityModalUtils } from 'src/app/core/utils/search-entity-modal-utils';
import { PlantSectionService } from 'src/app/plant-section/plant-section.service';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { AdditionalInfoContainer, InputModalField, InputModalFieldContainer, InputModalFieldEditableType, SearchEntityModalComponent, } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.component';
import { SearchEntityModalService } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.service';
import { Industry, PlantStatus } from '../../../core/models/common';
import { AddressService } from '../../address-form/address.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';
import { PecZoneModalComponent } from '../pec-zone-modal/pec-zone-modal.component';
import { SicCodeLookupComponent } from '../sic-code-lookup/sic-code-lookup.component';

@Component({
    selector: 'app-search-plant',
    templateUrl: '../../../shared/components/base/search-entity-modal/search-entity-modal.component.html'
})
export class SearchPlantComponent extends SearchEntityModalComponent {
  
    
    private restrictedOnOffshore: boolean = false;
    private isOffshore: boolean;
    constructor(
        private plantService: PlantSectionService,
        private addressService: AddressService,
        private offshoreAddressService: OffshoreAddressService,
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        protected sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected datePipe: DatePipe,
        protected searchEntityModalService: SearchEntityModalService,
        protected changeDetectorRef: ChangeDetectorRef) {
    super(messageHandler, activeModal, config, preferencesService, translate, loadWheelService, entityCommonService, entityUtilsService, searchEntityModalService, dialogService, changeDetectorRef);
    }

    
    override initConfigAtributes() {
        this.entityTitle = 'common.plant';
        this.entityTitlePlural = 'common.plants';

        if (this.config.data?.restrictedOnOffshore) {
            this.restrictedOnOffshore = true;
            this.isOffshore = this.config.data?.offshore ? true : false;
            this.selectedRadioButton = this.isOffshore ? 'offshore' : 'onshore';
        }
    }

    protected override getRadioButtons(): InputModalField[] {
        if (this.restrictedOnOffshore) {
            return null;
        }        
        return [    
            InputModalField.CreateInstance({ entityPropName: 'onshore', displayName: 'plant.searchPlant.plantOnshore' }),
            InputModalField.CreateInstance({ entityPropName: 'offshore', displayName: 'plant.searchPlant.plantOffshore' })
        ];
    }

    override getInputModalFieldContainer(): InputModalFieldContainer[] {
        let inputsModalFieldContainer: InputModalFieldContainer[] = [];
        let inputsModalField: InputModalField[] = [];
    
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantOwner', displayName: 'plant.searchPlant.plantOwName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantName', displayName: 'plant.searchPlant.plantName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantStatus', displayName: 'plant.searchPlant.plantStatus', editableType: InputModalFieldEditableType.multiSelectField, dropdownValue: 'StatusID', dropdownLabel: 'Description' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'industryCode', displayName: 'project.searchOfflineEvent.industryCode', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'industryCode', dropdownLabel: 'industryDescription' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'sicCode', displayName: 'plant.searchPlant.plantSicCode', editableType: InputModalFieldEditableType.nonEditableField, searchType: SicCodeLookupComponent, searchTitle:'sicCode.searchSicCodes', searchDataFunc: this.getSelectedSicCodesArray, searchEntityPropName: 'sicCode', placeholder: 'Select SIC Code(s)' }));
            
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
            const isOffshore = this.selectedRadioButton === 'offshore';
            
            if (!isOffshore) {
                inputsModalField = [];
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantCountry', displayName: 'plant.searchPlant.plantCountry', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'countryId', dropdownLabel: 'countryNameConcat', onChange: () => this.onChangePhysCountry()}));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantState', displayName: 'plant.searchPlant.plantState', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'sId', dropdownLabel: 'stateConcat', isDropdownValueNumber: () => { return true }, disabledCondition: this.isDisabledState,dropdownOptionsArray: [] }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantCity', displayName: 'plant.searchPlant.plantCity', editableType: InputModalFieldEditableType.textField  }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantCounty', displayName: 'plant.searchPlant.plantCounty', editableType: InputModalFieldEditableType.textField  }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'pecZone', displayName: 'plant.searchPlant.pecZone', editableType: InputModalFieldEditableType.textField, searchType: PecZoneModalComponent, searchTitle: 'PEC Zone Search', searchEntityPropName: 'pecZoneId' }));
                
            }else{
                inputsModalField = [];
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantCountry', displayName: 'plant.searchPlant.plantCountry', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'countryId', dropdownLabel: 'countryNameConcat', onChange: () => this.onChangeOffshoreCountry()}));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantWaterBody', displayName: 'plant.searchPlant.plantTableWaterBody', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'waterBodyId', dropdownLabel: 'waterBodyName', isDropdownValueNumber: () => { return true },  disabledCondition: this.isDisabledWaterBody, dropdownOptionsArray: [] }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantFieldName', displayName: 'plant.searchPlant.plantTableFieldName', editableType: InputModalFieldEditableType.textField }));
                inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantAreaName', displayName: 'plant.searchPlant.plantTableArea', editableType: InputModalFieldEditableType.textField  }));
            }

            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantRecordStatus', displayName: 'plant.searchPlant.plantRecStatus', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'RecordStatusID', dropdownLabel: 'RecordStatusDescription' }));
    
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
    
       
        return inputsModalFieldContainer;
    }

    getSelectedSicCodesArray = (): string[] => {
        const sicCodeField = this.searchInputModalField('sicCode');
        return sicCodeField?.inputValue?.split(', ') ?? [];
    };

    isDisabledState = (): boolean => {
        const countryField = this.searchInputModalField('plantCountry');
        return !countryField?.inputValue;
    };

    isDisabledWaterBody = (): boolean => {
        const countryField = this.searchInputModalField('plantCountry');
        return !countryField?.inputValue;
    };

    private loadCountryOffshore() {
        SearchEntityModalUtils.loadOffshoreCountries(this.searchInputModalField('plantCountry'), this.offshoreAddressService);
    }

    private loadCountryOnshore() {
        SearchEntityModalUtils.loadOnshoreCountries(this.searchInputModalField('plantCountry'), this.addressService);
    }

    protected override getColumnsInfo(): ColumnEntityInfo[] {
        const isOffshore = this.selectedRadioButton === 'offshore';
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'plantId', columnHeader: this.translate.instant('plant.searchPlant.plantTablePlantId'), widthPercentage: 9 });
        columnsInfo.push({ entityPropName: 'plantName', columnHeader: this.translate.instant('plant.searchPlant.plantTablePlantName'), widthPercentage: 18 });
        columnsInfo.push({ entityPropName: 'ownerName', columnHeader: this.translate.instant('plant.searchPlant.plantTableOwner'), widthPercentage: 15 });
        if(!isOffshore){
            columnsInfo.push({ entityPropName: 'physAddress', columnHeader: this.translate.instant('plant.searchPlant.plantTableAddress'), widthPercentage: 15 });
            columnsInfo.push({ entityPropName: 'plantPhysCity', columnHeader: this.translate.instant('plant.searchPlant.plantTableCity'), widthPercentage: 10 });
            columnsInfo.push({ entityPropName: 'plantPhysStateName', columnHeader: this.translate.instant('plant.searchPlant.plantTableState'), widthPercentage: 10 });
            columnsInfo.push({ entityPropName: 'plantPhysCountryName', columnHeader: this.translate.instant('plant.searchPlant.plantTableCountry'), widthPercentage: 8 });
        }else{
            columnsInfo.push({ entityPropName: 'quadrantBlock', columnHeader: this.translate.instant('plant.searchPlant.plantTableQuadrantBlock'), widthPercentage: 15 });
            columnsInfo.push({ entityPropName: 'plantWaterBody', columnHeader: this.translate.instant('plant.searchPlant.plantTableWaterBody'), widthPercentage: 10 });
            columnsInfo.push({ entityPropName: 'plantFieldName', columnHeader: this.translate.instant('plant.searchPlant.plantTableFieldName'), widthPercentage: 10 });
            columnsInfo.push({ entityPropName: 'plantArea', columnHeader: this.translate.instant('plant.searchPlant.plantTableArea'), widthPercentage: 8 });
        }
        columnsInfo.push({ entityPropName: 'sicCode', columnHeader: this.translate.instant('plant.searchPlant.plantTableSicCode'), widthPercentage: 8 });
        columnsInfo.push({ entityPropName: 'plantStatusDesc', columnHeader:  this.translate.instant('plant.searchPlant.plantTableStatus'), widthPercentage: 9 });

        return columnsInfo;
    }

    protected override getAdditionalInfoContainers(): AdditionalInfoContainer[] {
        const isOffshore = this.selectedRadioButton === 'offshore';
        let aditionalInfoContainer: AdditionalInfoContainer[] = [];
        let columnsInfo: ColumnEntityInfo[] = [];

        if(!isOffshore){
            columnsInfo.push({ entityPropName: 'physAddress', columnHeader: this.translate.instant('plant.additionalInfo.physAddress'), widthPercentage: 50 });
        }else{
            columnsInfo.push({ entityPropName: 'quadrantBlock', columnHeader: this.translate.instant('plant.additionalInfo.quadrantBlock'), widthPercentage: 50 });
        }
        columnsInfo.push({ entityPropName: 'mailAddress', columnHeader: this.translate.instant('plant.additionalInfo.mailAddress'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'phone', columnHeader: this.translate.instant('plant.additionalInfo.telephone'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'fax', columnHeader: this.translate.instant('plant.additionalInfo.faxNumber'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 50 });
        
        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
        
        columnsInfo = [];
        columnsInfo.push({ entityPropName: 'parentCompanyName', columnHeader:  this.translate.instant('plant.additionalInfo.parentCompany'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'operatorName', columnHeader: this.translate.instant('plant.additionalInfo.operator'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'recordStatusDesc', columnHeader: this.translate.instant('plant.additionalInfo.recordStatus'), widthPercentage: 50 });
        columnsInfo.push({ entityPropName: 'noEmployees', columnHeader:this.translate.instant('plant.additionalInfo.noEmployees'), widthPercentage: 50 });

        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
        return aditionalInfoContainer;
    }

    protected override searchEntitiesDelegate(params: HttpParams, wheel: SpinnerProcess): Observable<any> {
        return this.plantService.searchPlant(params);
    }

    protected override getHttpParamsFromInputsModalField(): HttpParams {
        let params: HttpParams = super.getHttpParamsFromInputsModalField();
        params = params.set('plantOffshore', this.selectedRadioButton == 'offshore'? '1':'0');
        return params;
    }

    onChangePhysCountry() {
        const countryField = this.searchInputModalField('plantCountry');
        const stateField = this.searchInputModalField('plantState');          
        SearchEntityModalUtils.loadStates(countryField, stateField, this.addressService);
    }

    onChangeOffshoreCountry() {
        const countryField = this.searchInputModalField('plantCountry');
        const waterBodyField = this.searchInputModalField('plantWaterBody');        
        SearchEntityModalUtils.loadWaterBodies(countryField, waterBodyField, this.offshoreAddressService);
    }

    protected override loadList() {
        this.loadPlantStatus();
        this.loadIndustryCode();
        this.loadRecordStatus();
        const isOffshore = this.selectedRadioButton === 'offshore';
        isOffshore ? this.loadCountryOffshore() : this.loadCountryOnshore();
    }

    private loadPlantStatus() {
        this.sharedService.getPlantStatus()
            .subscribe((record: PlantStatus[]) => {
                this.searchInputModalField('plantStatus').dropdownOptionsArray = record;
            });
    }

    private loadRecordStatus() {
        SearchEntityModalUtils.loadRecordStatus(this.searchInputModalField('plantRecordStatus'), this.sharedService);
    }

    private loadIndustryCode() {
        this.sharedService.getIndustryCode()
            .subscribe((industry: Industry[]) => {
                this.searchInputModalField('industryCode').dropdownOptionsArray = industry;
            });
    }
}

