import { Adapter } from "../adapter";
import { StringUtils } from "../utils/string-utils";
import { Constants } from "./constants";
import { Injectable } from "@angular/core";


export class UnitSearch {
    constructor(
        public unitId: string,
        public unitName: string,
        public ownerName: string,
        public operatorName: string,
        public unitStatus: string,
        public shutdownDate: string,
        public qcDate: string,
        public sicCode: string,
        public sicCodeDesc: string,
        public primaryFuel: string,
        public primaryFuelDesc: string,
        public powerUsage: string,
        public powerUsageDesc: string,
        public startupDate: string,
        public recordStatus: string,
        public unitType: string,
        public plantName: string,
        public areaName: string,
        public sicCodeLargeDesc: string,
        
        public plantId: string,
        public areaId: string,
        public plantOffshore: string,
        public physAddressV1: string,
        public physAddressV2: string,
        public plantOffshoreBlockV1: string,
        public plantFieldName: string,
        public plantWaterBody: string,
        public plantCity: string,
        public plantState: string,
        public plantCountry: string,
        public plantCountryName: string,
        public plantPostalCode: string,
        public ownerId: string,
        public startUpDateResolution: string,
        public shutDownDateResolution: string,
        public unitStatusDesc: string,
        public unitTypeId: string
    ) { }

    get sicCodeCompositeName():string {
        return StringUtils.getCompositeIDName(this.sicCode, this.sicCodeDesc, ' - ');
    }
}


@Injectable({
    providedIn: 'root',
})
export class UnitSearchAdapter implements Adapter<UnitSearch> {
    adapt(item: any): UnitSearch {
        return new UnitSearch(
            item.UNIT_ID,
            item.UNIT_NAME,
            item.OWNER_NAME ?? item.PLANT_OWNER_NAME,
            item.OPERATOR_NAME,
            item.UNIT_STATUS,
            item.SHUTDOWN_DATE,
            item.QC_DATE,
            item.SIC_CODE,
            item.SIC_CODE_DESC,
            item.PRIMARY_FUEL,
            item.DESCRIPTION,
            item.POWER_USAGE,
            item.POWER_USAGE_DESC,
            item.STARTUP_DATE,
            item.RECORD_STATUS_DESC,
            item.TYPE_NAME,
            item.PLANT_NAME,
            item.AREA_NAME,
            item.SIC_CODE ? `${item.SIC_CODE} - ${item.SIC_CODE_DESC}` : '',
            item.PLANT_ID,
            item.AREA_ID,
            item.OFFSHORE,
            item.PHYS_ADDRESS_V1,
            item.PHYS_ADDRESS_V2,
            item.OFFSHORE_BLOCK_V1,
            item.FIELD_NAME,
            item.WATERBODY_NAME,
            item.PHYS_CITY,
            item.STATE_NAME,
            item.PHYS_COUNTRY,
            item.COUNTRY_NAME,
            item.SIC_CODE,
            item.PLANT_OWNER_ID,
            item.STARTUP_RESOLUTION,
            item.SHUTDOWN_RESOLUTION,
            item.UNIT_STATUS_DESC,
            item.UNIT_TYPE
        );
    }
}



export class TypeResult {
    constructor(
        public typeId: number,
        public typeName: string,

    ) { }
}

export class TypeSearch {
    constructor(
        public typeId: number,
        public typeName: string,

    ) { }
}

export class UnitType {
    public constructor(
        public typeId: number,
        public typeName: string,
        public typeCategory: string,
    ) { }

    static BuildUnitType(fields: Array<any>): Array<UnitType> {
        return fields.map(item => UnitType.CreateInstance(item));
    }


    static CreateInstance(entity: any): UnitType {
        return new UnitType(
            entity.UNIT_TYPES_ID,
            entity.TYPE_NAME,
            entity.DRIVE_CATEGORY_ID
        );
    }
}

export class UnitStatus {
    statusID: string;
    shortDescription: string;
    description: string;

    private constructor(
        statusID: string,
        shortDescription: string,
        description: string
    ) {
        this.statusID = statusID;
        this.description = description;
        this.shortDescription = shortDescription;
    }

    static BuildUnitStatus(entity: Array<any>): Array<UnitStatus> {
        return entity.map(item => UnitStatus.CreateInstance(item));
    }

    private static CreateInstance(record: any): UnitStatus {
        return new UnitStatus(record.UNIT_STATUS_ID, record.STATUS_DESC, `${record.UNIT_STATUS_ID} - ${record.STATUS_DESC}`);
    }
}


export class CrudeSlateType {
    typeId: string;
    groupId: string;
    typeDesc: string;

    private constructor(
        typeId: string,
        groupId: string,
        typeDesc: string
    ) {
        this.typeId = typeId;
        this.groupId = groupId;
        this.typeDesc = typeDesc;
    }

    static BuildCrudeSlateType(entity: Array<any>): Array<CrudeSlateType> {
        return entity.map(item => CrudeSlateType.CreateInstance(item));
    }


    private static CreateInstance(record: any): CrudeSlateType {
        return new CrudeSlateType(
            record.CRUDE_SLATE_TYPE_ID, 
            record.CRUDE_SLATE_GROUP_ID, 
            record.CRUDE_SLATE_TYPE_DESC);
    }
}

export class CrudeSlateGroup {
    groupId: string;
    groupDesc: string;

    private constructor(
        groupId: string,
        groupDesc: string
    ) {
        this.groupId = groupId;
        this.groupDesc = groupDesc;
    }

    static BuildCrudeSlateGroup(entity: Array<any>): Array<CrudeSlateGroup> {
        return entity.map(item => CrudeSlateGroup.CreateInstance(item));
    }


    private static CreateInstance(record: any): CrudeSlateGroup {
        return new CrudeSlateGroup(
            record.CRUDE_SLATE_GROUP_ID, 
            record.CRUDE_SLATE_GROUP_DESC);
    }
}

export class FutureStatus {
    id: string;
    description: string;
    largeDescription: string;

    private constructor(
        id: string,
        description: string,
        largeDescription: string
    ) {
        this.id = id;
        this.description = description;
        this.largeDescription = largeDescription;
    }

    static BuildFutureStatus(entity: Array<any>): Array<FutureStatus> {
        return entity.map(item => FutureStatus.CreateInstance(item));
    }


    private static CreateInstance(record: any): FutureStatus {
        
        return new FutureStatus(
            record.FUTURE_CAPACITY_STATUS_ID, 
            record.STATUS_DESC,
            `${record.FUTURE_CAPACITY_STATUS_ID} - ${record.STATUS_DESC}`
            );
    }
}

export class CrudeSlateHistoryMap {
    key: string;
    entities: Array<CrudeSlateHistory>;

    private constructor(
        key: string,
        entities: Array<CrudeSlateHistory>
    ) {
        this.key = key;
        this.entities = entities;
    }

    static BuildCrudeSlateHistory(entity: Array<any>): Array<CrudeSlateHistoryMap> {
        return entity.map(item => CrudeSlateHistoryMap.CreateInstance(item));
    }

    private static CreateInstance(record: any): CrudeSlateHistoryMap {
        return new CrudeSlateHistoryMap(
            record.operationDate, 
            CrudeSlateHistory.BuildCrudeSlateHistory(record.history));
    }
}

export class CrudeSlateHistory {
    unitId: number;
    typeId: number;
    groupId: number;
    typeDesc: string;
    groupDesc: string;
    capacity: number;
    percentage: number;
    lastDate: string;
    qcDate: string;
    qcUser: string;
    operationDate: string;

    private constructor(
        unitId: number,
        typeId: number,
        groupId: number,
        typeDesc: string,
        groupDesc: string,
        capacity: number,
        percentage: number,
        lastDate: string,
        qcDate: string,
        qcUser: string
    ) {
        this.unitId = unitId;
        this.typeId = typeId;
        this.groupId = groupId;
        this.typeDesc = typeDesc;
        this.groupDesc = groupDesc;
        this.capacity = capacity;
        this.percentage = percentage;
        this.lastDate = lastDate;
        this.qcDate = qcDate;
        this.qcUser = qcUser;
    }

    static BuildCrudeSlateHistory(entity: Array<any>): Array<CrudeSlateHistory> {
        return entity.map(item => CrudeSlateHistory.CreateInstance(item));
    }

    static SetCrudeSlateHistory(entity: any): CrudeSlateHistory {
        return CrudeSlateHistory.CreateInstance(entity);
    }

    private static CreateInstance(record: any): CrudeSlateHistory {
        return new CrudeSlateHistory(
            record.UNIT_ID, 
            record.CRUDE_SLATE_TYPE_ID, 
            record.CRUDE_SLATE_GROUP_ID, 
            record.CRUDE_SLATE_TYPE_DESC,
            record.CRUDE_SLATE_GROUP_DESC,
            record.CAPACITY,
            record.PERCENTAGE,
            record.LAST_DATE,
            record.CRUDE_QC_DATE,
            record.CRUDE_QC_USER );
    }
}


export class PowerUsage {
    id: string;
    description: string;

    private constructor(
        id: string,
        description: string
    ) {
        this.id = id;
        this.description = description;
    }

    static BuildGenericList(): Array<PowerUsage> {
        return [
            {id:"B", description:"B - Base Load"},
            {id:"I", description:"I - Intermediate Load"},
            {id:"P", description:"P - Peak Load"}];
    }

}

export class PSIRating {
    id: string;
    description: string;

    private constructor(
        id: string,
        description: string
    ) {
        this.id = id;
        this.description = description;
    }

    static BuildGenericList(): Array<PowerUsage> {
        return [
            {id:"LOW", description:"L-Low"},
            {id:"MEDIUM", description:"M-Medium"},
            {id:"HIGH", description:"H-High"}];
    }

}

export class UnitFuelType {
    unitId: string;
    id: number;
    description: string;
    mvOrder: number;

    private constructor(
        unitId: string,
        id: number,
        description: string,
        mvOrder: number
    ) {
        this.unitId = unitId;
        this.id = id;
        this.description = description;
        this.mvOrder = mvOrder;
    }

    static BuildFuelType(entity: Array<any>): Array<UnitFuelType> {
        return entity.map(item => UnitFuelType.CreateInstance(item));
    }

    private static CreateInstance(record: any): UnitFuelType {
        return new UnitFuelType(
            record.UNIT_ID, 
            record.FUELS, 
            record.DESCRIPTION, 
            record.MV_ORDER);
    }

}


