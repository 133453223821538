import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
// ThirdParty
import { DialogService } from 'primeng/dynamicdialog';
// Services
import { CompanySectionService } from 'src/app/company-section/company-section.service';
// Models
import { CompanySearch } from 'src/app/core/models';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
// Constant
import { CompanyClassificationValue, CompanyStatusValue, Constants, RecordStatusValue } from 'src/app/core/models/constants';
// Components
import { TranslateService } from '@ngx-translate/core';
import { EditableColumn } from 'primeng/table';
import { Subject, Subscription } from 'rxjs';
import { IOwnership } from 'src/app/core/interfaces/iownership';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { SharedService } from 'src/app/core/services/shared.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { MessageHandlerComponent, MessageType } from '../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../messages/message-handler/message-handler.service';
import { SearchCompanyComponent } from '../modals/search-company/search-company.component';
import { FocusableEvent, FocusableEventType } from 'src/app/core/directives/focusable-table.directive';
import { TableUtils } from 'src/app/core/utils/table-utils';

enum LocalEventType {
    ON_ENTER,
    ON_FOCUS_OUT,
}

@Component({
    selector: 'app-ownership',
    templateUrl: './ownership.component.html',
    styleUrls: ['./ownership.component.scss']
})
export class OwnershipComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() ownerships: Array<IOwnership>;
    @Input() entityId: number;
    @Input() entityName: string;
    @Input() entityForm: FormGroup;
    @Input() editable: boolean = false;
    @Input() createInstance: (obj: any) => IOwnership;
    @Input() disabledButton: boolean = false;
    @Input() titleKey: string;
    @Input() required: boolean = false;
    @Input() baseIndex: number;
    @ViewChildren(EditableColumn) private editableColumns: QueryList<EditableColumn>;
    @Output() ownershipChanges: EventEmitter<Array<IOwnership>> = new EventEmitter();
    @Output() companyIsSelected: EventEmitter<CompanySearch> = new EventEmitter();

    cols: any[] = [];
    formGroup: FormGroup;
    ownershipsToShow: Array<IOwnership> = [];
    tableEditEnabled: boolean = true;
    disableFormSub: Subscription;
    isEditing: boolean = false;
    isCompletedPercent: boolean = false;
    isIncompletedRow: boolean = false;

    ownershipFocusManager = new Subject<FocusableEvent>();

    constructor(
        private companySectionService: CompanySectionService,
        public dialogService: DialogService,
        private messageHandler: MessageHandlerService,
        private translate: TranslateService,
        private headerService: HeaderService,
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {

        if(this.entityName === 'Plant'){
            this.cols = [
                { draggable: true, width: '5%' },
                { field: 'ownerId', header: this.translate.instant('ownership.ownershipId'), editable: this.editable, width: '20%' },
                { field: 'companyName', header: this.translate.instant('common.companyName'), editable: false, width: '30%' },
                { field: 'ownerPct', header: this.translate.instant('ownership.ownershipPct'), editable: this.editable, width: '20%', headerAlign: 'right', bodyAlign: 'right' },
                { field: 'qcDate', header: this.translate.instant('common.qcDate'), editable: false, width: '20%' },
                { field: 'delete', delete: true, width: '5%' }
            ];
        }else{
            this.cols = [
                { draggable: true, width: '5%' },
                { field: 'ownerId', header: this.translate.instant('ownership.ownershipId'), editable: this.editable, width: '25%' },
                { field: 'companyName', header: this.translate.instant('common.companyName'), editable: false, width: '40%' },
                { field: 'ownerPct', header: this.translate.instant('ownership.ownershipPct'), editable: this.editable, width: '25%', headerAlign: 'right', bodyAlign: 'right' },
                { field: 'delete', delete: true, width: '5%' }
            ];

        }
        this.disableFormSub = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            this.disabledButton = this.disabledButton || disable;
            this.tableEditEnabled = !disable;
        });
    }

    ngAfterViewInit(): void {
        this.ownershipFocusManager.next(new FocusableEvent(FocusableEventType.ADD_EDITABLE_COLUMNS, this.editableColumns));
    }

    companyOwnerFiltered(): Array<IOwnership> {
        if (this.ownerships) {
            this.ownershipsToShow = MVTOperations.sortByMvOrder(this.ownerships, 'asc');
            this.ownershipsToShow = MVTOperations.filterByDeleted(this.ownerships);
        }
        return this.ownershipsToShow;

    }

    deleteCompanyOwner(row: IOwnership) {
        this.headerService.changeForm = true;
        MVTOperations.removeItem(this.ownerships, 'ownerId', row.ownerId !== null ? String(row.ownerId) : null);
        MVTOperations.arrangeMvOrder(this.ownerships);
        this.ownerships = MVTOperations.sortByMvOrder(this.ownerships);
        this.updateRowStatus();
    }

    addRow() {
        const emptyTemporalObject: boolean = this.ownerships?.filter(p => p.getOperation() === OperationType.TEMPORAL)?.length > 0;
        if (!emptyTemporalObject) {
            this.headerService.changeForm = true;
            let sPercent = 0;
            if (this.ownerships && this.ownerships.length > 0) {
                let values = MVTOperations.filterByDeleted(this.ownerships)?.map(p => Number(p.ownerPct));
                sPercent = values.length > 0 ? values.reduce((a, b) => a + b) : Number(0);
            }
            if (sPercent < 100) {
                const itemAux: IOwnership = this.createInstance({
                    parentId: this.entityId,
                    mvOrder: MVTOperations.getLastMvOrder(this.ownerships) + 1,
                });
                itemAux.setOperation(OperationType.TEMPORAL);
                this.ownerships.push(itemAux);
                this.ownershipChanges.emit(this.ownerships);
                this.ownershipFocusManager.next(new FocusableEvent(FocusableEventType.FOCUS_TO_LAST_ROW));
            }
        }
    }

    openCompanySearchModal(row: IOwnership, rowIndex?: number) {
        const section = 'Ownership';
        const modalRef = this.dialogService.open(SearchCompanyComponent, {
            header: 'Company Search',
            width: '90rem',
            data: { fromCompanyScreen: (EntityName.COMPANY === this.entityName), "parentCompanyForm": this.entityForm,
                    section: section },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((companySearch: CompanySearch) => {
            if (companySearch !== undefined) {
                this.validateAndSetCompanyData(companySearch, row, rowIndex);
            }
        });
    }

    onOwnerIdEdited(row: IOwnership, rowIndex?: number, lEvent?: LocalEventType): void {
        if (String(row.ownerId) !== String(row.previousOwnerId)) {
            if (row.ownerId) {
                if(lEvent === LocalEventType.ON_FOCUS_OUT) {
                    this.ownershipFocusManager.next(new FocusableEvent(FocusableEventType.IGNORE_LAST_TAB_EVENT));
                }
                this.companySectionService.getCompanyById(row.ownerId).subscribe({
                    next: (companySearch: CompanySearch) => {
                        this.validateAndSetCompanyData(companySearch, row, rowIndex);
                    },
                    error: (error: any) => {
                        this.messageHandler.show(this.translate.instant('ownership.ownershipDoesNoExist'), MessageType.INFO, () => this.setFocusToId(rowIndex));
                        this.clearRow(row);
                    }
                });
            } else {
                this.clearRow(row);
            }
        }
    }

    onOwnerPctEdited(row: IOwnership, rowIndex?: number): void {
        if (row.ownerId && String(row.ownerId).trim() !== '' && row.ownerPct
            && row.ownerPct && String(row.ownerPct).trim() !== '') {
            row.setOperation(row.getOperation() === OperationType.IDLE ? OperationType.UPDATE : OperationType.INSERT);
            if (row.changedFields['ownerPct'] && row.ownerPct !== row.changedFields['ownerPct']
                || !row.changedFields['ownerPct'] && row.initialData['ownerPct'] != row.ownerPct) {
                this.headerService.changeForm = true;
            }
            row.changedFields = { 'ownerPct': row.ownerPct, ...row.changedFields };
        } else {
            this.headerService.changeForm = true;
        }
        if (this.handleOwnerPercentage(row)) {
            this.ownershipFocusManager.next(new FocusableEvent(FocusableEventType.IGNORE_LAST_TAB_EVENT));
            const onMessageConfirm = () => this.setFocusToPct(rowIndex);
            this.messageHandler.show(this.translate.instant('ownership.ownership100Validation'), MessageType.ERROR, onMessageConfirm);
            row.ownerPct = null;
        }
    }

    onEnter(col: any, row: IOwnership, rowIndex?: number): void {
        this.updateRowStatus();
        if (col.field === 'ownerId') {
            if (row.ownerId === null || String(row.ownerId).trim() === '') {
                this.clearRow(row);
                this.openCompanySearchModal(row, rowIndex);
                return;
            }
            this.onOwnerIdEdited(row, rowIndex, LocalEventType.ON_ENTER);
        } else if (col.field === 'ownerPct') {
            this.onOwnerPctEdited(row, rowIndex);
        }
    }

    onFocusOut(col: any, row: IOwnership, rowIndex?: number): void {
        this.updateRowStatus();
        if (col.field === 'ownerId') {
            this.onOwnerIdEdited(row, rowIndex, LocalEventType.ON_FOCUS_OUT);
        } else if (col.field === 'ownerPct') {
            this.onOwnerPctEdited(row, rowIndex);
        }
    }

    onRowReorder(event: any) {
        this.headerService.changeForm = true;
        MVTOperations.changePosition(this.ownerships, event.dragIndex, event.dropIndex);
    }

    onEditComplete(event: any): void {
        this.isEditing = false;
    }

    onEditInit(): void {
        this.isEditing = true;
    }

    clearRow(row: IOwnership): void {
        this.headerService.changeForm = true;
        row.ownerId = null;
        row.companyName = null;
        row.previousOwnerId = null;
        row.qcDate = null;
        this.updateRowStatus();
        this.ownershipFocusManager.next(new FocusableEvent(FocusableEventType.RESET_FOCUS_LISTENERS));
    }

    hasIncompleteRow(): boolean {
        const notDeletedOwnerships = MVTOperations.filterByDeleted(this.ownerships) ?? [];
        return notDeletedOwnerships.filter(el => el.ownerPct === null || String(el.ownerPct).trim() === ''
            || el.ownerId === null || String(el.ownerId).trim() === '').length > 0;
    }

    handleOwnerPercentage(row: IOwnership): boolean {
        const vPercent = row.ownerPct;
        if (vPercent === 0) {
            row.ownerPct = null;
        }
        const sPercent = MVTOperations.filterByDeleted(this.ownerships)?.map(p => Number(p.ownerPct)).reduce((a, b) => a + b);
        if (sPercent === 100) {
            MVTOperations.removeTemporalRow(this.ownerships);
            return false;
        }
        if (sPercent > 100) {
            return true;
        }
        return false;
    }

    private validateAndSetCompanyData(companySearch: CompanySearch, row: IOwnership, rowIndex?: number): void {
        const onMessageConfirm = () => this.setFocusToId(rowIndex);
        if (companySearch.companyId !== this.entityId || (companySearch.companyId === this.entityId && this.entityName !== EntityName.COMPANY)) {
            if (this.ownershipsToShow.some(el => el.ownerId === companySearch.companyId && el.mvOrder !== row.mvOrder)) {
                this.messageHandler.show(companySearch.companyId + " " + this.translate.instant("ownership.ownershipExists"), MessageType.WARNING, onMessageConfirm);
                this.clearRow(row);
                return;
            }
            const recordStatus = companySearch.recordStatus;
            const status = companySearch.companyStatus;
            if (status == CompanyStatusValue.SuspendedUnresolved
                || status === CompanyStatusValue.Closed
                || recordStatus === RecordStatusValue.Incomplete
                || recordStatus === RecordStatusValue.Trash) {
                this.messageHandler.show(Constants.WARNING_COMPANY_ASSOCIATED, MessageType.INFO, onMessageConfirm);
                this.clearRow(row);
                return;
            }
            if (this.entityName !== EntityName.COMPANY || this.entityForm.get('classification').value !== CompanyClassificationValue.Parent
                || companySearch.classification === CompanyClassificationValue.Holding) {
                const mvtIdPkName = this.entityName === EntityName.COMPANY ||
                                        this.entityName === EntityName.UNIT ? 'ownerId' : 'companyId';
                row.setOperation(row.getOperation() === OperationType.IDLE ? OperationType.UPDATE : OperationType.INSERT);
                row.companyName = companySearch.companyName;
                row.ownerId = companySearch.companyId;
                row.previousOwnerId = row.ownerId;
                row.classification = companySearch.classification;
                row.forceBeanChange(mvtIdPkName);
                row.ownerPct = null;
                row.qcDate = companySearch.qcDate;
                this.setFocusToId(rowIndex);
                this.headerService.changeForm = true;
                this.updateRowStatus();
                this.companyIsSelected.emit(companySearch);
            } else {
                this.messageHandler.show(this.translate.instant("ownership.ownershipParentHoldingValidation"), MessageType.INFO, onMessageConfirm);
                this.clearRow(row);
            }
        } else {
            this.messageHandler.show(Constants.MESSAGE_COMPANY_CANNOT_OWN_ITSELF, MessageType.INFO, onMessageConfirm);
            this.clearRow(row);
        }
    }

    private setFocusToId(rowIndex: number): void {
        this.ownershipFocusManager.next(new FocusableEvent(FocusableEventType.FOCUS_TO_BY_INDEX, rowIndex));
    }

    private setFocusToPct(rowIndex: number): void {
        this.ownershipFocusManager.next(new FocusableEvent(FocusableEventType.FOCUS_TO_BY_INDEX_AND_FIELD, {index: rowIndex, field: 'ownerPct'}));
    }

    isDisabledButton(): boolean {
        return this.disabledButton || this.isIncompletedRow || !this.tableEditEnabled ||
            this.isEditing || (this.entityName === EntityName.COMPANY &&
                this.entityForm.get('classification').value === CompanyClassificationValue.Holding)
            || this.isCompletedPercent;
    }

    hasHundredPercent(): boolean {
        let result: boolean = false;
        const emptyTemporalObject: boolean = this.ownerships?.filter(p => p.getOperation() === OperationType.TEMPORAL)?.length > 0;
        if (!emptyTemporalObject && this.ownerships && this.ownerships.length > 0) {
            let values = MVTOperations.filterByDeleted(this.ownerships)?.map(p => Number(p.ownerPct));
            if (values.length > 0 && values.reduce((a, b) => a + b) === 100) {
                result = true;
            }
        }
        return result;
    }

    ngOnDestroy(): void {
        this.disableFormSub.unsubscribe();
    }

    updateRowStatus() {
        this.isCompletedPercent = this.hasHundredPercent();
        this.isIncompletedRow = this.hasIncompleteRow();
    }

    ngOnChanges() {
        this.updateRowStatus();
    }

    restrictArrowKeyNavigation(event){
        TableUtils.restrictArrowKeyNavigation(event);
    }

}
