import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompanySectionService } from 'src/app/company-section/company-section.service';
import { SicCode } from 'src/app/core/models/common';
import { PlantSectionService } from 'src/app/plant-section/plant-section.service';
import { MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';

@Component({
    selector: 'app-sic-code-lookup',
    templateUrl: './sic-code-lookup.component.html',
    styleUrls: ['./sic-code-lookup.component.css'],
})

export class SicCodeLookupComponent implements OnInit {
    sicCode: string = '';
    sicDescription: string = '';
    industryCode: string = '';

    availableSicCodes: SicCode[] = [];
    selectedInAvailableSicCodes = [];
    selectedSicCodesIds: string[] = [];
    selectedSicCodes: SicCode[] = [];
    selectedInSelectedSicCodes = [];

    @ViewChildren('tabIndexSetted') tabIndexSetted: QueryList<any>;
    @ViewChild('listbox') listbox: ElementRef;
    @ViewChild('searchBtn') searchBtn: ElementRef;
    @ViewChild('addBtn') addBtn: ElementRef;
    @ViewChild('removeBtn') removeBtn: ElementRef;
    @ViewChild('selectedSCodes') selectedSCodes: ElementRef;


    constructor(public companySectionService: CompanySectionService,
        public plantService: PlantSectionService,
        public activeModal: DynamicDialogRef,
        private messageHandler: MessageHandlerService,
        public config: DynamicDialogConfig,
        private translate: TranslateService,
        private primengConfig: PrimeNGConfig,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.selectedSicCodesIds = this.config.data;
        this.primengConfig.ripple = false;
        this.initSearch();
    }

    initSearch() {
        this.plantService.getSicCodes().subscribe((data) => {
            if (data !== null) {
                this.availableSicCodes = data;
                if(this.selectedSicCodesIds?.length > 0) {
                    this.selectedSicCodes = this.availableSicCodes?.filter(entity =>
                        this.selectedSicCodesIds.some(sicCodeId => entity.sicCode === sicCodeId));
                }
                this.filterSelectedSICCodes();
            }
        });
    }

    search() {
        this.plantService.searchSicCodes(this.sicCode, this.sicDescription, this.industryCode).subscribe((data) => {
            if (data !== null) {
                this.availableSicCodes = data;
                this.filterSelectedSICCodes();
                if (this.availableSicCodes.length === 0) {
                    this.messageHandler.show(this.translate.instant('common.searchNotFound'), MessageType.INFO);
                }
            }
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    addRowSingleClick() {
        this.activeModal.close(this.selectedSicCodes);
    }

    add() {
        this.selectedSicCodes = this.selectedSicCodes.concat(this.selectedInAvailableSicCodes.filter(item => !this.listContainsSICCode(this.selectedSicCodes, item.sicCode)));
        this.filterSelectedSICCodes();
        this.selectedInAvailableSicCodes = [];
    }

    remove() {
        this.availableSicCodes = this.availableSicCodes.concat(this.selectedInSelectedSicCodes);
        this.selectedSicCodes = this.selectedSicCodes.filter(item => !this.selectedInSelectedSicCodes.includes(item));
        this.selectedInSelectedSicCodes = [];
    }

    private listContainsSICCode(list: SicCode[], sicCode: string): boolean {
        return list.some(item => item.sicCode === sicCode);
    }

    private filterSelectedSICCodes(): void {
        this.availableSicCodes = this.availableSicCodes.filter(selectable =>
            !this.selectedSicCodes.some(selected => selected.sicCode === selectable.sicCode));
    }

}
