import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import { MessageType } from '../components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../components/messages/message-handler/message-handler.service';
import { SaveType } from '../core/models/enumerations/save-type';
import { CompanyOwners, Plants } from '../core/models/index';
import { UserDetail } from '../core/models/user-detail';
// Interface
import { TranslateService } from '@ngx-translate/core';
import { LoadWheelService } from '../components/load-wheel/load-wheel.service';
import { Areas, Units } from '../core/models/common';
import { EntityName } from '../core/models/enumerations/entity-name';
import { Manufacturer } from '../core/models/manufacturer';
import { EquipmentsDisplay } from '../core/models/other-equipment/display/equipments-display';
import { EquipmentSearch, EquipmentSearchAdapter } from '../core/models/search/equipment-search';
import { SpinnerProcess } from '../core/models/spinner-process';
import { RecordLockingFlow } from '../core/record-locking/record-locking-flow';
import { EntityCommonService } from '../core/services/entity-common.service';
import { LockSameUserMessageService, LockSameUserResult } from '../core/services/lock-same-user-message.service';
import { TelemetryService } from '../core/services/telemetry.service';
import { EntityUtilsService } from '../core/utils/entity-utils.service';
import { StringUtils } from '../core/utils/string-utils';

@Injectable({
    providedIn: 'root'
})
export class EquipmentSectionService {

    constructor(
        //private plantAdapter: PlantSearchAdapter,
        private recordLockingFlow: RecordLockingFlow,
        private http: HttpClient,
        private messageHandlerService: MessageHandlerService,
        private loadWheelService: LoadWheelService,
        private entityCommonService: EntityCommonService,
        private translate: TranslateService,
        private equipmentSearchAdapter: EquipmentSearchAdapter,
        //private authService: AuthService,
        private entityUtilsService: EntityUtilsService,
        private lockSameUserMessageService: LockSameUserMessageService,
        private telemetry: TelemetryService,
    ) { }

    getEquipmentDetailsById(equipmentId: string, isReload: boolean = false, quickAccess: boolean = false): Promise<EquipmentsDisplay> {
        let mainLockID: number = 0;

        if (isReload) {
            mainLockID = this.recordLockingFlow.getLockID(EntityName.OEQUIPMENT, equipmentId);
        }

        return new Promise<EquipmentsDisplay>((resolve) => {
            this.lockSameUserMessageService.validateEntityIsLockedByUser(this.recordLockingFlow, EntityName.OEQUIPMENT, equipmentId, mainLockID, quickAccess).then((lockSameUserResult: LockSameUserResult) => {
                if(lockSameUserResult.openEntity) {
                    this.getEntityDetailsById(equipmentId, mainLockID, false).subscribe((entityDisplay: EquipmentsDisplay) => {
                        resolve(entityDisplay);
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    private getEntityDetailsById(equipmentId: string, mainLockID: number, onlyForRead: boolean): Observable<EquipmentsDisplay> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.equipment"));
        return this.http.get<EquipmentsDisplay>(`${environment.apiUrl}otherEquipment/${equipmentId}/${mainLockID}/${onlyForRead}`)
            .pipe(
                map((data: any) => {
                    let equipmentDisplay = null;
                    if (data.response) {
                        equipmentDisplay = EquipmentsDisplay.BuildEquipmentDisplay(data.response);
                        if(onlyForRead){
                            if(equipmentDisplay.equipmentId && !equipmentDisplay.lockError){
                                this.recordLockingFlow.setLockItem(EntityName.OEQUIPMENT, equipmentDisplay.equipmentId, equipmentDisplay.lockId, equipmentDisplay.lockMode);
                                this.recordLockingFlow.clearLockFlow(EntityName.OEQUIPMENT, equipmentId);
                            }
                        }else {
                            if (equipmentDisplay.equipmentId) {
                                if (equipmentDisplay.hasDiffUserLockError()) {
                                    this.messageHandlerService.show(equipmentDisplay.lockError, MessageType.INFO);
                                }
    
                                // Set the locking info into lock global list.
                                this.recordLockingFlow.setLockItem(EntityName.OEQUIPMENT, equipmentDisplay.equipmentId, equipmentDisplay.lockId, equipmentDisplay.lockMode);
                                this.recordLockingFlow.initLockRefreshTimer(EntityName.OEQUIPMENT, equipmentDisplay.equipmentId, equipmentDisplay.lockId);
    
                            } else {
                                this.messageHandlerService.show(equipmentDisplay.lockError, MessageType.ERROR);
                            }
                        }

                    } else {
                        const errorType = MessageHandlerService.errorType(data);
                        if (errorType === MessageType.INFO) {
                            this.entityCommonService.sendEntityNotFoundEvent();
                        } else {
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), errorType);
                        }
                    }
                    this.loadWheelService.hideWheel(wheel);
                    return equipmentDisplay;
                }),

                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })

            );
    }

    getEquipmentsDetailsByIdForRead(equipmentId: string): Observable<EquipmentsDisplay> {
        return this.getEntityDetailsById(equipmentId, 0, true);
    }

    getEquipmentById(equipmentId: number): Observable<EquipmentSearch> {
        return this.http.get<EquipmentSearch>(`${environment.apiUrl}otherEquipment/${equipmentId}`)
            .pipe(
                map((data: any) => {
                    return this.equipmentSearchAdapter.adapt(data.response[0]);
                })
            );
    }

    saveEquipmentDetails(equipmentDisplay: EquipmentsDisplay): Observable<EquipmentsDisplay> {
        return this.http.put<EquipmentsDisplay>(`${environment.apiUrl}otherEquipment/`, equipmentDisplay)
            .pipe(
                map((data: any) => {
                    if (data.response === null) {
                        if (data.exception !== null) {
                            this.messageHandlerService.setExceptionData(data);
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), MessageType.EXCEPTION);
                        } else if (data.message !== null) {
                            if (equipmentDisplay.saveType === SaveType.TYPE_QC || equipmentDisplay.saveType === SaveType.UPDATE) {
                                //When the user attempts to QC a record while the lock was already cleared,
                                //this//the above warning message should still be thrown; however, the record should not reload.
                                if (data.messageType !== MessageType.WARNING.valueOf()) {
                                    this.entityCommonService.sendReloadEvent();
                                }
                            } else {
                                equipmentDisplay.intDataDepMigError = data.message;
                            }

                        }
                    } else {
                        const isQcUpdateOrAmend = equipmentDisplay.saveType === SaveType.TYPE_QC ||
                            equipmentDisplay.saveType === SaveType.AMENDMENT ||
                            equipmentDisplay.saveType === SaveType.UPDATE;

                        const afterClearFunc = () => this.entityCommonService.sendPreReleaseEvent();
                        this.recordLockingFlow.clearLockFlow(EntityName.OEQUIPMENT, String(equipmentDisplay.equipmentId), isQcUpdateOrAmend, afterClearFunc);
                    }
                    return data.response;
                })
            );
    }

    equipmentNextId(): Observable<EquipmentsDisplay> {
        return this.http.get<any>(`${environment.apiUrl}otherEquipment/nextId`)
            .pipe(
                map((data: any) => {
                    const equipmentDisplay = EquipmentsDisplay.BuildNewEquipment(data.response);
                    if (equipmentDisplay && equipmentDisplay.equipmentId) {
                        // Set the locking info into lock global list.
                        this.recordLockingFlow.setLockItem(EntityName.OEQUIPMENT, equipmentDisplay.equipmentId, -1);
                    }
                    return equipmentDisplay;
                }
                ));
    }

    getUserDetailInfo(userName: string): Observable<UserDetail> {
        return this.http.get<UserDetail>(`${environment.apiUrl}registration/user/details/${userName}`).pipe(
            map((data: any) => {
                const userInfo = UserDetail.BuildUserDetailData(data.response[0]);
                return userInfo;
            }),
        );
    }

    searchEquipments(params: HttpParams): Observable<EquipmentSearch[]> {
        params = EntityUtilsService.initializeMissingParams(params, 
            [ 
            'unitName','ownerName','unitId','plantId','plantName',
            'equipmentType','equipmentTypeName','sicCode','equipmentName',
            'industryCode','recordStatus'
            ]);
        const searchStart: number = performance.now();

        return this.http.get<EquipmentSearch[]>(`${environment.apiUrl}otherEquipment/search`, { params: params })
            .pipe(
                map((data: any) => {
                    let equipment: Array<EquipmentSearch> = data.response;
                    this.entityUtilsService.verifyMaxResult(data);
                    this.telemetry.entitySearchTime("equipment", performance.now() - searchStart);
                    return equipment.map(item => this.equipmentSearchAdapter.adapt(item));
                })
            );
    }

    loadEquimentAreas(equipmentId: number) {
        return this.http.get<Areas>(`${environment.apiUrl}otherEquipment/areas/${equipmentId}`)
            .pipe(
                map((data: any) => Areas.BuildAreas(data.response))
            );
    }

    loadEquimentCompanies(equipmentId: number) {
        return this.http.get<CompanyOwners>(`${environment.apiUrl}otherEquipment/companies/${equipmentId}`)
            .pipe(
                map((data: any) => CompanyOwners.BuildCompOwners(data.response))
            );
    }

    loadEquimentPlants(equipmentId: number) {
        return this.http.get<Plants>(`${environment.apiUrl}otherEquipment/plants/${equipmentId}`)
            .pipe(
                map((data: any) => Plants.BuildPlants(data.response))
            );
    }

    loadEquimentUnits(equipmentId: number) {
        return this.http.get<Units>(`${environment.apiUrl}otherEquipment/units/${equipmentId}`)
            .pipe(
                map((data: any) => Units.BuildUnits(data.response))
            );
    }

    isEquipMfgAssociatedToEquipType(equipmentTypeName: string, manufacturer: Manufacturer): boolean {
        if(!StringUtils.isEmpty(equipmentTypeName)){
            const equipTypesArray = manufacturer.equipTypes.split(',').map(type => type.trim());

            for (const type of equipTypesArray) {
                if (equipmentTypeName.toUpperCase() === type.toUpperCase()) {
                    return true;
                }
            }
        }         
        return false;
    }
    
}


