import { ChangeDetectorRef, Component } from '@angular/core';
// Modal Dialog
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// Service
import { SharedService } from 'src/app/core/services/shared.service';

// Models
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { ProjectsOfflineEventsDisplay } from 'src/app/core/models/project/display/projects-offline-events-display';
import { ProjectsUnitIdsDisplay } from 'src/app/core/models/project/display/projects-unit-ids-display';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { OfflineEventSectionService } from 'src/app/offline-event-section/offline-event-section.service';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { AdditionalInfoContainer, InputModalField, InputModalFieldContainer, InputModalFieldEditableType, SearchEntityModalComponent } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.component';
import { SearchEntityModalService } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.service';
import { UnitTypes } from '../../../core/models/common';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { SearchEntityModalUtils } from 'src/app/core/utils/search-entity-modal-utils';

@Component({
    selector: 'app-search-offline-event-modal',
    templateUrl: '../../../shared/components/base/search-entity-modal/search-entity-modal.component.html'
})

export class SearchModalOfflineEventComponent extends SearchEntityModalComponent {

    unitsIds: ProjectsUnitIdsDisplay[];
    preExistingProjectsOfflineEventsDisplay: ProjectsOfflineEventsDisplay[];

    constructor(
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        protected sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        protected offlineEventService: OfflineEventSectionService,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected datePipe: DatePipe,
        protected searchEntityModalService: SearchEntityModalService,
        protected changeDetectorRef: ChangeDetectorRef) {
        super(messageHandler, activeModal, config, preferencesService, translate, loadWheelService, entityCommonService, entityUtilsService, searchEntityModalService, dialogService, changeDetectorRef);
    }

    override initConfigAtributes() {
        this.entityTitle = 'offlineEvent.offlineEvent';
        this.entityTitlePlural = 'offlineEvent.offlineEvents';
        this.unitsIds = this.config.data.unitsIds;
        this.preExistingProjectsOfflineEventsDisplay = this.config.data.entitiesArray;
        this.showRecordCounter = true;
        this.isMultiSelect = !this.isMainAccess;
    }

    override ngAfterViewInit() {
        super.ngAfterViewInit();

        if(!this.isMainAccess) {
            this.search(false);
        }
    }

    protected override getInputModalFieldContainer(): InputModalFieldContainer[] {
        let inputsModalFieldContainer: InputModalFieldContainer[] = [];

        if(this.isMainAccess) {
            let inputsModalField: InputModalField[] = [];
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'unitName', displayName: 'project.searchOfflineEvent.unitName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'ownerName', displayName: 'project.searchOfflineEvent.ownerName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'unitId', displayName: 'project.searchOfflineEvent.unitId', editableType: InputModalFieldEditableType.numberField, maxLength: 7 }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantName', displayName: 'project.searchOfflineEvent.plantName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'assetId', displayName: 'project.searchOfflineEvent.assetId', editableType: InputModalFieldEditableType.numberField, maxLength: 7 }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'assetName', displayName: 'project.searchOfflineEvent.assetName', editableType: InputModalFieldEditableType.textField }));
    
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
    
            inputsModalField = [];
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'unitType', displayName: 'project.searchOfflineEvent.unitType', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'unitTypeId', dropdownLabel: 'unitTypeDesc' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'sicCode', displayName: 'project.searchOfflineEvent.sicCode', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'sicCode', dropdownLabel: 'sicCodeDescription' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'industryCode', displayName: 'project.searchOfflineEvent.industryCode', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'industryCode', dropdownLabel: 'industryDescription' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantId', displayName: 'project.searchOfflineEvent.plantId', editableType: InputModalFieldEditableType.numberField, maxLength: 7 }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'recordStatus', displayName: 'project.searchOfflineEvent.recordStatus', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'RecordStatusID', dropdownLabel: 'RecordStatusDescription' }));
    
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
        }

        return inputsModalFieldContainer;
    }

    protected override getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];

        columnsInfo.push({ entityPropName: 'eventID', columnHeader: 'project.searchOfflineEvent.eventId', widthPercentage: 12 });
        columnsInfo.push({ entityPropName: 'unitType', columnHeader: 'project.searchOfflineEvent.unitType', widthPercentage: 15 });
        columnsInfo.push({ entityPropName: 'eventStatusDesc', columnHeader: 'project.searchOfflineEvent.eventStatus', widthPercentage: 15 });
        columnsInfo.push({ entityPropName: this.isMainAccess ? 'largeEventName' : 'eventName', columnHeader: 'project.searchOfflineEvent.eventName', widthPercentage: 34 });
        columnsInfo.push({ entityPropName: 'eventStartDate', columnHeader: 'project.searchOfflineEvent.eventStartDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 11 });
        columnsInfo.push({ entityPropName: 'eventDuration', columnHeader: 'project.searchOfflineEvent.duration', widthPercentage: 7 });

        return columnsInfo;
    }

    protected override getAdditionalInfoContainers(): AdditionalInfoContainer[] {
        let aditionalInfoContainer: AdditionalInfoContainer[] = [];

        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: this.isMainAccess ? 'largeEventName' : 'eventName', columnHeader: 'project.searchOfflineEvent.eventName' });
        columnsInfo.push({ entityPropName: 'ownerName', columnHeader: 'project.searchOfflineEvent.ownerName' });
        columnsInfo.push({ entityPropName: 'operatorName', columnHeader: 'project.searchOfflineEvent.operatorName' });
        columnsInfo.push({ entityPropName: 'eventStartDate', columnHeader: 'project.searchOfflineEvent.eventStartDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField });
        columnsInfo.push({ entityPropName: 'recordStatus', columnHeader: 'project.searchOfflineEvent.recordStatus' });
        columnsInfo.push({ entityPropName: 'eventComments', columnHeader: 'project.searchOfflineEvent.eventComments' });

        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));

        columnsInfo = [];
        columnsInfo.push({ entityPropName: 'sicCodeCompositeName', columnHeader: 'project.searchOfflineEvent.sicCode' });
        columnsInfo.push({ entityPropName: 'primaryFuel', columnHeader: 'project.searchOfflineEvent.primaryFuel' });
        columnsInfo.push({ entityPropName: 'powerUsage', columnHeader: 'project.searchOfflineEvent.powerUsage' });
        columnsInfo.push({ entityPropName: 'eventEndDate', columnHeader: 'project.searchOfflineEvent.eventEndDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField });
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'project.searchOfflineEvent.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField });

        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
        
        return aditionalInfoContainer;
    }

    protected override searchEntitiesDelegate(params: HttpParams, wheel: SpinnerProcess): Observable<any> {
        return this.offlineEventService.searchOfflineEvents(params);
    }

    protected override getHttpParamsFromInputsModalField(): HttpParams {
        let params: HttpParams = super.getHttpParamsFromInputsModalField();
        if (!this.isMainAccess) {
            params = params.set('unitsIds', this.getUnitIds());
            params = params.set('offlineEventsIds', this.getOfflineEventsIds());
            params = params.set('searchOfflineEventsIds', true);
        } else {
            params = params.set('unitsIds', '');
            params = params.set('offlineEventsIds', '');
            params = params.set('searchOfflineEventsIds', false);
        }
        return params;
    }

    getUnitIds(): string {
        const filteredUnits = this.unitsIds.filter(unit => unit.getOperation() !== OperationType.DELETE);
        const unitsIdsCollected = filteredUnits.map(unit => unit.unitId).join(',');
        return unitsIdsCollected;
    }

    getOfflineEventsIds(): string {
        const filteredEntities = this.preExistingProjectsOfflineEventsDisplay.filter(entity => entity.getOperation() !== OperationType.DELETE && entity.offlineEventId > 0);
        const offlineEventsIdsCollected = filteredEntities.map(entity => StringUtils.toStringNeverNull(entity.offlineEventId)).join(',');
        return offlineEventsIdsCollected;
    }

    override loadList() {
        this.loadUnitType();
        this.loadUnitSicCode();
        this.loadRecordStatus();
        this.loadIndustry();
    }

    private loadUnitType() {
        SearchEntityModalUtils.loadUnitType(this.searchInputModalField('unitType'), this.sharedService);
    }

    private loadUnitSicCode() {
        SearchEntityModalUtils.loadSicCode(this.searchInputModalField('sicCode'), this.sharedService);
    }

    private loadRecordStatus() {
        SearchEntityModalUtils.loadRecordStatus(this.searchInputModalField('recordStatus'), this.sharedService);
    }

    private loadIndustry() {
        SearchEntityModalUtils.loadIndustryCode(this.searchInputModalField('industryCode'), this.sharedService);
    }

}
