import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';

import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { EquipmentSectionService } from 'src/app/equipment-section/equipment-section.service';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { AdditionalInfoContainer, InputModalField, InputModalFieldContainer, InputModalFieldEditableType, SearchEntityModalComponent } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.component';
import { SearchEntityModalService } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { EquipmentTypesModalComponent } from '../equipment-types-modal/equipment-types-modal.component';
import { SearchEntityModalUtils } from 'src/app/core/utils/search-entity-modal-utils';

@Component({
    selector: 'app-search-equipment',
    templateUrl: '../../../shared/components/base/search-entity-modal/search-entity-modal.component.html'
})
export class SearchEquipmentComponent extends SearchEntityModalComponent {
    
    constructor(
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        protected equipmentService: EquipmentSectionService,
        protected sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        protected datePipe: DatePipe,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected searchEntityModalService: SearchEntityModalService,
        protected changeDetectorRef: ChangeDetectorRef) {
        super(messageHandler, activeModal, config, preferencesService, translate, loadWheelService, entityCommonService, entityUtilsService, searchEntityModalService, dialogService, changeDetectorRef);
    }

    override initConfigAtributes() {
        this.entityTitle = 'common.equipment';
        this.entityTitlePlural = 'equipment.searchEquipment.titlePlural';
        this.showNewRecordButton = false;    
    }

    protected override getInputModalFieldContainer(): InputModalFieldContainer[] {
        let inputsModalFieldContainer: InputModalFieldContainer[] = [];

        let inputsModalField: InputModalField[] = [];
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'unitName', displayName: 'equipment.searchEquipment.unitName', editableType: InputModalFieldEditableType.textField }));
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'ownerName', displayName: 'equipment.searchEquipment.ownerName', editableType: InputModalFieldEditableType.textField }));
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'unitId', displayName: 'equipment.searchEquipment.unitId', editableType: InputModalFieldEditableType.numberField, widthPercentage: 50, maxLength: 7 }));
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantId', displayName: 'equipment.searchEquipment.plantId', editableType: InputModalFieldEditableType.numberField, widthPercentage: 50, maxLength: 7 }));
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantName', displayName: 'equipment.searchEquipment.plantName', editableType: InputModalFieldEditableType.textField }));
        
        inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
        
        inputsModalField = [];
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'equipmentName', displayName: 'equipment.searchEquipment.equipmentName', editableType: InputModalFieldEditableType.textField }));
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'equipmentTypeName', displayName: 'equipment.searchEquipment.equipmentType', editableType: InputModalFieldEditableType.textField, searchType: EquipmentTypesModalComponent, searchTitle:'equipment.equipmentTypeSearch.search', searchData: { entitiesArray: [], searchOnModalOpen: true }, searchEntityPropName: 'equipmentTypeDesc' }));
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'sicCode', displayName: 'equipment.searchEquipment.sicCode', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'sicCode', dropdownLabel: 'sicCodeDescription' }));
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'industryCode', displayName: 'equipment.searchEquipment.industryCode', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'industryCode', dropdownLabel: 'industryDescription' }));
        inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'recordStatus', displayName: 'equipment.searchEquipment.recordStatus', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'RecordStatusID', dropdownLabel: 'RecordStatusDescription' }));

        inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));

        return inputsModalFieldContainer;
    }

    protected override getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];

        columnsInfo.push({ entityPropName: 'equipmentId', columnHeader: 'equipment.searchEquipment.equipmentId', widthPercentage: 16 });
        columnsInfo.push({ entityPropName: 'equipmentName', columnHeader: 'equipment.searchEquipment.equipmentName', widthPercentage: 20 });
        columnsInfo.push({ entityPropName: 'equipmentTypeName', columnHeader: 'equipment.equipmentType', widthPercentage: 16 });
        columnsInfo.push({ entityPropName: 'plantName', columnHeader: 'equipment.searchEquipment.plantName', widthPercentage: 16 });
        columnsInfo.push({ entityPropName: 'unitName', columnHeader: 'equipment.searchEquipment.unitName', widthPercentage: 16 });        
        return columnsInfo;
    }

    protected override getAdditionalInfoContainers(): AdditionalInfoContainer[] {
        let aditionalInfoContainer: AdditionalInfoContainer[] = [];

        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'equipmentName', columnHeader: 'equipment.searchEquipment.equipmentName' });
        columnsInfo.push({ entityPropName: 'equipmentTypeName', columnHeader: 'equipment.searchEquipment.equipType' });
        columnsInfo.push({ entityPropName: 'equipmentInfo', columnHeader: 'equipment.searchEquipment.equipmentInfo' });
        columnsInfo.push({ entityPropName: 'capacityCompositeName', columnHeader: 'equipment.searchEquipment.capacity' });
        columnsInfo.push({ entityPropName: 'manufacturer', columnHeader: 'equipment.searchEquipment.manufacturer' });
        columnsInfo.push({ entityPropName: 'recordStatus', columnHeader: 'equipment.searchEquipment.recordStatus' });
        
        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));
        
        columnsInfo = [];
        columnsInfo.push({ entityPropName: 'ownerName', columnHeader: 'equipment.searchEquipment.ownerName' });
        columnsInfo.push({ entityPropName: 'plantName', columnHeader: 'equipment.searchEquipment.plantName' });
        columnsInfo.push({ entityPropName: 'areaName', columnHeader: 'equipment.searchEquipment.areaName' });
        columnsInfo.push({ entityPropName: 'unitName', columnHeader: 'equipment.searchEquipment.unitName' });
        columnsInfo.push({ entityPropName: 'unitId', columnHeader: 'equipment.searchEquipment.unitId', maxLength: 7 });
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'equipment.searchEquipment.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField });
        
        aditionalInfoContainer.push(AdditionalInfoContainer.CreateInstance({ additionalInfoFields: columnsInfo }));

        return aditionalInfoContainer;
    }
    
    protected override searchEntitiesDelegate(params: HttpParams, wheel: SpinnerProcess): Observable<any> {
        return this.equipmentService.searchEquipments(params);
    }

    override loadList() {
        this.loadRecordStatus();
        this.loadIndustryCode();
        this.loadSicCode();
    }

    private loadRecordStatus() {
        SearchEntityModalUtils.loadRecordStatus(this.searchInputModalField('recordStatus'), this.sharedService);
    }

    private loadIndustryCode() {
        SearchEntityModalUtils.loadIndustryCode(this.searchInputModalField('industryCode'), this.sharedService);
    }

    private loadSicCode() {
        SearchEntityModalUtils.loadSicCode(this.searchInputModalField('sicCode'), this.sharedService);
    }

}

