import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import { MessageType } from '../components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../components/messages/message-handler/message-handler.service';
import { SaveType } from '../core/models/enumerations/save-type';
import {
    CompaniesDisplay, CompanyOwners,
    CompanySearch, CompanySearchAdapter, Contact, Pipeline, Plants, Projects, TransmissionLine
} from '../core/models/index';
// Interface
import { TranslateService } from '@ngx-translate/core';
import { LoadWheelService } from '../components/load-wheel/load-wheel.service';
import { CompanyClassificationValue, CompanyStatusValue, RecordStatusValue } from '../core/models/constants';
import { EntityName } from '../core/models/enumerations/entity-name';
import { SpinnerProcess } from '../core/models/spinner-process';
import { RecordLockingFlow } from '../core/record-locking/record-locking-flow';
import { EntityCommonService } from '../core/services/entity-common.service';
import { LockSameUserMessageService, LockSameUserResult } from '../core/services/lock-same-user-message.service';
import { TelemetryService } from '../core/services/telemetry.service';
import { EntityUtilsService } from '../core/utils/entity-utils.service';

@Injectable({
    providedIn: 'root'
})

export class CompanySectionService {

    constructor(
        private adapter: CompanySearchAdapter,
        private recordLockingFlow: RecordLockingFlow,
        private http: HttpClient,
        private messageHandlerService: MessageHandlerService,
        private loadWheelService: LoadWheelService,
        private entityCommonService: EntityCommonService,
        private translate: TranslateService,
        private entityUtilsService: EntityUtilsService,
        private lockSameUserMessageService: LockSameUserMessageService,
        private telemetry: TelemetryService,
    ) { }

    getCompanyDetailsById(companyId: string, isReload: boolean, quickAccess: boolean = false): Promise<CompaniesDisplay> {
        let mainLockID: number = 0;
        if (isReload) {
            mainLockID = this.recordLockingFlow.getLockID(EntityName.COMPANY, companyId);
        }

        return new Promise<CompaniesDisplay>((resolve) => {
            this.lockSameUserMessageService.validateEntityIsLockedByUser(this.recordLockingFlow, EntityName.COMPANY, companyId, mainLockID, quickAccess).then((lockSameUserResult: LockSameUserResult) => {
                if(lockSameUserResult.openEntity) {
                    this.getEntityDetailsById(companyId, mainLockID).subscribe((entityDisplay: CompaniesDisplay) => {
                        this.lockSameUserMessageService.clearContactsLockedsBySameUser(lockSameUserResult, entityDisplay?.companiesContactInfoDisplays);
                        resolve(entityDisplay);
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    private getEntityDetailsById(entityId: string, mainLockID: number): Observable<CompaniesDisplay> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.company"));

        return this.http.get<CompaniesDisplay>(`${environment.apiUrl}company/${entityId}/${mainLockID}/false`)
            .pipe(
                map((data: any) => {
                    let companyDisplay = null;
                    if (data.response) {
                        companyDisplay = CompaniesDisplay.BuildCompanyDisplay(data.response);
                        if (companyDisplay.companyId) {
                            if (companyDisplay.hasDiffUserLockError()) {
                                this.messageHandlerService.show(companyDisplay.lockError, MessageType.INFO);
                            }
                            // Set the locking info into lock global list.
                            this.recordLockingFlow.setLockItem(EntityName.COMPANY, companyDisplay.companyId, companyDisplay.lockId, companyDisplay.lockMode);
                            this.recordLockingFlow.initLockRefreshTimer(EntityName.COMPANY, companyDisplay.companyId, companyDisplay.lockId);
                        } else {
                            this.messageHandlerService.show(companyDisplay.lockError, MessageType.ERROR);
                        }
                    } else {
                        const errorType = MessageHandlerService.errorType(data);
                        if (errorType === MessageType.INFO) {
                            this.entityCommonService.sendEntityNotFoundEvent();
                        } else if (errorType !== MessageType.NONE && errorType !== MessageType.SESSION_INVALID) {
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), errorType);
                        }
                    }
                    this.loadWheelService.hideWheel(wheel);
                    return companyDisplay;
                }),

                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })

            );
    }

    getCompanyById(companyId: number): Observable<CompanySearch> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.company"));
        return this.http.get<CompanySearch>(`${environment.apiUrl}company/${companyId}`)
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return this.adapter.adapt(data.response[0]);
                })
            );
    }

    getAssociatedCompanyByIdArray(companyId: number, doOnError?: CallableFunction): Observable<Array<CompanySearch>> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.company"));
        return this.http.get<CompanySearch>(`${environment.apiUrl}company/${companyId}`)
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    const company  =  data.response.length > 0 ? [this.adapter.adapt(data.response[0])] : [];
                    if (company.length > 0 && !this.canAssociateCompany(company[0])) {
                        const onMessageOk = ()=> {if(doOnError){doOnError();}}
                        this.messageHandlerService.show(this.translate.instant('company.searchModal.cannotAssociate'), MessageType.INFO, onMessageOk);
                        return null;
                    }
                    return company;

                })
            );
    }

    getAssociatedCompanyById(companyId: number, doOnError?: CallableFunction): Observable<CompanySearch> {
        return this.getCompanyById(companyId).pipe(
            map((company: CompanySearch) => {
                if (!this.canAssociateCompany(company)) {
                    const onMessageOk = ()=> {if(doOnError){doOnError();}}
                    this.messageHandlerService.show(this.translate.instant('company.searchModal.cannotAssociate'), MessageType.INFO, onMessageOk);
                    return null;
                }
                return company;
            })
        );
    }

    canAssociateCompany(company: CompanySearch): boolean {
        return company.companyStatus === CompanyStatusValue.Active &&
            (company.recordStatus === RecordStatusValue.Enhanced ||
                company.recordStatus === RecordStatusValue.Archived ||
                company.recordStatus === RecordStatusValue.Unconfirmed ||
                company.recordStatus === null
            );
    }

    canAssociateParentCompany(company: CompanySearch): boolean {
        return company.classification === CompanyClassificationValue.Holding
        || company.classification === CompanyClassificationValue.Parent;
    }

    companyInTree(ownerList: Array<number>, companyData: CompanySearch, that: any){
        if(ownerList.some(id => id === companyData.companyId)) {
            that.parentDataOk(companyData);
        } else if(ownerList.length>0) {
            this.containsCompanyRelationshipTree(companyData.companyId, ownerList.join(','))
                .subscribe(result => {
                    if(result) {
                        that.parentDataOk(companyData);
                    } else {
                        this.messageHandlerService.show(this.translate.instant('common.companyCannotBeSelect', MessageType.INFO));
                        if(that.parentDataFail) {
                            that.parentDataFail(companyData);
                        }
                    }
                });
        } else {
            this.messageHandlerService.show(this.translate.instant('common.companyCannotBeSelect', MessageType.INFO));
            if(that.parentDataFail) {
                that.parentDataFail(companyData);
            }
        }
    }

    saveCompanyDetails(companyDisplay: CompaniesDisplay): Observable<CompaniesDisplay> {
        return this.http.put<CompaniesDisplay>(`${environment.apiUrl}company/`, companyDisplay)
            .pipe(
                map((data: any) => {
                    if (data.response === null) {
                        if (data.exception !== null) {
                            this.messageHandlerService.setExceptionData(data);
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), MessageType.EXCEPTION);
                        } else if (data.message !== null) {
                            if (companyDisplay.saveType === SaveType.TYPE_QC || companyDisplay.saveType === SaveType.UPDATE) {
                                //When the user attempts to QC a record while the lock was already cleared,
                                //this//the above warning message should still be thrown; however, the record should not reload.
                                if (data.messageType !== MessageType.WARNING.valueOf()) {
                                    this.entityCommonService.sendReloadEvent();
                                }
                            } else {
                                companyDisplay.intDataDepMigError = data.message;
                            }

                        }
                    } else {
                        const isQcUpdateOrAmend = companyDisplay.saveType === SaveType.TYPE_QC ||
                            companyDisplay.saveType === SaveType.AMENDMENT ||
                            companyDisplay.saveType === SaveType.UPDATE;

                        const afterClearFunc = () => this.entityCommonService.sendPreReleaseEvent();
                        this.recordLockingFlow.clearLockFlow(EntityName.COMPANY, String(companyDisplay.companyId), isQcUpdateOrAmend, afterClearFunc);
                    }
                    return data.response;
                })
            );
    }

    companyNextId(): Observable<CompaniesDisplay> {
        return this.http.get<any>(`${environment.apiUrl}company/nextId`)
            .pipe(
                map((data: any) => {
                    const companyDisplay = CompaniesDisplay.BuildNewCompany(data.response);
                    if (companyDisplay && companyDisplay.companyId) {
                        // Set the locking info into lock global list.
                        this.recordLockingFlow.setLockItem(EntityName.COMPANY, companyDisplay.companyId, -1);
                    }
                    return companyDisplay;
                }
                ));
    }

    getTLineInfo(companyId: number): Observable<TransmissionLine[]> {
        return this.http.get<TransmissionLine>(`${environment.apiUrl}company/tlines/${companyId}`)
            .pipe(
                map((data: any) => TransmissionLine.BuildTransmissionLine(data.response))
            );
    }

    getPlantsInfo(companyId: number) {
        return this.http.get<Plants>(`${environment.apiUrl}company/plants/${companyId}`)
            .pipe(
                map((data: any) => Plants.BuildPlants(data.response))
            );
    }

    getPipelineInfo(companyId: number): Observable<Pipeline[]> {
        return this.http.get<Pipeline>(`${environment.apiUrl}company/pipelines/${companyId}`)
            .pipe(
                map((data: any) => Pipeline.BuildPipeline(data.response))
            );
    }

    getProjectInfo(companyId: number): Observable<Projects[]> {
        return this.http.get<Projects>(`${environment.apiUrl}company/projects/${companyId}`)
            .pipe(
                map((data: any) => Projects.BuildProjects(data.response))
            );
    }

    getCompOwners(companyId: number): Observable<CompanyOwners[]> {
        return this.http.get<CompanyOwners>(`${environment.apiUrl}company/owners/${companyId}`)
            .pipe(
                map((data: any) => CompanyOwners.BuildCompOwners(data.response))
            );
    }

    getContacts(companyId: number) {
        return this.http.get<Contact>(`${environment.apiUrl}company/contacts/${companyId}`)
            .pipe(
                map((data: any) => Contact.BuilContact(data.response))
            );
    }

    searchCompany(httpParams: HttpParams): Observable<CompanySearch[]> {
        httpParams = EntityUtilsService.initializeMissingParams(httpParams, 
            [ 
            'companyName','physCity','industryCode','physCountry','physState','companyStatus[]',
            'companyClassification','physCounty','previousName','recordStatus',
            ]);
        const searchStart: number = performance.now();

        return this.http.get<CompanySearch[]>(`${environment.apiUrl}company/search`, { params:httpParams })
            .pipe(
                map((data: any) => {
                    this.entityUtilsService.verifyMaxResult(data);
                    const company: Array<CompanySearch> = data.response;
                    this.telemetry.entitySearchTime("company", performance.now() - searchStart);
                    return company.map(item => this.adapter.adapt(item));
                })
            );
    }

    containsCompanyRelationshipTree(plantId: number, companyId: string): Observable<boolean> {
        return this.http.get<boolean>(`${environment.apiUrl}company/contains/relationship/tree/${plantId}/${companyId}`)
            .pipe(
                map((data: any) => data.response)
            );
    }

}
